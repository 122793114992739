import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  SocialLinkDiv,
  CenterDiv,
  DownloadDiv,
  DownfooterDiv,
} from "./footerStyle";
import facebookLogo from "../../images/facebook.svg";
import twitterLogo from "../../images/twitter.svg";
import linkedInLogo from "../../images/linked_in.svg";
import instagramLogo from "../../images/instagram.svg";
import youtubeLogo from "../../images/youtube.svg";
import logo from "../../images/footerLogo.svg";
import astore from "../../new/astore.svg";
import gplay from "../../new/gplay.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [redirectLink, setRedirectLinki] = useState("Windows");
  useEffect(() => {
    const os = navigator.userAgent;
    var windowsLink = "https://anydone.com/download-page-windows.html";
    var macLink = "https://anydone.com/download-page-mac.html";
    var linuxLink = "https://anydone.com/download-page-linux.html";

    let finalOs = "";
    if (os.search("Windows") !== -1) {
      setRedirectLinki(windowsLink);
    } else if (os.search("Mac") !== -1) {
      setRedirectLinki(macLink);
    } else if (os.search("X11") !== -1 && !(os.search("Linux") !== -1)) {
      setRedirectLinki(linuxLink);
    } else if (os.search("Linux") !== -1 && os.search("X11") !== -1) {
      setRedirectLinki(linuxLink);
    } else {
      setRedirectLinki(windowsLink);
    }
  }, []);

  return (
    <Box>
      <Container>
        <Row>
          <Column>
            <Heading>Product</Heading>
            <FooterLink
              href="https://anydone.com/features.html"
              target="_blank"
            >
              Features
            </FooterLink>
            <FooterLink href="https://anydone.com/pricing.html" target="_blank">
              Pricing
            </FooterLink>
            <FooterLink
              href="https://anydone.com/brand-assets.html"
              target="_blank"
            >
              Brand Assets
            </FooterLink>
            <FooterLink
              href={redirectLink}
              target="_blank"
              className="redirect-download-page"
              rel="noreferrer"
            >
              Download
            </FooterLink>
            {/* <FooterLink href="#">Product Tour</FooterLink> */}
          </Column>
          {/* <Column>
            <Heading>Solutions</Heading>
            <FooterLink
              href="https://anydone.com/software-it.html"
              target="_blank"
            >
              Software and IT
            </FooterLink>
            <FooterLink
              href="https://anydone.com/telecommunications.html"
              target="_blank"
            >
              Telecommunication
            </FooterLink>
            <FooterLink
              href="https://anydone.com/financial-institutions.html"
              target="_blank"
            >
              Financial Institution
            </FooterLink>
            <FooterLink
              href="https://anydone.com/manufacturing.html"
              target="_blank"
            >
              Manufacturing
            </FooterLink>
          </Column> */}
          <Column>
            <Heading>Company</Heading>
            <FooterLink href="https://anydone.com/about.html" target="_blank">
              About Us
            </FooterLink>
            <FooterLink href="https://blog.anydone.com/" target="_blank">
              Blog
            </FooterLink>
            <FooterLink href="https://anydone.com/career.html" target="_blank">
              Career
            </FooterLink>
          </Column>
          <Column>
            <Heading>Help</Heading>
            <FooterLink href="https://anydone.com/contact.html" target="_blank">
              Contact Us
            </FooterLink>
            <FooterLink href="https://help.anydone.com/" target="_blank">
              Help Center
            </FooterLink>
          </Column>
        </Row>
      </Container>
      <div className="border-div"></div>
      <DownfooterDiv>
        <SocialLinkDiv>
          <div>
            <a href="https://www.anydone.com/" rel="noreferrer">
              <img src={logo} alt="youtube-icon" />
            </a>
          </div>
          <div>
            <a
              href="https://twitter.com/AnydoneUs?s=20"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={twitterLogo}
                alt="twitter-cion"
                style={{ height: "22px !important" }}
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/anydone.us/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebookLogo} alt="facebook-icon" />
            </a>
          </div>
          <div>
            <a
              href="https://www.linkedin.com/company/anydone/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedInLogo} alt="linkedin-icon" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/anydone.us/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagramLogo} alt="instagram-icon" />
            </a>
          </div>
          <div>
            <a
              href="https://www.youtube.com/@anydone"
              target="_blank"
              rel="noreferrer"
            >
              <img src={youtubeLogo} alt="youtube-icon" />
            </a>
          </div>
        </SocialLinkDiv>
        <CenterDiv>
          <div className="inner-center-content">
            <span className="footer-width">©{currentYear} anydone</span>
            <span className="footer-width">
              <FooterLink href="https://anydone.com/terms.html" target="_blank">
                Terms of Service
              </FooterLink>
            </span>
            <span className="footer-width">
              <FooterLink
                href="https://anydone.com/privacy.html"
                target="_blank"
              >
                Privacy Policy
              </FooterLink>
            </span>
            <span className="footer-width">
              <FooterLink
                href="https://anydone.com/cookie.html"
                target="_blank"
              >
                Cookie Policy
              </FooterLink>
            </span>
          </div>
        </CenterDiv>
        <DownloadDiv>
          {/* <div className="redirect-download-page">
            <a href={redirectLink} target="_blank" rel="noreferrer">
              <img
                src="https://edge-a.anydone.com/252aa5f1f8414d598b3088f94d0ede1d.svg"
                alt="download-icon"
              />
              Download anydone
            </a>
          </div> */}
          <div className="stores">
            <div>
              <a
                href="https://apps.apple.com/np/app/anydone/id1601165191"
                target="_blank"
                rel="noreferrer"
              >
                <img src={astore} alt="applestore-icon" />
              </a>
            </div>
            <div>
              <a
                href="https://play.google.com/store/apps/details?id=com.anydone.inbox"
                target="_blank"
                rel="noreferrer"
              >
                <img src={gplay} alt="gstore-icon" />
              </a>
            </div>
          </div>
        </DownloadDiv>
      </DownfooterDiv>
    </Box>
  );
};
export default Footer;
