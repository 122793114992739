import plus from "./../../../../../new/icons/plus.png";
import message from "./../../../../../new/icons/message.svg";
import headphone from "./../../../../../new/icons/headphones.png";
import transcribe from "./../../../../../new/icons/Transcript.png";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Ccontent = {
  title: "MeetAssist",
  link: "meetassist",
  content: [
    {
      id: 0,
      title: "Starting a MeetAssist",
      isStartMeetAssist: true,
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${message} alt="message" height="12" width="12"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace, DMs, or subject where you want to CoConnect.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${headphone} alt="message" height="12" width="12"/> icon from the top right corner.`,
        },
        {
          id: 4,
          title: `Click on <b>three horizontal dots</b>.`,
        },
        {
          id: 5,
          title: `Here from options like <b>Record only</b>, <b>Record and Transcribe</b> and <b>Transcribe Only</b>, select one as per your preference.`,
        },
        {
          id: 6,
          title: `After wrapping up the call, your recorded video will show in a thread.`,
        },
        {
          id: 7,
          title: `Now click on the <img src=${transcribe} alt="message" height="16" width="16"/> icon i.e Transcription from the video player.`,
        },
      ],
    },
  ],
};
