import headphone from "./../../../../../new/icons/headphones.png";
import screenShare from "./../../../../../new/icons/Screen share.png";
import message from "./../../../../../new/icons/message.svg";
import videoCall from "./../../../../../new/icons/video.png";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Ccontent = {
  title: "CoConnect",
  link: "calls",
  content: [
    {
      id: 0,
      title: "Starting a CoConnect",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${message} alt="message" height="12" width="12"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace, DMs, or subject where you want to CoConnect.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${headphone} alt="message" height="12" width="12"/> icon from the top right corner.`,
        },
        {
          id: 4,
          title: `Now, start collaborating in CoConnect with your peers.`,
          imageSource: `${GifBaseUrl}starting-coconnect.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Invite additional member during the CoConnect",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${message} alt="message" height="12" width="12"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace, DMs, or subject where you want to CoConnect.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${headphone} alt="message" height="12" width="12"/> icon from the top right corner.`,
        },
        {
          id: 4,
          title: `Click on the <b>three horizontal dots</b>.`,
        },
        {
          id: 5,
          title: `Now add members to your CoConnect.`,
        },
        {
          id: 6,
          title: `Click on <b>Invite</b> option`,
          imageSource: `${GifBaseUrl}inviting-member-to-coconnect.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Make a Video Call with CoConnect",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${message} alt="message" height="12" width="12"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace, DMs, or subject where you want to CoConnect.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${headphone} alt="message" height="12" width="12"/> icon from the top right corner.`,
        },
        {
          id: 4,
          title: `Click on <img src=${videoCall} alt="message" height="12" width="12"/>  icon.`,
          imageSource: `${GifBaseUrl}start-video-call-in-connect.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Share Screen During a CoConnect",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${message} alt="message" height="12" width="12"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace, DMs, or subject where you want to CoConnect.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${headphone} alt="message" height="12" width="12"/> icon from the top right corner.`,
        },
        {
          id: 4,
          title: `Click on the <img src=${screenShare} alt="message" height="12" width="12"/> icon`,
        },
        {
          id: 5,
          title: `Now select the tab, windows or screen to share`,
          imageSource: `${GifBaseUrl}share-screen-in-covas.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "Send Emoji in CoConnect",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${message} alt="message" height="12" width="12"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace, DMs, or subject where you want to CoConnect.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${headphone} alt="message" height="12" width="12"/> icon from the top right corner.`,
        },
        {
          id: 4,
          title: `Click on <b>emoji</b> option and select the emoji`,
        },
        {
          id: 5,
          title: `Or, click on hand like icon to raise hand during the call or select your preferred emoji from here`,
          imageSource: `${GifBaseUrl}emoji-in-coconnect.gif`,
        },
      ],
    },
  ],
};
