import plus from "./../../../../../new/icons/plus.png";
import tick from "./../../../../../new/icons/check-mark.png";
import cross from "./../../../../../new/icons/close.png";
import send from "./../../../../../new/icons/send.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/messages-and-calls/";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MEcontent = {
  title: "Message Experience",
  link: "message-experience",
  content: [
    {
      id: 0,
      title: "Create a new Direct Message (DM)",
      steps: [
        {
          id: 1,
          title: ` From the left-hand side of your dashboard, navigate to the <b>Direct Messages</b> section and click on the <img src=${plus} alt="iicon" height="12" width="12"/> button.`,
        },
        {
          id: 2,
          title: `Now, at the top right side, type in the participant's name.`,
        },
        {
          id: 3,
          title: `Then, type your message in the text editor, and click on the  <img src=${send} alt="sendicon" height="12" width="12" style="margin-left: 5px; margin-right:5px"/> button to send the message.`,
          imageSource: `${GifBaseUrl}create-a-new-dm.gif`,
        },
      ],
      add_title: "Alternatively,",
      add_steps: [
        {
          id: 1,
          title: `From the left-hand side of your dashboard, click on the <img src=${plus} alt="iicon" height="12" width="12"/> button.`,
        },
        {
          id: 2,
          title: `Click on Compose and repeat the process mentioned above.`,
          imageSource: `${GifBaseUrl}create-a-new-dm-alternative.gif`,
        },
      ],
      // Add_Note: [
      //   {
      //     id: 1,
      //     title:
      //       "While adding a participant, you can also add multiple participants and get your conversation started.",
      //   },
      // ],
    },
    {
      id: 1,
      title: "Edit and Delete messages",
      onetitle: "Edit Message",
      steps: [
        {
          id: 1,
          title: `Hover over the sent message that you’d like to edit.`,
        },
        {
          id: 2,
          title: `Click on the button indicated by <b>3 vertically aligned dots</b>.`,
        },
        {
          id: 3,
          title: `Then, navigate to the <b>Edit</b> option.`,
        },
        {
          id: 4,
          title: "Text editor will pop up where you need to make changes",
        },
        {
          id: 5,
          title: `Then, click on the <b>Edit</b> message`,
          imageSource: `${GifBaseUrl}how-to-edit-message.gif`,
        },

        {
          id: 6,
          title: `Or click on the <img src=${cross} alt="iicon" height="12" width="12"/> button to discard changes.`,
        },
      ],
      // text1content: `<i>Note:&nbsp; While editing the text, if you select all the text in your message and erase it, you'll see a <b>"Delete message"</b> button appear, allowing you to delete the message directly.</i>`,
      Note: [
        {
          id: 1,
          title:
            "While editing the text, if you select all the text in your message and erase it, you'll see a Delete message button appear, allowing you to delete the message directly.",
        },
      ],
      add_title_bold: "Delete Message",
      add_steps: [
        {
          id: 1,
          title: ` Hover over the message you’d like to delete.`,
        },
        {
          id: 2,
          title: `Click on the button indicated by <b>3 vertically aligned dots</b>.`,
        },
        {
          id: 3,
          title: `Navigate to the <b>Delete</b> option.`,
        },
        {
          id: 4,
          title: ` A prompt will appear to confirm if you want to <b>Yes</b> or <b>No</b>.`,
        },
        {
          id: 5,
          title: `Click on the <b>Yes</b> button.`,
          imageSource: `${GifBaseUrl}how-to-delete-message.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Reply and Forward Message",
      onetitle: "Reply Message",
      steps: [
        {
          id: 1,
          title: `Hover over the message you want to reply to.`,
        },
        {
          id: 2,
          title: `Click on the reply button indicated by a <b>curved arrow pointing</b> to the left.`,
        },
        {
          id: 3,
          title: ` Now, in a new prompt type your reply message.`,
        },

        {
          id: 4,
          title: ` Then, click on the <img src=${send} alt="sendicon" height="12" width="12" style="margin-left: 5px; margin-right:5px"/> button to send your reply.`,
          imageSource: `${GifBaseUrl}how-to-reply-message.gif`,
        },
      ],
      add_title: "Forward message",
      add_steps: [
        {
          id: 1,
          title: `Hover over the message you want to forward to.`,
        },
        {
          id: 2,
          title: ` Click on the Forward option indicated by a right-side point arrow.`,
        },
        {
          id: 3,
          title:
            "A prompt will appear to confirm who you want to forward the message to.",
        },
        {
          id: 4,
          title: `Select the person by scrolling or typing the name to whom you want to forward the message.`,
        },
        {
          id: 5,
          title: `Click on the Forward button.`,
          imageSource: `${GifBaseUrl}how-to-forrward-message.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Remind Me About This",
      steps: [
        {
          id: 1,
          title:
            "Hover over the message for which you would like to receive a reminder.",
        },
        {
          id: 2,
          title:
            "Click on the button indicated by <b>3 vertically aligned dots</b>.",
        },
        {
          id: 3,
          title: `Navigate to the <b>Remind me about this</b> option.`,
        },
        {
          id: 4,
          title: ` Now, set the desired reminder time from the available default options.`,
        },
        {
          id: 5,
          title: ` Or you can click on the <b>Custom</b> option and create a reminder for your preferred date and time.`,
          imageSource: `${GifBaseUrl}remind-me-later.gif`,
        },
      ],
    },
  ],
};
