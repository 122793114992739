import taskicon from "./../../../../../new/icons/task.svg";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MPcontent = {
  title: "Manage Project",
  link: "manage-project",
  content: [
    {
      id: 0,
      title: "Edit Project Permission",
      onedescription:
        "As an owner or admin, you have the ability to delegate project creation authority to your members. To do so, please follow the steps outlined below:",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your current <b>workspace</b> from the top left corner
					`,
        },
        {
          id: 2,
          title: `From the drop-down option select <b> Workspace setting </b>option`,
        },
        {
          id: 3,
          title: `Under Administration, navigate to <b>Settings & Permissions</b>.`,
          imageSource: `${GifBaseUrl}setting-permission-in-project.gif`,
        },
      ],
      text1content: `<b>After clicking on the Ticket option, you can change the following settings:</b>`,
      isEditProjectPermisson: true,
    },

    {
      id: 1,
      title: "Edit Project Details",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on a <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: "Hover over the project that you want to edit details",
        },
        {
          id: 3,
          title: `Click on the <b>three horizontal dots</b>`,
        },
        {
          id: 4,
          title: "Select the <b>Edit</b> option",
        },
        {
          id: 5,
          title: `After making changes, click on <b>Update</b>`,
          imageSource: `${GifBaseUrl}editing-project-details.gif`,
        },
      ],
    },

    {
      id: 2,
      title: "Sharing Project",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left.`,
        },
        {
          id: 2,
          title: "Hover over the project that you want to share",
        },
        {
          id: 3,
          title: `Click on the <b>three vertical dots</b>`,
        },
        {
          id: 4,
          title: "Select the <b>Share</b> option",
        },
        {
          id: 5,
          title: "Now copy the link and share it with your peers",
          imageSource: `${GifBaseUrl}sharing-project.gif`,
        },
      ],
      text1content: `Remember, if you choose the <b>'Restricted' </b>option while sharing a project, the user must be logged in to view it. And members from other workspaces won't have access unless they are logged in to your workspace or added as project members.`,
    },
  ],
};
