export const contents = [
	{
		id: 1,
		title: "Managing Calendar ",
		link: "managing-calendar",
		listing: [
			{
				id: "0",
				title: "One time meeting",
			},
			{
				id: "1",
				title: "Import Calendar",
			},
			{
				id: "2",
				title: "Export Calendar",
			},
			{
				id: "3",
				title: "View holiday and leave on calendar",
			},
		],
	},
];
