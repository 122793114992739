import message from "../../../../../new/icons/message.svg";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const AZcontent = {
  title: "Zipy",
  link: "anydone-zipy",
  content: [
    {
      id: 0,
      title: "Activating Zipy",
      steps: [
        {
          id: 1,
          title: `Go to your anydone dashboard.`,
        },
        {
          id: 2,
          title: `Click on your profile type icon from the top right corner.`,
        },
        {
          id: 3,
          title: `Click on the <b>Preferences</b>.`,
        },
        {
          id: 4,
          title: `Navigate to <b>Zipy</b> and click on it .`,
        },
        {
          id: 5,
          title: `Now, tick Enable Zipy option.`,
        },
        {
          id: 6,
          title: `Then click <b>Save</b> button.`,
          imageSource: `${GifBaseUrl}activating-zipy.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Using Zipy",

      steps: [
        {
          id: 1,
          title: `Go to your anydone dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${message} alt="message" height="14" width="14"/>  icon from the left side.`,
        },
        {
          id: 3,
          title: `Click on <b>Zipy</b>.`,
        },
        {
          id: 4,
          title: `Click on <b>Thread</b>.`,
        },
        {
          id: 5,
          title: `Now, ask <b>Zipy Anything to do Everything</b>.`,
        },
        {
          id: 6,
          title: `You can also create and view your <b>To-Do</b> list from here.`,
          imageSource: `${GifBaseUrl}using-zipy.gif`,
        },
      ],
    },
  ],
};
