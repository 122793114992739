export const BaseAPI = "https://api.anydone.com/"; // for dev
// export const BaseAPI = "https://apigateway.anydone.net/"; // for prod
// export const LocalHost = "http://localhost:3000/"; // for local
// export const LocalHost = "https://inbox.anydone.net/"; // for dev
export const LocalHost = "https://app.anydone.com/"; // for prod

function createUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export const APIHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Debug-Id": createUUID().replace(/-/g, ""),
};
export const APIHeadersForMultipartFormData = {
  Accept: "application/json",
  "Debug-Id": createUUID().replace(/-/g, ""),
  // mimeType:'multipart/form-data'
};
