import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import {
	MainpageCardDetails,
	MainpgeBrowseHelp,
	whatsnewContent,
} from "./MainpageContent";
import { Link } from "react-router-dom";
import {
	CardImgDiv,
	CardTitle,
	DropDownDiv,
	FlexDiv,
	HelpHeader,
	InnerWhatsNew,
	MainContainer,
	MainHeaderText,
	PText,
	SearchDiv,
	SecTitle,
} from "../../../styleComponents";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import searchIcon from "../../../new/icons/search_icon.svg";
import whatisnew from "../../../new/homepage/whatisnew.svg";
import arrowRight from "../../../new/homepage/arrow-right.svg";
import { Waypoint } from "react-waypoint";
import { searchContent } from "../../../../SearchContent";

export default function MainHelp({ handleWaypointEnter, handleWaypointLeave }) {
	const navigate = useNavigate();
	const [searchText, setSearchText] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const [dropDownlist, setDropDownList] = useState([]);
	useEffect(() => {
		if (searchText) {
			setShowDropdown(true);

			const newDropDown = [];
			const newsearchText = searchText.toLowerCase();

			searchContent.map((item) => {
				if (item.title.toLowerCase().includes(newsearchText)) {
					// console.log(item.title, "*******", newsearchText);
					newDropDown.push(item);
				}
			});
			setDropDownList(newDropDown);
		} else {
			setShowDropdown(false);
		}
	}, [searchText]);
	

	return (
		<MainContainer>
			<Helmet>
				<title>anydone Help Center</title>
				<meta
					name="description"
					content="How can we help? anydone is a business communication platform that is very essential for carrying out crucial business activities."
				/>
			</Helmet>
			<HelpHeader>
				<MainHeaderText linehight>Hello! How can we help?</MainHeaderText>
				<SearchDiv>
					<img src={searchIcon} alt="search icon" />
					<input
						placeholder="Find anything"
						className="input-search"
						value={searchText}
						onChange={(e) => setSearchText(e.target.value)}
						// onKeyDown={(e) => {
						// 	if (e.key === "Enter" && searchText !== "") {
						// 		// navigate("/search", { state: searchText });
						// 		setSearchText("");
						// 	}
						// }}
					/>
				</SearchDiv>
				{showDropdown && (
					<DropDownDiv
						onMouseLeave={(event) => {
							setShowDropdown(false);
						}}
					>
						<div className="inner-dropdown">
							{dropDownlist &&
								dropDownlist.length >= 1 &&
								dropDownlist.map((each) => {
									return (
										<h1
											onClick={() =>
												navigate(`${each.path}?tabid=${each.active}`)
											}
										>
											{each.title}
										</h1>
									);
								})}

							{dropDownlist.length === 0 && (
								<p style={{ marginBottom: "0" }}>
									Sorry! We didn't find anything like "{searchText}"
								</p>
							)}
						</div>
					</DropDownDiv>
				)}
			</HelpHeader>
			<Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />
			<FlexDiv direction={"row"}>
				<Card className="main-card">
					<Row gutter={[60, 60]} className="main-row">
						{MainpageCardDetails.map((cards, index) => (
							<Col
								xl={8}
								md={8}
								sm={12}
								xs={24}
								key={index}
								className="main-col"
							>
								<Link to={`/${cards.path}`}>
									<Card className="card-details">
										<CardImgDiv>
											<img src={cards.image} alt={`${cards.title} icon`} />
										</CardImgDiv>

										<CardTitle alignText={"center"}>{cards.title}</CardTitle>
									</Card>
								</Link>
							</Col>
						))}
					</Row>
				</Card>
			</FlexDiv>
			<FlexDiv
				direction={"column"}
				textalign={"center"}
				className="whats-new-container"
			>
				<FlexDiv direction={"column"}>
					<SecTitle style={{ display: "flex", alignItems: "center" }}>
						<img
							className="browse-media"
							src={whatisnew}
							alt="not found"
							style={{ marginRight: "2rem" }}
						/>
						What’s New?
					</SecTitle>
					<InnerWhatsNew>
						<FlexDiv
							style={{
								width: "100%",
								flexWrap: "wrap",
								justifyContent: "space-between",
							}}
							direction="row"
							justifyItem
						>
							{whatsnewContent.map((each) => (
								<div
									className="whats-new-each-div"
									onClick={() => {
    navigate(`${each.link}?tabid=${each.active}`);
	window.scrollTo({
  top: 0,
  behavior: 'smooth'
});
  }}
								>
									<p>{each.title}</p>
									<img src={arrowRight} alt="not found" />
								</div>
							))}
						</FlexDiv>
					</InnerWhatsNew>
				</FlexDiv>
			</FlexDiv>

			<FlexDiv
				direction={"column"}
				textalign={"center"}
				style={{ margin: "50px 0px 0px", backgroundColor: "#EEF4FF" }}
			>
				<SecTitle>Browse our Help Center</SecTitle>

				<FlexDiv direction={"column"} className="browse-card">
					<Row gutter={[60, 30]}>
						{MainpgeBrowseHelp.map((cards, index) => (
							<div className="single-card">
								<div
									onClick={() => {
										cards.external
											? window.open(cards.elink, "_blank")
											: navigate(cards.link);
									}}
									style={{
										cursor: "pointer",
									}}
								>
									<FlexDiv justifyItem>
										<img
											className="browse-media"
											src={cards.image}
											alt="not found"
										/>
										<FlexDiv
											direction={"column"}
											textalign={"left"}
											alignItem={"flex-start"}
											style={{ marginLeft: "2rem" }}
										>
											<CardTitle alignText={"left"}>{cards.title}</CardTitle>
											{cards.desc.map((each) => (
												<PText color="#666666" text_start>
													{each}
												</PText>
											))}
										</FlexDiv>
									</FlexDiv>
								</div>
							</div>
						))}
					</Row>
				</FlexDiv>
			</FlexDiv>
		</MainContainer>
	);
}
