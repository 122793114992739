import React from "react";
export const MessageIcon = {
    PrivateIcon: (option) => (
        <svg
            width={option.width || "12"}
            height={option.height || "14"}
            viewBox="0 0 12 14"
            fill={option.background}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.5 6C1.36739 6 1.24021 6.05268 1.14645 6.14645C1.05268 6.24021 1 6.36739 1 6.5V12.5C1 12.6326 1.05268 12.7598 1.14645 12.8536C1.24021 12.9473 1.36739 13 1.5 13H10.5C10.6326 13 10.7598 12.9473 10.8536 12.8536C10.9473 12.7598 11 12.6326 11 12.5V6.5C11 6.36739 10.9473 6.24021 10.8536 6.14645C10.7598 6.05268 10.6326 6 10.5 6H1.5ZM1.5 5H10.5C10.8978 5 11.2794 5.15804 11.5607 5.43934C11.842 5.72064 12 6.10218 12 6.5V12.5C12 12.8978 11.842 13.2794 11.5607 13.5607C11.2794 13.842 10.8978 14 10.5 14H1.5C1.10218 14 0.720644 13.842 0.43934 13.5607C0.158035 13.2794 0 12.8978 0 12.5V6.5C0 6.10218 0.158035 5.72064 0.43934 5.43934C0.720644 5.15804 1.10218 5 1.5 5Z"
                fill={option.color}
            />
            <path
                d="M6.00037 7.5C6.13297 7.5 6.26015 7.55268 6.35392 7.64645C6.44769 7.74021 6.50037 7.86739 6.50037 8V11C6.50037 11.1326 6.44769 11.2598 6.35392 11.3536C6.26015 11.4473 6.13297 11.5 6.00037 11.5C5.86776 11.5 5.74058 11.4473 5.64681 11.3536C5.55304 11.2598 5.50037 11.1326 5.50037 11V8C5.50037 7.86739 5.55304 7.74021 5.64681 7.64645C5.74058 7.55268 5.86776 7.5 6.00037 7.5ZM9.00037 5V4C9.00037 3.20435 8.6843 2.44129 8.12169 1.87868C7.55908 1.31607 6.79602 1 6.00037 1C5.20472 1 4.44166 1.31607 3.87905 1.87868C3.31644 2.44129 3.00037 3.20435 3.00037 4V5H9.00037ZM6.00037 0C7.06123 0 8.07865 0.421427 8.82879 1.17157C9.57894 1.92172 10.0004 2.93913 10.0004 4V6H2.00037V4C2.00037 2.93913 2.42179 1.92172 3.17194 1.17157C3.92208 0.421427 4.9395 0 6.00037 0Z"
                fill={option.color}
            />
        </svg>
    ),
    PublicIcon: (option) => (
        <svg
            width={option.width || "15"}
            height={option.height || "16"}
            viewBox="0 0 15 16"
            fill={option.background}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.27782 1.00641C6.34521 1.01711 6.40983 1.04098 6.46798 1.07667C6.52613 1.11236 6.57668 1.15915 6.61674 1.21439C6.6568 1.26962 6.68558 1.33221 6.70144 1.39857C6.7173 1.46493 6.71992 1.53377 6.70916 1.60114L6.16843 4.98053H10.6549L11.2226 1.43705C11.249 1.30547 11.3253 1.1892 11.4355 1.11264C11.5458 1.03608 11.6813 1.00517 11.8139 1.02639C11.9464 1.04761 12.0655 1.11931 12.1463 1.22647C12.2272 1.33362 12.2633 1.4679 12.2473 1.60114L11.7073 4.98053H14.3313C14.469 4.98053 14.6011 5.03524 14.6985 5.13262C14.7959 5.23 14.8506 5.36208 14.8506 5.49979C14.8506 5.63751 14.7959 5.76959 14.6985 5.86697C14.6011 5.96435 14.469 6.01906 14.3313 6.01906H11.5411L10.8764 10.1732H13.6389C13.7767 10.1732 13.9087 10.2279 14.0061 10.3253C14.1035 10.4227 14.1582 10.5547 14.1582 10.6925C14.1582 10.8302 14.1035 10.9623 14.0061 11.0596C13.9087 11.157 13.7767 11.2117 13.6389 11.2117H10.7103L10.1702 14.5828C10.1439 14.7144 10.0676 14.8307 9.95733 14.9072C9.84711 14.9838 9.71152 15.0147 9.579 14.9935C9.44648 14.9722 9.32732 14.9005 9.24652 14.7934C9.16571 14.6862 9.12953 14.552 9.14556 14.4187L9.6579 11.2117H5.17144L4.6314 14.5828C4.60502 14.7144 4.52872 14.8307 4.41849 14.9072C4.30827 14.9838 4.17268 15.0147 4.04016 14.9935C3.90764 14.9722 3.78848 14.9005 3.70768 14.7934C3.62687 14.6862 3.59069 14.552 3.60672 14.4187L4.11906 11.2117H1.51927C1.38155 11.2117 1.24947 11.157 1.15209 11.0596C1.05471 10.9623 1 10.8302 1 10.6925C1 10.5547 1.05471 10.4227 1.15209 10.3253C1.24947 10.2279 1.38155 10.1732 1.51927 10.1732H4.28522L4.94989 6.01906H2.21508C2.07737 6.01906 1.94529 5.96435 1.84791 5.86697C1.75052 5.76959 1.69582 5.63751 1.69582 5.49979C1.69582 5.36208 1.75052 5.23 1.84791 5.13262C1.94529 5.03524 2.07737 4.98053 2.21508 4.98053H5.11605L5.68378 1.43705C5.70556 1.30123 5.78035 1.17959 5.89172 1.09885C6.0031 1.01811 6.14196 0.984866 6.27782 1.00641ZM9.82476 10.1732L10.4894 6.01906H6.00226L5.3376 10.1732H9.82406H9.82476Z"
                fill={option.color}
                stroke={option.color}
                stroke-width="0.3"
            />
        </svg>
    ),
    FilledCloseIcon: () => (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.952 2.25311C9.22069 -0.477598 4.77886 -0.477598 2.04758 2.25311C-0.682526 4.98381 -0.682526 9.42679 2.04758 12.1575C3.41322 13.5226 5.20663 14.2048 7.00007 14.2048C8.79352 14.2048 10.5863 13.5225 11.952 12.1575C14.6827 9.42682 14.6827 4.98381 11.952 2.25311ZM9.88833 9.26864C10.1165 9.49683 10.1165 9.86567 9.88833 10.0939C9.77452 10.2077 9.62513 10.2649 9.47571 10.2649C9.32631 10.2649 9.17689 10.2077 9.06309 10.0939L7.00005 8.03021L4.93758 10.0933C4.8232 10.2071 4.67378 10.2643 4.52496 10.2643C4.37557 10.2643 4.22615 10.2071 4.11234 10.0933C3.88416 9.86507 3.88416 9.49565 4.11234 9.26804L6.17481 7.205L4.11177 5.14196C3.88359 4.91378 3.88359 4.54436 4.11177 4.31675C4.33937 4.08857 4.7088 4.08857 4.93698 4.31675L7.00002 6.37979L9.06306 4.31675C9.29124 4.08857 9.66009 4.08857 9.88827 4.31675C10.1165 4.54436 10.1165 4.91378 9.88827 5.14196L7.82523 7.205L9.88833 9.26864Z"
                fill="#D2D2D2"
            />
        </svg>
    ),
    CrossIcon: () => (
        <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.87"
                d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                fill="#333333"
            />
        </svg>
    ),
};
