import plus from "./../../../../../new/icons/plus.png";
import taskicon from "./../../../../../new/icons/task.svg";
import deleteIcon from "./../../../../../new/icons/delete.svg";
import arrow from "./../../../../../new/icons/arrow.png";
import Zipyicon from "./../../../../../new/icons/zipy.svg";
import refineicon from "./../../../../../new/icons/refinetest.svg";

const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IGCcontent = {
  title: "Papers",
  link: "papers",
  content: [
    {
      id: 0,
      title: "Creating a New Paper",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Navigate to the project where you want to create paper and click on <b>Create New</b>.",
        },
        {
          id: 3,
          title: "Select <b>Papers Folder</b> and give folder a name.",
        },

        {
          id: 4,
          title: `Start writing content in different format.`,
          imageSource: `${GifeBaseUrl}new-paper.gif`,
        },
      ],

      add_title: "Additional Information:",
      add_steps: [
        {
          id: 1,
          title: `Press <b>@</b> and mention a project member to collaborate on the paper.`,
        },
        {
          id: 2,
          title:
            "Press <b>/</b> to get a list of options to make your paper contextual.",
        },
        {
          id: 3,
          title: "Press <b>Ctrl+Z</b> to undo changes.",
        },

        {
          id: 4,
          title: `Click on the three vertical dots next to your paper title to <b>Duplicate, Delete, Share</b> paper`,
        },
      ],
    },

    {
      id: 1,
      title: "Editing a Paper",
      steps: [
        {
          id: 1,
          title: `Click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Hover over the project paper that you want to edit and click on <b>three vertical dots</b>.",
        },
        {
          id: 3,
          title: "Click on <b>Edit</b> option.",
        },
        {
          id: 4,
          title: `Make the necessary changes and click on <b>Update</b> option.`,
          imageSource: `${GifeBaseUrl}editing-paper.gif`,
        },
      ],
    },

    {
      id: 2,
      title: "Write with Zipy",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the  <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Navigate to the <b>Project</b> and click on your <b>Paper Folder</b>.",
        },
        {
          id: 3,
          title: "Click on <b>New Paper</b>.",
        },
        {
          id: 4,
          title: `Give paper a title.`,
        },
        {
          id: 5,
          title:
            "Now, write a few words about the content you want Zipy to write.",
        },
        {
          id: 6,
          title: "Now mark that words and you'll see multiple options below.",
        },
        {
          id: 7,
          title: `From here, click on <img src=${refineicon} alt="task"  height="16" width:"16"/> icon.`,
        },
        {
          id: 8,
          title: `Click on click on the  <img src=${Zipyicon} alt="task"  height="16" width:"16"/> icon.`,
        },
        {
          id: 9,
          title:
            "Now in the <b>Ask Zipy</b> placeholder add the topic that you want Zipy to write.",
        },
        {
          id: 10,
          title: `Then, click on <img src=${arrow} alt="task"  height="14" width:"14"/> button to generate text.`,
          imageSource: `${GifeBaseUrl}write-with-zipy.gif`,
        },
      ],
      Note: [
        {
          title: `From the same text editor (Ask Zipy), you can also refine your selected text. For example, you can change it to casual, professional, shorten, elaborate, and more. Additionally, you can translate the selected text into any language you want.`,
        },
      ],
    },

    // {
    //   id: 2,
    //   title: "Cloning a Paper",

    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon`,
    //     },
    //     {
    //       id: 2,
    //       title:
    //         "Hover over the project paper that you want to clone and click on <b>three vertical dots</b>.",
    //     },
    //     {
    //       id: 3,
    //       title: "Click on <b>Clone</b> option.",
    //     },
    //     {
    //       id: 4,
    //       title: `Now, select a project and folder where you want to clone paper to move.`,
    //     },
    //   ],
    // },

    // {
    //   id: 3,
    //   title: "Pin to Message",

    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon`,
    //     },
    //     {
    //       id: 2,
    //       title:
    //         "Hover over the project paper that you want to pin and click on <b>three vertical dots</b>.",
    //     },
    //     {
    //       id: 3,
    //       title: "Click on <b>Pin to message</b> option.",
    //     },
    //     {
    //       id: 4,
    //       title: `Now, select a DMs, CoSpaces or members where you want to pin the paper.`,
    //     },
    //   ],
    // },

    // {
    //   id: 4,
    //   title: "Share the Paper",

    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon`,
    //     },
    //     {
    //       id: 2,
    //       title:
    //         "Hover over the project paper that you want to share and click on <b>three vertical dots</b>.",
    //     },
    //     {
    //       id: 3,
    //       title: "Click on <b>Share</b> option.",
    //     },
    //     {
    //       id: 4,
    //       title: `Now, copy the link and share it with your peers.`,
    //     },
    //   ],
    // },
  ],
};
