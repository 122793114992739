import iicon from "./../../../../../new/icons/i.svg";
import message from "./../../../../../new/icons/message.svg";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MGcontent = {
  title: "Manage CoSpace",
  link: "manage-groups",
  content: [
    {
      id: 0,
      title: "Mute Cospace",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Hover over the cospace that you want to mute.`,
        },
        {
          id: 3,
          title: `Click on the Option button indicated by <b>3  horizontally aligned dots</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>Mute</b> option.`,
        },
        {
          id: 5,
          title: `A Mute icon will appear alongside the conversation thread.`,
          imageSource: `${GifBaseUrl}mute-cospace.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Unmute CoSpace",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Hover over the cospace that you want to unmute.`,
        },
        {
          id: 3,
          title: `Click on the button indicated by <b>3  horizontally aligned dots</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>unmute</b> option.`,
          imageSource: `${GifBaseUrl}unmute-cospace.gif`,
        },
      ],
    },
    {
      id: 2,
      title: " Mute Specific Participants from the CoSpace",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Under the <b>CoSpace</b> section, click on the cospace in which you want to <b>mute the</b> specific participant.`,
        },
        {
          id: 3,
          title: `Click on <img src=${iicon} alt="iicon" height="28" width="28"/>  button from the right side corner.`,
        },
        {
          id: 4,
          title: `Under <b>participants</b>, hover over the person that you want to mute.`,
        },
        {
          id: 5,
          title: `Click on the <b>three horizontal dots</b> and click on the <b>Mute</b> option.`,
          imageSource: `${GifBaseUrl}mute-specific-participant.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Unmute Specific Participant from the CoSpace",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Under the <b>CoSpace</b> section, click on the cospace in which you want to unmute specific participant.`,
        },
        {
          id: 3,
          title: `Click on <img src=${iicon} alt="iicon" height="28" width="28"/> button from the right side corner.`,
        },
        {
          id: 4,
          title: `Under <b>participants</b>, hover over the participant that you want to unmute.`,
        },
        {
          id: 5,
          title: `Click on the <b>three horizontal dots</b> and click on the <b>Unmute</b> option.`,
          imageSource: `${GifBaseUrl}unmute-specific-participant.gif`,
        },
      ],
    },
  ],
};
