export const contents = [
  {
    id: 1,
    title: "Creating and Organizing Project ",
    link: "creating-and-organizing-project",
    listing: [
      {
        id: "0",
        title: "Creating a Project",
      },
      {
        id: "1",
        title: "Archiving Project ",
      },
      {
        id: "2",
        title: "View Archived Projects",
      },
      {
        id: "3",
        title: "Restoring Archived Project ",
      },
    ],
  },
  {
    id: 2,
    title: "Manage Project",
    link: "manage-project",
    listing: [
      {
        id: "0",
        title: "Edit Project Permission",
      },
      {
        id: "1",
        title: "Edit Project Details",
      },
      {
        id: "2",
        title: "Sharing Project ",
      },
    ],
  },
  {
    id: 3,
    title: "Project Members and Permission Settings",
    link: "project-members-and-permission-settings",
    listing: [
      {
        id: "0",
        title: "Add members to Project",
      },
      {
        id: "1",
        title: "Change Project Member Permission",
      },
      {
        id: "2",
        title: "Remove Member from Project",
      },
    ],
  },
  {
    id: 4,
    title: "Invite External and Company to Project",
    link: "invite-external-and-company-to-project",
    listing: [
      {
        id: "0",
        title: "Inviting Company to Project",
      },
      {
        id: "1",
        title: "Inviting External to Project",
      },
    ],
  },

  {
    id: 5,
    title: "Roadmap",
    link: "roadmap",
    listing: [
      {
        id: "0",
        title: "Creating a Product Roadmap",
      },
      {
        id: "1",
        title: "Different Views for Roadmap",
      },
    ],
  },
  {
    id: 6,
    title: "Forms",
    link: "forms",
    listing: [
      {
        id: "0",
        title: "Creating a New Form",
      },
      {
        id: "1",
        title: "Editing Form Field",
      },
      {
        id: "2",
        title: "Share Form",
      },
      {
        id: "3",
        title: "Delete Form",
      },
    ],
  },

  {
    id: 7,
    title: "Papers",
    link: "papers",
    listing: [
      {
        id: "0",
        title: "Creating a New Paper",
      },
      {
        id: "1",
        title: "Editing a Paper",
      },
      {
        id: "2",
        title: "Write with Zipy",
      },
      // {
      //   id: "3",
      //   title: "Pin to Message",
      // },
      // {
      //   id: "4",
      //   title: "Share the Paper",
      // },
    ],
  },
];
