import Automation from "../../../../../new/icons/automation.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
export const ABcontent = {
  title: "Create Automation Tool (AI)",
  link: "anydone-creation",
  content: [
	{
		id: 0,
		title: "Entity",
  
		steps: [
		  {
			id: 1,
			title: `Log in to your dashboard`,
		  },
		  {
			id: 2,
			title: `Click on the <img src=${Automation} alt="automation" height="12" width="12"/> icon.`,
		  },
		  {
			id: 3,
			title: `Under <b>"AI"</b> select <b>"Entity."</b>`,
		  },
		  {
			id: 4,
			title: `In the top right corner, click the <b>"+"</b> button.`,
		  },
		  {
			id: 5,
			title: `Give Entity a name and add a <b>description</b>.`,
		  },
		  {
			id: 6,
			title: `Select the Entity type: <b>Regex</b> or <b>Synonym</b>.	<br><br>
			<b>Regex</b><br>
		   If you select a <b><u>Regular Expression (Regex)</u></b>, start by defining the Regex expression in the placeholder. For instance, if you're creating an entity to extract email addresses, the Regex expression could be [a-z]*@[a-z]*\.[a-z]*
					  <br><br>
					  Next, add a relevant prompt under <b>"Prompt."</b> For example, if you want to extract email addresses, a possible prompt could be: <b><i>"What is your email address?” Please provide your email."
					  entity-email."</i></b>
					  <br>
					
		   
			`,
			imageSource: `${imageUrl}entity-email.gif`,
			title2:`<b>Synonym</b><br>
					  If you select Synonyms then you need to define Reference Value, Synonyms and Prompt. For example you can add "New York City" in <u>Reference Value</u> and <u>Synonyms</u> could be "NYC", "New York", "Big Apple" and <u>Prompt</u>: <b><i>"Enter the location of the event” "Give the address where the event will occur."</i></b><br><br>`,
	 imageSource2:`${imageUrl}entity-synonyms.gif`	
		  },
		
		],
	
	   
	  },
	  {
		id: 1,
		title: "Intent Collection",
  
		steps: [
		  {
			id: 1,
			title: `Log in to anydone.`
		  },
		  {
			  id: 2,
			  title: `Click on the <img src=${Automation} alt="automation" height="12" width="12"/> icon.`
			},
			{
			  id: 3,
			  title: `Under <b>AI</b> Select the <b>Intent Collection</b> option.`
			},
			{
			  id: 4,
			  title: `Click the <b>'+'</b> button from the right corner.`
			},
			{
			  id: 5,
			  title: ` Enter the name and description.`
			},
			{
			  id: 6,
			  title: `   Then, click the <b>'Create'</b> button.<br><br>
			  <b>Now, click the '+' button in the top right corner again.</b>`
			},
			{
			  id:7,
			  title:`Then, enter the intent name.`
		  },
		  {
			  id:8,
			  title:` Define the threshold.`
		  },
		  {
			  id:9,
			  title:` Add all the phrases that correspond to your intents.`
		  },
		  {
			  id:10,
			  title:` Then, click the <b>'Create'</b> button.<br>`,
			 imageSource: `${imageUrl}anydone-intent-collection.gif`
		  }
		],
		
	  
		add_title:"<b><u>Creating Automated Reply with Intent Collection</u></b>",
		text1content:"<br>After creating the intent collection, the next step is to set up automated replies and add that intent collection in it. This will ensure that the system responds appropriately based on the identified intents.<br><br>Follow the steps to create automated reply by adding intent collection<br>",
		add_steps:[
		  {
			  id:1,
			  title:`Log in to your dashboard.`
		  },
		  {
			  id:2,
			  title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon.`
		  },
		  {
			  id:3,
			  title:` Select <b>Automated Replies</b> from the options..`
		  },
		  {
			  id:4,
			  title:`Click the <b>'+'</b> button in the top right corner.`
		  },
		  {
			  id:5,
			  title:`Enter the name and add a description.`
		  },
		  {
			  id:6,
			  title:`Click on the <b>Source</b> placeholder.`
		  },
		  {
			  id:7,
			  title:` From the drop-down, select <b>Intent Collection.</b>`
		  },
		  {
			  id:8,
			  title:` Select the intent collection that you’ve created.`
		  },
		  {
			  id:9,
			  title:` Now, add the <b>Reply text.</b>`
		  },
		  {
			  id:10,
			  title:` Under the <b>Handoff Rule</b>, select your preferred option. (Here, we've selected Fixed Replies.)`
		  },
		  {
			  id:11,
			  title:` Lastly, click the <b>Create</b> button.<br><br>`,
			  imageSource: `${imageUrl}automated-reply-anydone.gif`
  
		  },
		],
		text2content:`<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=2" style="color:white">See how it works</a></button>`
	  },
    {
      id: 2,
      title: "Knowledge Base",

      steps: [
        {
          id: 1,
          title: `Log in to anydone`
		},
		{
			id: 2,
			title: `Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon.`
		  },
		  {
			id: 3,
			title: `Select <b>Knowledge Base</b>.`
		  },
		  {
			id: 4,
			title: `Enter a name and description.`
		  },
		  {
			id: 5,
			title: `Under Source, choose the type of<b> Knowledge Base</b>, here, we've selected the <b>URL</b>.`

		  },
		  {
			id: 6,
			title: `Add the <b>URL</b>.`
		  },
		  {
			id: 6,
			title: `Define the <b>Update Frequency</b>.`
		  },
		  {
			id: 7,
			title: `Lastly, click the <b>Create</b> button.<br>
			`,
			imageSource: `${imageUrl}anydone-knowledge-base.gif`
		  },
      ],
	  add_title:"<b><u>Creating Automated Reply with Knowledge Base</u></b>",
	  text1content:"<br>After creating the Knowledge Base, the next step is to set up automated replies. This will ensure that the system delivers accurate responses based on the information stored in the Knowledge Base.<br><br>Follow the steps to create automated reply by adding intent collection:<br>",
	  add_steps:[
		{
			id:1,
			title:`Log in to Anydone.`
		},
		{
			id:2,
			title:`Click on the <img src=${Automation} alt="automation" height="12" width="12"/> icon.`
		},
		{
			id:3,
			title:`Select <b>Automated Replies</b> from the options.`
		},
		{
			id:4,
			title:`Click the '+' button in the top right corner.`
		},
		{
			id:5,
			title:`Enter the name and description.`
		},
		{
			id:6,
			title:`Under <b>Source</b>, select<b> Knowledge Base</b>.`
		},
		{
			id:7,
			title:`Select the Knowledge Base that you've created.`
		},
		{
			id:8,
			title:`Select the model (Anydone Model) by default.`
		},
		{
			id:9,
			title:`Lastly, click the <b>Create</b> button.<br><br>

			`,
			imageSource: `${imageUrl}anydone-automated-replies-kb.gif`
		}
	  ],
	  text2content:`<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=0" style="color:white">See how it works</a></button>`

	  
    },
    {
      id: 3,
      title: "Knowledge Graph",

      steps: [
        {
          id: 1,
          title: `Log in to Anydone`
		},
		{
			id: 2,
			title: `Click on the <img src=${Automation} alt="automation" height="12" width="12"/> icon.`
		  },
		  {
			id: 3,
			title: `Select the <b>Knowledge Graph</b> option.`
		  },   
		  {
			id: 4,
			title: `Click the <b>'+'</b> button in the top right corner.`
		  },  
		  {
			id: 5,
			title: `Enter a name for it.`
		  },  
		  {
			id: 6,
			title: `Under the <b>Question</b> placeholder, enter all the possible questions.`
		  },  
		  {
			id: 7,
			title: `Click on <b>Add Answer</b> to the right of the same placeholder and enter the answer.`
		  },  
		  {
			id: 8,
			title: `In the same prompt, you can add steps, and feedback replies as well.`
		  },  
		  {
			id: 9,
			title: `Lastly, click the <b>Create</b> button.`,
			imageSource: `${imageUrl}anydone-knowledge-graph.gif`
		  },  
		],

		add_title:"<b><u>Creating Automated Reply with Knowledge Graph</u></b>",
		text1content:"<br>After creating the Knowledge Graph, the next step is to set up automated replies. This will ensure that the system delivers accurate responses based on the information defined in the Knowledge Graph.<br><br>Follow the steps to create automated reply with knowledge graph:<br>",
		add_steps:[
			{
				id:1,
				title:`Log in to your anydone dashboard.`
			},
			{
				id:2,
				title:` Click on the <img src=${Automation} alt="automation" height="12" width="12"/> icon on the left-hand side.`
			},
			{
				id:3,
				title:` Select <b>Automated Replies</b> from the options.`
			},
			{
				id:4,
				title:`Click the '+' button in the top right corner.`
			},
			{
				id:5,
				title:`Enter the name and description.`
			},
			{
				id:6,
				title:`Click on <b>Source</b>.`
			},
			{
				id:7,
				title:`From the drop-down menu, select <b>Knowledge Graph</b>.`
			},
			{
				id:8,
				title:`Then, select the Knowledge Graph you created earlier from the drop-down option.`
			},
			{
				id:9,
				title:`  Lastly, click the <b>Create</b> button.<br>


`,
imageSource: `${imageUrl}automated-replies-knowledge-graph.gif`
			},
		],
		text2content:`<button style="display: block; padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=1" style="color:white">See how it works</a></button>`
   
   
   






 

    },
    {
		id: 4,
		title: "Automated Reply",
		onetitle:`Automated replies can be created using the <a>Knowledge Base, Knowledge Graph, third-party models (text generation),</a> and <a href="anydone-creation?tabid=1">Intent Collection</a>.<br><br>Once you've created these AI tools with automated replies, you can view them by following the steps below, and you can also try them out from here:`,
		steps: [
		  {
			id: 1,
			title: `Login to anydone `,
		  },
		  {
			id: 2,
			title: `Click on the automation tool icon `,
		  },
		  {
			id: 3,
			title: `Under <b>AI</b>, select <b>Automated Replies</b> option `,
			imageSource: `${imageUrl}anydone-automated-replies-kb.gif`,
			title2:`While creating an automated reply, you can also use the Default Automated Reply option and create the Flowcess.`

		  },
		],
		text2content:`<button style="display: block; padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=3" style="color:white">See how it works</a></button>`

	  },
    {
      id: 5,
      title: "Third Party Model",

	add_title:`The Third-Party Model allows you to create following models, but before creating any of them , you need to <a href="automation-tools?tabid=0">create Secrets</a> first. <br><br>
	• <b>Text Generation</b><br>
	• <b>Speech to Text</b><br>
	• <b>Text to Speech</b><br>
	`,

	
	one_more_title:"<b><u>For Text Generation</u></b>",
	one_more_steps:[
         {
			id:1,
			title:`Log in to anydone.`
		 },
		 {
			id:2,
			title:`Click on the <b>automation tool</b> icon.`
		 },
		 {
			id:3,
			title:`Click on the <b>+</b> button.`
		 },
		 {
			id:4,
			title:`Enter a name and select a provider.`
		 },
		 {
			id:5,
			title:`Under <b>Type</b>, choose the <b>Text Generation</b> option.`
		 },
		 {
			id:6,
			title:`Under <b>API Key</b>, select the key you created in Secrets.`
		 },
		 {
			id:7,
			title:`In the <b>Model Configuration Settings</b>, define the settings according to your needs.`
		 },
		 {
			id:8,
			title:`Finally, click the <b>Create</b> button.<br><br>
			

			
			`,
imageSource: `${imageUrl}text-generation-model.gif`
		 },
	],
	more_title:"<b><u>Creating Automated Reply with Third Party Model(Text Generation)</u></b>",
	more_steps:[
		{
			id:1,
			title:`Log in to anydone.`
		},
		{
			id:2,
			title:`Click on the automation tool icon.`
		},
		{
			id:3,
			title:`Select <b>Automated Reply</b>.`
		},
		{
			id:4,
			title:`Click the <b>+</b> button in the top right corner.`
		},
		{
			id:5,
			title:`Enter a name for it.`
		},
		{
			id:6,
			title:`Under <b>Source</b>, select <b>Generative Model</b>.`
		},
		{
			id:7,
			title:`Under <b>Model</b>, choose the model you created for text generation.`
		},
		{
			id:8,
			title:`Finally, click the <b>Create</b> button.<br>`,
			imageSource: `${imageUrl}model-text-generation.gif`
		},

	],
	text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=5" style="color:white">See how it works</a></button>`,
	last_title:"<b><u>For Speech to Text</u></b>",
	last_steps:[
		{
			id:1,
			title:`Log in to your Anydone dashboard.`
		},
		{
			id:2,
			title:`Click on the <b>Automation Tool</b> icon.`
		},
		{
			id:3,
			title:`Select the Third-Party <b>Model</b>.`
		},
		{
			id:4,
			title:`Click the <b>+</b> button in the top right corner.`
		},
		{
			id:5,
			title:`Enter a name.`
		},
		{
			id:6,
			title:`Select a provider.`
		},
		{
			id:7,
			title:`Under <b>Type</b>, choose <b>Speech to Text</b>.`
		},
		{
			id:8,
			title:`Under <b>API Key</b>, select the secret key you created.`
		},
		{
			id:9,
			title:`Finally, click the <b>Create</b> button.<br><br>`,
			imageSource: `${imageUrl}speech-to-text-model.gif`
		},
	],
	lastt_text:`Once you create the model, you need to <a href="anydone-creation-process?tabid=0"><u>create a speech to text  process</u></a> so that you can use it in your Flowcess.<br><br>`,
	lastt_text2:`Once you create the model, you need to create a <a href="anydone-creation-process?tabid=1"><u>text to speech process</u></a> so that you can use it in your Flowcess.<br><br>`,
	lastt_title:"<b><u>For Text to Speech </u></b>",
	lastt_steps:[
		{
			id:1,
			title:`Log in to your Anydone dashboard.`
		},
		{
			id:2,
			title:`Click on the Automation Tool icon.`
		},
		{
			id:3,
			title:`Select the Third-Party Model.`
		},
		{
			id:4,
			title:`Click the + button in the top right corner.`
		},
		{
			id:5,
			title:`Enter a name.`
		},
		{
			id:6,
			title:`Select a provider.`
		},
		{
			id:7,
			title:`Under <b>Type</b>, choose <b>Text to Speech</b>.`
		},
		{
			id:8,
			title:`Under <b>API Key</b>, select the secret key you created.`
		},
		{
			id:9,
			title:`Finally, click the <b>Create</b> button.<br>`,
			imageSource: `${imageUrl}process-text-to-speech.gif`
		},

	]
    },
	
	
	  
  
	
	 
  ],
};
