import React, { useEffect, useState } from "react";
import { Col, Menu } from "antd";
import GoBack from "../../../../common/GoBack";
import { useLocation, useNavigate } from "react-router-dom";

import {
	RowDiv,
	SecStartedContent,
	Stickydiv,
} from "../../../../../styleComponents";
import BorderDiv from "../../../../common/BorderDiv";
// import { Helmet } from "react-helmet";
import MainHeader from "../../../../common/MainHeader";
import { ARcontent } from "./content";
import LayoutPage from "../LayoutPage";
import { Waypoint } from "react-waypoint";

function AutomationTools({ handleWaypointEnter, handleWaypointLeave }) {
	const location = useLocation();
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(location.search);
	const tabsValue = searchParams.get("tabid");
	const [active, setActive] = useState(tabsValue);

	const handleClick = (e) => {
		setActive(e.key);
	};
	const [content, setContent] = useState();
	useEffect(() => {
		setActive(tabsValue);
		setContent(ARcontent.content[tabsValue]);
	}, [tabsValue]);
	return (
		<div>
			<MainHeader heading={ARcontent.title} />
			<Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

			<SecStartedContent>
				<RowDiv>
					<Col
						xs={{ span: 24 }}
						md={{ span: 5 }}
						lg={{ span: 4 }}
						className="first-column"
					>
						<Stickydiv height="43rem">
							<GoBack title="Automation" path="automation" />
							<BorderDiv mb={1} mt={1} />
							<div className="started-items">
								<Menu
									onClick={handleClick}
									selectedKeys={active}
									mode="vertical"
									className="side-menu"
								>
									{ARcontent.content.map((items, index) => (
										<Menu.Item
											key={index}
											onClick={() => {
												navigate(
													`/automation/automation-tools?tabid=${items.id}`
												);
												window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });	
											}}
										>
											{items.title}
										</Menu.Item>
									))}
								</Menu>
							</div>
						</Stickydiv>
					</Col>
					{active && <LayoutPage content={content} />}
				</RowDiv>
			</SecStartedContent>
		</div>
	);
}

export default AutomationTools;
