import React from "react";
import { Link } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

export default function GoBack({ title, path }) {
  return (
    <>
      <div className="go-back">
        <Link to={`/${path ? path : ""}`} style={{ color: "#666666" }}>
          <span style={{ paddingRight: "5px" }}>
            <LeftOutlined />
          </span>
          Go back to {title ? title : "Help Center"}
        </Link>
      </div>
    </>
  );
}
