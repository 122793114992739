export const contents = [
  {
    id: 1,
    title: "Creating and Managing Ticket Folder",
    link: "creating-and-managing-task-folder",
    listing: [
      {
        id: "0",
        title: "Creating New Ticket Folder",
      },
      {
        id: "1",
        title: "Edit Ticket Folder",
      },
      {
        id: "2",
        title: "Pin ticket folder to DMs, CoSpace, or Members",
      },
      {
        id: "3",
        title: "Archiving  folder",
      },
      {
        id: "3",
        title: "Share task folder",
      },
    ],
  },
  {
    id: 2,
    title: "Creating and Managing Ticket",
    link: "creating-and-managing-ticket",
    listing: [
      {
        id: "0",
        title: "Creating ticket",
      },
      // {
      //   id: "1",
      //   title: "Moving Ticket",
      // },
      {
        id: "1",
        title: "Clone Ticket",
      },
      {
        id: "2",
        title: "Archive ticket",
      },
      {
        id: "3",
        title: "Restore archived tickets",
      },
      {
        id: "4",
        title: "Sharing ticket",
      },
      {
        id: "5",
        title: "Make Changes in Ticket",
      },
    ],
  },

  {
    id: 2,
    title: "Ticket Dashboard",
    link: "ticket-dashboard",
    listing: [
      {
        id: "0",
        title: "Your Work",
      },
      {
        id: "1",
        title: "Project Dashboard",
      },
    ],
  },

  {
    id: 3,
    title: "Ticket Reports",
    link: "ticket-report",
    listing: [
      {
        id: "0",
        title: "View Ticket Reports",
      },
    ],
  },

  {
    id: 4,
    title: "Ticket Workflow",
    link: "ticket-workflow",
    listing: [
      {
        id: "0",
        title: "Set Ticket Status Approval",
      },
      {
        id: "1",
        title: "Set Ticket Transition",
      },
    ],
  },

  {
    id: 5,
    title: "Checklist",
    link: "checklist",
    listing: [
      {
        id: "0",
        title: "Generate Checklist",
      },
    ],
  },

  {
    id: 6,
    title: "Subtasks",
    link: "subtasks",
    listing: [
      {
        id: "0",
        title: "Generate Subtasks",
      },
    ],
  },

  {
    id: 7,
    title: "Custom Field",
    link: "custom-field",
    listing: [
      {
        id: "0",
        title: "Create Custom Fields",
      },
    ],
  },

  {
    id: 8,
    title: "Creating & Managing Sprint Folders",
    link: "creating-managing-sprint-folders",
    listing: [
      {
        id: "0",
        title: "Create Sprint Folder",
      },
      {
        id: "1",
        title: "Create new Sprint ",
      },
    ],
  },

  {
    id: 9,
    title: "Auto Assign",
    link: "auto-assign",
    listing: [
      {
        id: "0",
        title: "Auto Assign",
      },
    ],
  },
];
