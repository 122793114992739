import iicon from "./../../../../../new/icons/i.svg";
import message from "./../../../../../new/icons/message.svg";

const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Gcontent = {
  title: "CoSpaces",
  link: "groups",
  content: [
    {
      id: 0,
      title: "Join CoSpace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the <img src=${message} alt="message" height="14" width="14"/> icon.`,
        },
        {
          id: 2,
          title: ` Click on <b>Message</b> option from the top left.`,
        },
        {
          id: 3,
          title: `From the dropdown menu select <b>Browse CoSpace</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>Public CoSpaces</b> or <b>Public Subject</b>.`,
        },
        {
          id: 5,
          title: `Select the CoSpace you want to join and click on <b>Join</b>.`,
          imageSource: `${GifeBaseUrl}join-cospace.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "In anydone, you can join public CoSpaces or public subjects on your own, but for private CoSpaces or private subjects, someone from that CoSpace must add you.",
        },
      ],
    },
    {
      id: 1,
      title: "Leave CoSpace",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the <img src=${message} alt="message" height="14" width="14"/> icon.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace that you want to <b>leave</b>.`,
        },
        {
          id: 3,
          title: `Click on the icon <img src=${iicon} alt="i-icon" /> icon located at the top right.`,
        },
        {
          id: 4,
          title: `Click on <b>Leave CoSpace</b>.`,
        },
        {
          id: 5,
          title: `Now, select one member from the CoSpace as an admin.`,
        },
        {
          id: 6,
          title: `A new prompt will appear on your screen; click on the <b>Make Admin and Leave</b> button.`,
          imageSource: `${GifeBaseUrl}leave-cospace.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "If you are an admin of the CoSpace that you want to leave, then it's mandatory to make one member an admin from that CoSpace before you leave.",
        },
      ],
    },
    {
      id: 2,
      title: "Leave and Delete CoSpace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the <img src=${message} alt="message" height="14" width="14"/> icon.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace that you want to <b>leave and delete</b>.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${iicon} alt="i-icon" /> button located at the top right.`,
        },
        {
          id: 4,
          title: `Click on <b>Leave and Delete</b> CoSpace.`,
        },
        {
          id: 5,
          title: `A new prompt will appear on your screen, showing two options.`,
        },
        {
          id: 6,
          title: `The first option is to l<b>eave and delete</b> the CoSpace permanently.`,
        },
        {
          id: 7,
          title: `Second is leave and delete the CoSpace for yourself only; if you choose this option, then you need to make one member from the CoSpace an admin before taking any action.`,
        },
        {
          id: 8,
          title: `Then click on the <b>Leave and Delete</b> button.`,
          imageSource: `${GifeBaseUrl}leave-delete-cospace.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "If you are an admin of the CoSpace that you want to leave and delete, then it's mandatory to make one member an admin from that CoSpace before you leave and delete the CoSpace.",
        },
      ],
    },
    {
      id: 3,
      title: "Archive CoSpace",
      ondtitle: `<i>Remember, only the admin can archive the group.</i>`,
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the <img src=${message} alt="message" height="14" width="14"/> icon.`,
        },
        {
          id: 2,
          title: `Navigate to the CoSpace that you want to archive.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${iicon} alt="i-icon" /> button located at the top right.`,
        },
        {
          id: 4,
          title: `Click on the <b>Archive CoSpace</b> option.`,
        },
        {
          id: 5,
          title: `A new prompt will appear on your screen; click on the <b>Archive</b> button.`,
          imageSource: `${GifeBaseUrl}archiving-cospaces.gif`,
        },
      ],
      text1content: `<i>Remember, only the admin can archive the CoSpace .</i>`,
    },
    {
      id: 4,
      title: "View Archived CoSpaces",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the<b> Workspace</b> icon.`,
        },
        {
          id: 2,
          title: `From the drop-down menu select <b>Workspace</b> Settings option.`,
        },
        {
          id: 3,
          title: `Under Administration, click on the <b>Archive CoSpaces</b>.`,
        },
        {
          id: 4,
          title: `Now click on the <b>Archived CoSpace</b> option.`,
          imageSource: `${GifeBaseUrl}view-archived-cospace.gif`,
        },
      ],
    },
    {
      id: 5,
      title: "Unarchive CoSpace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the<b> Workspace</b> icon.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b> .`,
        },
        {
          id: 3,
          title: `Under Administration, click on the <b>Archive CoSpaces</b>.`,
        },
        {
          id: 4,
          title: `Here you can view all Archived CoSpace, you can also unarchive the Cospace from here.`,
        },
        {
          id: 5,
          title: `Hover over the CoSpace you want to unarchive and click on the <b>three dots</b>.`,
        },
        {
          id: 6,
          title: `Finally, click on <b>Unarchive</b> option.`,
          imageSource: `${GifeBaseUrl}unarchive-cospace.gif`,
        },
      ],
    },
  ],
};
