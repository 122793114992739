import plus from "./../../../../../new/icons/plus.png";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IMGcontent = {
  title: "Inviting and Managing Externals",
  link: "inviting-and-managing-externals",
  content: [
    {
      id: 0,
      title: "Inviting Externals",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on your Workspace icon from the left.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under Administration, navigate to the <b>External Teams</b> and click on it.`,
        },
        {
          id: 4,
          title: `Click on <b>Individual</b> from the top`,
        },
        {
          id: 5,
          title: `Click the <img src=${plus} alt="plus" height="14px" width="14px"/> icon from the right side corner.`,
        },
        {
          id: 6,
          title: `Enter an external's email.`,
        },
        {
          id: 7,
          title: `Specify a time limit and the CoSpace for the external.`,
        },
        {
          id: 8,
          title: `Then, click on the <b>Invite</b> button.`,
          imageSource: `${GifBaseUrl}invite-guest.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Convert External To Member",
      steps: [
        {
          id: 1,
          title: `From the dashboard click on your workspace icon from the left.`,
        },
        {
          id: 2,
          title: `Select <b>Workspace Settings</b> option.`,
        },
        {
          id: 3,
          title: `Under Administration, click on <b>External Teams</b> option.`,
        },
        {
          id: 4,
          title: `Click on the <b>Individual</b> option from the top.`,
        },
        {
          id: 5,
          title: `Hover over the External name that you want to change into a member.`,
        },
        {
          id: 6,
          title: `Click on the <b>three vertical dots</b> option.`,
        },
        {
          id: 7,
          title: `Select <b>Convert to member</b> option.`,
        },
        {
          id: 8,
          title: `Click on the <b>Convert</b>.`,
          imageSource: `${GifBaseUrl}convert-guest-to-member.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Edit External Detail",
      steps: [
        {
          id: 1,
          title: `From the dashboard click on your workspace icon from the left.`,
        },
        {
          id: 2,
          title: `Select <b>Workspace Settings</b> option.`,
        },
        {
          id: 3,
          title: `Under Administration, click on <b>External Teams</b> option.`,
        },
        {
          id: 4,
          title: `Click on the <b>Individual</b> option from the top.`,
        },
        {
          id: 5,
          title: `Hover over the External name that you want to edit.`,
        },
        {
          id: 6,
          title: `Click on the <b>three vertical dots</b> option.`,
        },
        {
          id: 7,
          title: `Select <b>Edit</b> option and make necessary changes.`,
        },
        {
          id: 8,
          title: `Click on the <b>Update</b>.`,
          imageSource: `${GifBaseUrl}edit-guest-member.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Deactivate External",
      steps: [
        {
          id: 1,
          title: `From the dashboard, click on your Workspace icon from the left side corner.`,
        },
        {
          id: 2,
          title: `Click on <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under Administration, click on <b>External Teams</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>Individual</b> from the top.`,
        },
        {
          id: 5,
          title: `Navigate to the external that you want to deactivate details`,
        },
        {
          id: 6,
          title: `Click on the <b>three vertical dots</b>.`,
        },
        {
          id: 7,
          title: `Select on <b>Deactivate</b> option.`,
        },
        {
          id: 8,
          title: `Click on the <b>Deactivate</b>.`,
          imageSource: `${GifBaseUrl}deactivate-guest.gif`,
        },
      ],
    },
  ],
};
