import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IGCcontent = {
  title: "Invite External and Company to Project",
  link: "invite-external-and-company-to-project",
  content: [
    {
      id: 0,
      title: "Inviting Company to Project",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the  <img src=${taskicon} alt="task"  height="14" width:"14"/> icon .`,
        },
        {
          id: 2,
          title: "Click on the <b>Project Permissions</b> option from the top.",
        },
        {
          id: 3,
          title:
            "Click on the Invite <b>External Teams</b> button from the top right.",
        },

        {
          id: 4,
          title:
            "Here, enter the details of the company that you want to invite.",
        },

        {
          id: 5,
          title: "Then, click on <b>Invite</b>.",
          imageSource: `${GifeBaseUrl}inviting-compnay-to-project.gif`,
        },
      ],
    },

    {
      id: 1,
      title: "Inviting External to Project",
      onedescription:
        "You can also invite individual as external to project from here.",
      steps: [
        {
          id: 1,
          title: "Click on the <b>External Teams</b> from the top left.",
        },
        {
          id: 2,
          title: `Under Individual, click on the <img src=${plus}  alt="plus"  height="12px" width="14px" />  bottom from the right corner.`,
        },

        {
          id: 3,
          title:
            "Now enter all the necessary details and click on <b>Invite</b>.",
          imageSource: `${GifeBaseUrl}inviting-individual-to-project.gif`,
        },
      ],
    },
  ],
};
