import React from "react";
import MainHeader from "../../common/MainHeader";

export default function ReportBugHeader() {
  return (
    <div>
      <MainHeader
        heading="Report Bug"
        content="If you found anything wrong within this platform, Feel free to report it to us. We will continuously improve ourself."
      />
    </div>
  );
}
