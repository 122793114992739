export const buttonTags = [
  {
    id: 0,
    name: "Getting Started",
    path: "getting-started",
    // activeID: 0,
  },
  {
    id: 1,
    name: "Message and Calls",
    path: "messages-and-calls",
  },
  {
    id: 2,
    name: "Project Management",
    path: "project-management",
  },
  {
    id: 3,
    name: "Ticket Management",
    path: "ticket-management",
  },
  // {
  // 	id: 4,
  // 	name: "Calendar",
  // 	path: "calendar",
  // },
  {
    id: 5,
    name: "Automation",
    path: "automation",
  },
];
