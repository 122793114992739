import React, { useEffect, useState } from "react";
import {
	StartHeaderDiv,
	MainHeaderText,
	// PText,
	SearchDiv,
	DropDownDiv,
} from "../../styleComponents";
import { useNavigate } from "react-router-dom";

import searchIcon from "../../new/icons/search_icon.svg";
import { searchContent } from "../../../SearchContent";

export default function MainHeader({ heading, content }) {
	const navigate = useNavigate();
	const [searchText, setSearchText] = useState("");
	const [showDropdown, setShowDropdown] = useState(false);
	const [dropDownlist, setDropDownList] = useState([]);
	useEffect(() => {
		if (searchText) {
			setShowDropdown(true);

			const newDropDown = [];
			const newsearchText = searchText.toLowerCase();

			searchContent.map((item) => {
				if (item.title.toLowerCase().includes(newsearchText)) {
					newDropDown.push(item);
				}
			});
			setDropDownList(newDropDown);
		} else {
			setShowDropdown(false);
		}
	}, [searchText]);
	return (
		<StartHeaderDiv>
			<div className="header-content">
				<MainHeaderText smsize={true}>{heading}</MainHeaderText>

				<SearchDiv notFlex>
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<img src={searchIcon} alt="search icon" />
						<input
							placeholder="Find anything"
							className="input-search"
							value={searchText}
							onChange={(e) => setSearchText(e.target.value)}
							onKeyDown={(e) => {
								if (e.key === "Enter" && searchText.trim() !== "") {
									// navigate("/search", { state: searchText });
									setSearchText("");
								}
							}}
						/>
					</div>
					{showDropdown && (
						<DropDownDiv isAbsolute>
							<div className="inner-dropdown">
								{dropDownlist &&
									dropDownlist.length >= 1 &&
									dropDownlist.map((each) => {
										return (
											<h1
												onClick={() => {
													setShowDropdown(false);
													setSearchText("");
													navigate(`/${each.path}?tabid=${each.active}`);
												}}
											>
												{each.title}
											</h1>
										);
									})}

								{dropDownlist.length === 0 && (
									<p style={{ marginBottom: "0" }}>
										Sorry! We didn't find anything like "{searchText}"
									</p>
								)}
							</div>
						</DropDownDiv>
					)}
				</SearchDiv>
			</div>
		</StartHeaderDiv>
	);
}
