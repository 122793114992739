import plus from "./../../../../../new/icons/plus.png";
import taskicon from "./../../../../../new/icons/task.svg";

const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const PMPScontent = {
  title: "Project Members and Permission Settings",
  link: "project-members-and-permission-settings",
  content: [
    {
      id: 0,
      title: "Add members to Project",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on a <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: "Navigate to the project where you want to add members.",
        },
        {
          id: 3,
          title: `Click on the <b>Project Members & Teams</b> option from the top.`,
        },
        {
          id: 4,
          title: `Click on the <img src=${plus} alt="plus"  height="12" width:"12"/> at the bottom right.`,
        },
        {
          id: 5,
          title: "Now, type the member name that you want to add.",
        },
        {
          id: 6,
          title: `Set the access permission`,
        },
        {
          id: 7,
          title: `Then, hit the <b>Add</b> button`,
          imageSource: `${GifeBaseUrl}project-permission .gif`,
        },
      ],
      Note: [
        {
          title: `By following the aforementioned method, you can add multiple members to the project. Likewise, you can also include your workspace team in the project at once.`,
        },
      ],
    },

    {
      id: 1,
      title: "Change Project Member Permission",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/>  icon from the left.`,
        },
        {
          id: 2,
          title: `Click on the project where you want to add member`,
        },
        {
          id: 3,
          title: `Click on the <b>Project Members & Teams</b> option from the top`,
        },
        {
          id: 4,
          title: `Hover over the project member whose role you want to change and click on the three <b>horizontal</b> dots.`,
        },
        {
          id: 5,
          title: `Click on the <b>Edit</b>.`,
        },
        {
          id: 6,
          title: `Click on the drop-down menu and assign member permissions as <b>Editor, Viewer, </b>or <b>Admin</b>.`,
          imageSource: `${GifeBaseUrl}change-project-permission .gif`,
        },
      ],
    },

    {
      id: 2,
      title: "Remove Member from Project",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left.`,
        },
        {
          id: 2,
          title: `Hover over your preferred project and click on <b>three horizontal dots</b>.`,
        },
        {
          id: 3,
          title: `Click on the<b> Members and Permissions</b> option.`,
        },
        {
          id: 4,
          title: `Choose the member that you want to remove and click on<b> three horizontal dots</b>.`,
        },
        {
          id: 5,
          title: `Click on the <b>Remove</b> option.`,
        },
        {
          id: 6,
          title: `A new prompt will appear on the screen, here click on <b>Remove</b>.`,
          imageSource: `${GifeBaseUrl}remove-member-from-project.gif`,
        },
      ],
    },
  ],
};
