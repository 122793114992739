import Project from "../../../../../new/icons/project.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/new-flowcess/"

export const ABcontent = {
	title: " Introduction",
	link: "anydone-flowcess",
	content: [
		
		{
			id: 0,
			title: "Flowcess Intro",
			onetitle:`
<b>What is Flowcess?</b><br>
Flowcess is a next-gen automation that automates entire business operations at any scale for any industry type. With its user-friendly interface, no prior coding knowledge is needed to create, manage, and execute automation processes.
<br><br>
<b>What steps are required for Flowcess?</b> <br>
Since Flowcess is a no-code automation platform, you'll first need to setup following:<br><br>

•  <a href="anydone-flowcess?tabid=1"><b>Automation Tools</b></a><br>
•  <a href="anydone-flowcess?tabid=2"><b>App Directory</b></a><br>
•  <a href="anydone-flowcess?tabid=3"><b>Create Flowcess</b></a><br>
•  <a href="anydone-running-flowcess?tabid=0"><b>Run Flowcess</b></a><br><br>

`
		},
		{
			id: 1,
			title: "Automation Tool",
			onetitle: `Before creating a Flowcess, you need to create Automation Tools. These tools define your automation processes to manage your business operations.<br><br>
                        
                       <b>Types of Automation Tools:</b><br><br>
                        
                        <b>AI</b><br><br>
                        
                        <b>•<a href="anydone-creation?tabid=0"> Entity:</a></b> Our AI-driven Entity automatically detects and identifies critical pieces of information, such as names, dates, emails, product details, and more, within your data. It not only categorizes these entities but also makes it easy for you to retrieve.<br><br>
                        
                        <b>•<a href="anydone-creation?tabid=1"> Intent collection:</a></b> Our Intent Collection analyzes user queries and determines what they want, whether it’s a customer asking for support or an employee seeking information. It helps by delivering accurate responses and actions, leading to more relevant and context-aware interactions.<br><br>
                        
                        <b>•<a href="anydone-creation?tabid=2"> Knowledge base:</a></b> With Knowledge Base create, and manage a repository of information. This includes FAQs, troubleshooting guides, product documentation, and more. It ensures that the most relevant and up-to-date information is available, reducing the burden on human support staff and improving the efficiency of customer service operations.<br><br>
                        
                        <b>•<a href="anydone-creation?tabid=3"> Knowledge graph:</a></b> With our Knowledge Graph, you can create a network of interconnected data and map relationships between customer preferences and product attributes. This enables personalized user experiences by delivering insights and recommendations based on a comprehensive data view.<br><br>
                        
                        <b>•<a href="anydone-creation?tabid=4"> Automated replies:</a></b> You can leverage the power of AI for your Automated Replies to handle routine inquiries. By generating instant, contextual responses, our automated replies enhance customer engagement and streamline internal communication, and ensure that responses are timely, accurate, and aligned with your business objectives.<br><br>
                        
                        <b>•<a href="anydone-creation?tabid=5"> Third-party model:</a></b> Seamlessly integrate third-party AI models and easily connect your preferred algorithms, predictive analytics, and external tools with our Flowcess. This integration allows your business to adapt and scale effortlessly.<br><br>
                        
                        `
                      
			
		},
		{
			id: 2,
			title: "App Directory",
			onetitle: `
                    The App Directory allows you to create apps where your created Flowcess will be triggered and run. Creating an app is simple, and there are different types you can choose from:<br><br>

<b>•<a href="anydone-app?tabid=0"> Anydone:</a></b> Create an Anydone app to streamline project management, collaboration, and seamlessly manage tasks or automate various operations. This makes it easy to achieve goals.<br><br>

<b>•<a href="anydone-app?tabid=1"> Chat Plugin:</a></b> Make smart and powerful chat plugins to embed real-time communication into your website or application. This enables direct interaction with customers or team members through live chat, providing immediate support and enhancing user engagement.<br><br>

<b>• <a href="anydone-app?tabid=2"> Live Capture:</a></b> Create a Live Capture app easily to collect and process real-time data. It captures live information for immediate analysis and action, helping businesses to enhance decision-making.<br><br>

<b>•<a href="anydone-app?tabid=3"> Webhook:</a></b> Easily create Webhook apps to receive instant updates and notifications from external applications. This feature enables automated responses and data synchronization, ensuring your system stays up-to-date with real-time information from connected services.<br><br>

<b>•<a href="anydone-app?tabid=4"> Mail:</a></b> Handle all your email communications directly within your workflows by creating Mail apps. This includes sending, receiving, and managing emails, allowing for seamless integration with your processes.<br><br>

<b>• <a href="anydone-app?tabid=5"> Slack:</a></b> By creating this app, you can send messages, receive updates, and automate interactions within your Slack channels, integrating these communications smoothly into your workflow.

                    `,
			
		},
		{
			id: 3,
			title: "Create Flowcess",
			onetitle:`After creating <a>Automation tools</a> like <a>AI</a> and <a>Process</a>, then create an <a>App Directory</a> then you can start creating the Flowcess.<br> <br>

Click on the + button from the right hand side. Here, you’ll see the Infinite Flowcess Canvas, where you can add blocks and connect them. <br><br>

This visual approach lets you map out complex automation processes with clarity, allowing you to easily adjust connections and refine the process, making it simple to create and manage.<br><br>
`,
imageSource: `${imageUrl}creation-of-flowcess.gif`


			// steps: [
			// 	{
			// 		id: 1,
			// 		title: `Login to your anydone`
			// 	},
			// 	{
			// 		id: 2,
			// 		title: `Click on the <b><img src=${Project} alt="project" height="12" width="12"/></b> icon`
			// 	},
			// 	{
			// 		id: 3,
			// 		title: `You can create a new project or click on the already created project`
			// 	},
			// 	{
			// 		id: 4,
			// 		title: `Click on the <b>+</b> button right to your project name`
			// 	},
			// 	{
			// 		id: 5,
			// 		title: ` Click on the <b>Flowcess</b> folder`
			// 	},
			// 	{
			// 		id: 6,
			// 		title: ` Give it a <b>name</b>`
			// 	},
			// 	{
			// 		id: 7,
			// 		title: `Click on <b>Create</b> button`
			// 	},	



				
			// ],
			// add_title:"Once the  the Flowcess folder is created now you need to create flowcess, to create one follow the steps:",
			// add_steps:[
			// 	{
			// 		id: 1,
			// 		title: `Click on the <b>+</b> button from the right corner`
			// 	},
			// 	{
			// 		id: 2,
			// 		title: ` Here, add image, title and description`
			// 	},
			// 	{
			// 		id: 3,
			// 		title: ` The, hit <b>"Create"</b> button<br><br>
			// 		Now you <a>start building flowcess </a>by adding blocks`
			// 	},
			// ],
			
		},
		
		
	],
};

