import Project from "../../../../../new/icons/project.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
const videoUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/new-flowcess/"
const url="https://storage.googleapis.com/anydone-static-files/assets/"
export const ABcontent = {
	title: " Flowcess",
	link: "anydone-create-flowcess",
	content: [
		
		{
			id: 0,
			title: " Knowledge Base Flowcess",
			onetitle:`Before you run Flowcess, make sure you have <a href="anydone-creation?tabid=2">created a knowledge base</a>. Once you've created it, follow the steps below: <br>`,
			imageSource: `${videoUrl}flowcess-knowledge-base-creation.gif`,
			title2:`Once you’re done, it’s time to <a href="anydone-create-flowcess?tabid=6">Run the Flowcess</a>.`


			
		},
		{
			id: 1,
			title: " Knowledge Graph FLowcess",
			onetitle:`Before you run Flowcess, make sure you have <a href="anydone-creation?tabid=3">created a knowledge graph</a>. Once you've created it, follow the steps below: <br>`,
				imageSource: `${videoUrl}flowcess-knowledge-graph-creation.gif`,
			title2:`Once you’re done, it’s time to <a href="anydone-create-flowcess?tabid=6">Run the Flowcess</a>.`
		},
		{
			id: 2,
			title: "Intent Collection Flowcess",
			onetitle:`Before you run Flowcess, make sure you have <a href="anydone-creation?tabid=1">created an intent collection</a>. Once you've created it, follow the steps below: <br>`,
			imageSource: `${videoUrl}flowcess-intent-collection-creation.gif`,
			title2:`Once you’re done, it’s time to <a href="anydone-create-flowcess?tabid=6">Run the Flowcess</a>.`

			
		},
		{
			id: 3,
			title: " Automated Reply Flowcess",
			onetitle:`Once you create automated replies using the <a href="anydone-creation?tabid=2">Knowledge Base</a>, <a href="anydone-creation?tabid=3">Knowledge Graph</a>, <a href="anydone-creation?tabid=5">third-party models (text generation)</a>, and <a href="anydone-creation?tabid=1">Intent Collection</a>.<br><br>You can create Flowcess with our Default Automated Reply or you can also use a model based on your preference.<br> <br>Here we’ve created Flowcess using the Default Automated Reply block.<br><br> `,
			imageSource: `${videoUrl}flowcess-automated-reply-creation.gif`,
			title2:`Once you’re done, it’s time to <a href="anydone-create-flowcess?tabid=6">Run the Flowcess</a>.`

			

			
		},
		{
			id: 4,
			title: "Text Generation Flowcess",
			onetitle:`Before starting to create the text generation  Flowcess, make sure you've created the <a href="anydone-creation?tabid=5">text generation</a> using a third-party model. <br>`,
			imageSource: `${imageUrl}flowcess-creation-text-generation.gif`,
			title2:`Once you’re done, it’s time to <a href="anydone-create-flowcess?tabid=6">Run the Flowcess</a>.`

			
		},
	
		
		
		
		{
			id: 5,
			title: " Text To Speech Flowcess",
			onetitle:`Before starting to create the text-to-speech Flowcess, make sure you've created the <a href="anydone-creation-process?tabid=1">text-to-speech process</a> using a <a href="anydone-creation?tabid=5">third-party model</a>. <br>`,
			imageSource: `${url}flowcess-text-to-speech-creation.gif`,
			title2:`Once you’re done, it’s time to <a href="anydone-create-flowcess?tabid=6">Run the Flowcess</a>.`,
			Note:[
				{
					id:1,
					title:`Note: Here we’ve selected Anydone Model and Knowledge Base, however you can choose your preferred model and tools.`
				}
			]
			
			
		},
	
				{
					id: 6,
					title: "Run Flowcess",
		
					steps: [
						{
							id: 1,
							title: `Log in to the anydone dashboard and click on the <b><img src=${Project} alt="project" height="12" width="12"/></b> icon.`,
						},
						{
							id: 1,
							title: `Navigate to your <b>project</b> and click on it.`,
						},
						{
							id: 1,
							title: `Click on the <b>Flowcess folder</b> and select a Flowcess that you want to run.`,
						},
						{
							id: 1,
							title: `Here, you will see all your created Flowcess.<br>`,
							imageSource: `${imageUrl}run-flowcess.png`
						},
						
					],
					title2:`If the Flowcess is unpublished, the <b>Publish</b> button will be green and remember, to run the Flowcess, you need to publish it first, so click the <b>Publish</b> button.<br><br>
		
					Once you publish the Flowcess, then, click on <b>Run</b> button, here, you’ll see three options: <b>Try Now, Time Based,</b> and, <b>Event Based.</b><br><br>`,
					imageSource2: `${imageUrl}run-flowcess-now-option.png`,
		
		
		
		
					add_title:"<b><u>Run Flowcess Time Based</u></b><br><br>",
					text1content:"<b>if selected “Now”</b>",
					add_steps:[
						{
							id:1,
							title:`Hover over <b>Time Based</b> and select the <b>'Now'</b> option.`
						},
						{
							id:2,
							title:`Enter a name for the instance.`
						},
						{
							id:3,
							title:`In the <b>Value</b> placeholder, add the probable prompt or possible query that your customer or user might enter.`
						},
						{
							id:4,
							title:`Then, click on the <b>Run</b> button <br>`,
							imageSource: `${imageUrl}time-based-now.gif`
		
		
						},
					],
					one_more_title:"<b>Now, to view the output follow these steps:</b>",
					one_more_steps:[
						{
							id:1,
							title:`Click on the <b>Running Instance.</b>`
						},
						{
							id:2,
							title:`Wait until the instance running gets complete.`
						},
						{
							id:3,
							title:`Then, click on the drop-down menu from the top right corner.`
						},
						{
							id:4,
							title:`From the options, select <b>Completed.</b>`
						},
						{
							id:5,
							title:`Now, click on <b>Sessions.</b>`
						},
						{
							id:6,
							title:`Click on the vertical three dots and select <b>View Output.</b>`
						},
						{
							id:7,
							title:`Here, you can see the output of your Flowcess`,
							imageSource: `${imageUrl}time-based-now-viewoutput.gif`
		
						},
					],
					more_title:`<b>If selected "Schedule"</b>`,
					more_steps:[
						{
							id:1,
							title:`Hover over <b>Time Based</b> and select the <b>Schedule</b> option.`
						},
						{
							id:1,
							title:`Enter a name for the instance.`
						},
						{
							id:1,
							title:`In the <b>Value</b> placeholder, add the probable prompt or possible query that your customer or user might enter.`
						},
					
						{
							id:1,
							title:`Then, click on the <b>Run</b> button. `
						},
					],
					last_title:"<b><u>Run Flowcess Event Based</u></b>",
					lasttextcontent:`<br>As we’ve already mentioned above, before running the Flowcess you need to decide where you want the Flowcess to run. Then, you need to <a href="anydone-app?tabid=0">create an app</a> based on your preferences. Once you are done, publish the Flowcess and follow the below steps.`,
					last_steps:[
						{
							id:1,
							title:`Click on the <b>Run</b> button.`
						},
						{
							id:2,
							title:`Select<b> Event Based</b> option.`,
							imageSource: `${imageUrl}run-flowcess-anydone.png`
		
						},
					],
					lastt_title:"<b><u>If selected Anydone</u></b>",
					lastt_steps:[
						{
							id:1,
							title:`Under Anydone, select the Anydone Cospace where you want to trigger the Flowcess.`
						},
						{
							id:2,
							title:`Finally, click the <b>Run</b> button.`,
							imageSource: `${imageUrl}run-anydone-flowcess-first.gif`
		
						},
					],
					final_title:"<b><u>If selected Chat Plugin</u></b>",
					final_steps:[
						{
							id:1,
							title:`Click on the <b>Run</b> button.`
						},
						{
							id:2,
							title:`Select <b>Event Based</b> option.`
						},
						{
							id:3,
							title:`Under the Chat <b>Plugin</b>, select the chat plugin you created to trigger the Flowcess.`
						},
						{
							id:4,
							title:`Finally, click the <b>Run</b> button.`,
							imageSource: `${imageUrl}run-chatplugin-flowcess.gif`,
							final_text2:`
							After clicking the <b>Run</b> button, a new prompt will appear. Here, you can copy the chat plugin <b>URL</b>, paste it into your browser tab, and interact with the chat plugin. You can also share this URL. <br><br>
		Additionally, you can copy the <b>Embed Code</b> and paste it into your website's HTML file to integrate the chat plugin directly into your site.`,
				imageSourceFinal:`${imageUrl}run-flowcess-chatplugins.png`,
		
						}
					],
		},,

				
		
		

	],
};

