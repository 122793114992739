import React from "react";
import { Col } from "antd";
import { ButtonTags } from "../../common/ButtonTags";

import {
	EachBoxListingWrapper,
	FlexDiv,
	SecStartedContent,
} from "../../../styleComponents";
import { Helmet } from "react-helmet";

import MainHeader from "../../common/MainHeader";

import { contents } from "./content";
import CardListBox from "../../common/CardListBox";
import { Waypoint } from "react-waypoint";
const MessagesAndCalls = ({ handleWaypointEnter, handleWaypointLeave }) => {
	return (
		<div>
			<Helmet>
				<title>Messages And Calls</title>
				<meta name="description" content="" />
			</Helmet>
			<MainHeader heading="Messages And Calls" content="" />
			<Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

			<SecStartedContent>
				<ButtonTags selectedID={1} />
				<FlexDiv>
					<Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 20 }}>
						<EachBoxListingWrapper>
							{contents.map((each) => {
								return <CardListBox each={each} />;
							})}
						</EachBoxListingWrapper>
					</Col>
				</FlexDiv>
			</SecStartedContent>
		</div>
	);
};

export default MessagesAndCalls;
