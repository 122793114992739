import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CAMTcontent = {
  title: "Ticket Dashboard",
  link: "ticket-dashboard",
  content: [
    {
      id: 0,
      title: "Your Work",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon`,
        },
        {
          id: 2,
          title: "Click on <b>Your Work</b> from the left corner.",
        },
        {
          id: 3,
          title:
            "Here, view all of your created tickets, see its status, make changes and more.",
          imageSource: `${GifeBaseUrl}your-work.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Project Dashboard",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title: `Click on the <b>Project Dashboard</b> from the left corner`,
        },
        {
          id: 3,
          title:
            "Here view all the projects created in your workspace, its created date, status, progress and more.",
          imageSource: `${GifeBaseUrl}project-dashboard.gif`,
        },
      ],
    },

    // {
    //   id: 2,
    //   title: "Viewing all Tickets",
    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
    //     },
    //     {
    //       id: 2,
    //       title: `Click on the <b>Ticket Dashboard</b> from the bottom right corner`,
    //     },
    //     {
    //       id: 3,
    //       title: "Click on the <b>All Tickets</b> from the top.",
    //     },
    //     {
    //       id: 4,
    //       title: "Here you can make changes to the tickets.",
    //     },
    //     {
    //       id: 5,
    //       title: "Also, click on + icon to create new ticket.",
    //       imageSource: `${GifeBaseUrl}all-tickets.gif`,
    //     },
    //   ],
    // },

    // {
    //   id: 5,
    //   title: "Sharing ticket",
    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> project icon.`,
    //     },
    //     {
    //       id: 2,
    //       title: `Navigate to the project and click on the ticket folder whose ticket you want to share.`,
    //     },
    //     {
    //       id: 3,
    //       title:
    //         "Click on the <b>Task, Story, Bug,</b> or <b>Epic</b> ticket category from where you want to share the ticket.",
    //     },
    //     {
    //       id: 4,
    //       title:
    //         "Now hover over the ticket that you want to share and click on three dots.",
    //     },
    //     {
    //       id: 5,
    //       title: "Click on the <b>Share</b> option.",
    //     },

    //     {
    //       id: 6,
    //       title:
    //         "New prompt will pop, here copy the link and share it with your peers",
    //       imageSource: `${GifeBaseUrl}tickets-move.gif`,
    //     },
    //   ],
    // },
  ],
};
