import faqs from "../../../new/homepage/faqs.svg";
import bug from "../../../new/homepage/bug.svg";
import support from "../../../new/homepage/support.svg";
import tutorials from "../../../new/homepage/tutorials.svg";
import MessageandCalls from "../../../new/homepage/messages_calls.svg";
import taskMgmt from "../../../new/homepage/task_management.svg";
import gettingStarted from "../../../new/homepage/getting_started.svg";
import projectManagement from "../../../new/homepage/project_management.svg";

import Meetings from "../../../new/homepage/meetings.svg";
import automation from "../../../new/homepage/automation.svg";
import calendar from "../../../new/homepage/calendar.svg";

// CardDetails json
export const MainpageCardDetails = [
  {
    id: 0,
    image: gettingStarted,
    title: "Getting Started",
    path: "getting-started",
  },
  {
    id: 1,
    image: MessageandCalls,
    title: "Message and Calls",
    path: "messages-and-calls",
  },
  {
    id: 2,
    image: projectManagement,
    title: "Project Management",
    path: "project-management",
  },
  {
    id: 3,
    image: taskMgmt,
    title: "Ticket Management",
    path: "ticket-management",
  },
  // {
  // 	id: 4,
  // 	image: calendar,
  // 	title: "Calendar",
  // 	path: "calendar",
  // },
  {
    id: 5,
    image: automation,
    title: "Automation",
    path: "automation",
  },
];

// BrowseHelp Json

export const MainpgeBrowseHelp = [
  {
    id: 1,
    image: support,
    title: "Support",
    desc: ["Have problem ? Get support with anydone's expert."],
    external: true,
    elink: `https://anydone.com/contact.html`,
  },
  {
    id: 2,
    image: faqs,
    title: "FAQs",
    desc: ["Got a question? We’ re here to answer them."],
    link: "faq/anydone-business-management-platform",
  },

  {
    id: 3,
    image: bug, 
    title: "Report Bug",
    desc: ["Let us know what went wrong, while using anydone."],
    link: "report-bug",
  },
  {
    id: 4,
    image: tutorials,
    title: "Watch Tutorials",
    desc: ["Watch and learn to use anydone"],
    external: true,
    elink: `https://www.youtube.com/@anydone`,
  },
];

export const whatsnewContent = [
  {
    id: 1,
    title: "Collab with the team  ",
    link: "/messages-and-calls/collab",
    active: "0",
  },
  {
    id: 2,
    title: "Refining text ",
    link: "/messages-and-calls/message-edit",
    active: "0",
  },
  {
    id: 3,
    title: "Inviting Externals ",
    link: "/getting-started/inviting-and-managing-externals",
    active: "0",
  },
  {
    id: 4,
    title: "Organise with Subjects",
    link: "/messages-and-calls/creating-group",
    active: "1",
  },
  {
    id: 5,
    title: "Write on Paper ",
    link: "/project-management/papers",
    active: "0",
  },
  {
    id: 6,
    title: "Schedule message",
    link: "/messages-and-calls/manage-messages",
    active: "2",
  },
];
