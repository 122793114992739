import plus from "./../../../../../new/icons/plus.png";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MScontent = {
  title: "Message Edit",
  link: "message-edit",
  content: [
    {
      id: 0,
      title: "Refining text With generative AI",

      steps: [
        {
          id: 1,
          title: `Login to your anydone dashboard.`,
        },
        {
          id: 2,
          title: `Go to any message thread and paste your content into Text Editor.`,
          imageSource: `${GifBaseUrl}refine-text.gif`,
        },
      ],
      isthisZipyandBots: true,
    },
    {
      id: 1,
      title: "Emoji and Reactions",
      onetitle: "Emoji",
      steps: [
        {
          id: 1,
          title: ` Compose a message for an individual, multiple participants, or a group.`,
        },
        {
          id: 2,
          title: `Select the <b>Emoji</b> option from the text editor.`,
        },
        {
          id: 3,
          title: `Then, various emojis will appear on your screen`,
        },
        {
          id: 4,
          title: "Now, select the emojis to add to your messages",
          imageSource: `${GifBaseUrl}emoji-and-reaction.gif`,
        },
      ],
      // add_title: "DELETE",
      // add_steps: [
      // 	{
      // 		id: 1,
      // 		title: ` Hover over the message you’d like to delete.`,
      // 	},
      // 	{
      // 		id: 2,
      // 		title: `Click on the button indicated by 3 vertically aligned dots.`,
      // 	},
      // 	{
      // 		id: 3,
      // 		title: "Navigate to the Delete option.",
      // 		imageSource: "",
      // 	},
      // 	{
      // 		id: 4,
      // 		title: ` A prompt will appear to confirm if you want to <b>Delete</b> or <b>Cancel</b>`,
      // 	},
      // 	{
      // 		id: 5,
      // 		title: `Click on the <b>Delete</b> button`,
      // 	},
      // ],
    },
    {
      id: 2,
      title: "Create New Post",

      steps: [
        {
          id: 1,
          title: `Go to the text editor section.`,
        },
        {
          id: 2,
          title: `From the text editor section, click on the <img src=${plus} alt="iicon" height="12" width="12"/> button.`,
        },
        {
          id: 3,
          title: `Click on the <b>Create New Post</b> option that appears.`,
        },
        {
          id: 4,
          title: `A different segment will appear where you need to select the file type like <b>Text, HTML, XML,</b> and <b>JSON</b>.`,
        },
        {
          id: 5,
          title: `Now give a name to your file.`,
        },
        {
          id: 6,
          title: `Add your content, then click <b>Create</b> option`,
          imageSource: `${GifBaseUrl}create-a-post.gif`,
        },
      ],
    },

    {
      id: 3,
      title: "Code Editor",
      steps: [
        {
          id: 1,
          title: `Compose a message for an individual, multiple participants, or a group.`,
        },
        {
          id: 2,
          title: `Select the <b>Code Editor</b> option from the text editor.`,
        },
        {
          id: 3,
          title: `Start writing or pasting copied code and <b>Edit</b> them.`,
        },
        {
          id: 4,
          title: `Click on the <b>Send</b> button indicated by a paper plane icon or press <b>Enter</b>.`,
          imageSource: `${GifBaseUrl}code-editor.gif`,
        },
      ],
    },
  ],
};
