import { Modal } from "antd";
import styled from "styled-components";
export const StyledModal = styled(Modal)`
    .ant-modal-content {
        background-color: #ffffff !important;
    }
    .ant-modal-footer {
        border: none !important;
    }
`;
export const ModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    p {
        font-size: 14px;
        letter-spacing: 1px;
    }
`;
