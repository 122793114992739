export const contents = [
	{
		id: 1,
		title: "Creating Meetings",
		link: "creating-meetings",
		listing: [
			{
				id: "0",
				title: "General Meetings",
			},
			{
				id: "1",
				title: "Instant Meetings",
			},
			{
				id: "2",
				title: "Time Slots",
			},
		],
	},
	// {
	// 	id: 2,
	// 	title: "Whiteboard",
	// 	link: "whiteboard",
	// 	listing: [
	// 		{
	// 			id: "0",
	// 			title: "Scope of Whiteboard",
	// 		},
	// 	],
	// },
];
