import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Checkcontent = {
  title: "Checklist",
  link: "checklist",
  content: [
    {
      id: 0,
      title: "Generate Checklist",
      onedescription:
        "You can generate a checklist in a ticket. The ticket can be one that has already been created, or you can generate a checklist while creating a new ticket. Here, we'll be generating a checklist for a new ticket.",
      steps: [
        {
          id: 1,
          title: `Login to you dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 3,
          title: "Navigate to the project and its ticket folder.",
        },
        {
          id: 4,
          title: `Click on the <img src=${plus} alt="task"  height="14" width:"14"/> button from the right side corner.`,
        },
        {
          id: 5,
          title: "Enter the necessary details for the ticket.",
        },

        {
          id: 6,
          title: "Click on the <b>Generate Checklist</b>.",
          imageSource: `${GifeBaseUrl}generate-checklist.gif`,
        },
      ],
      Note: [
        {
          title:
            "From the generated checklist, you can select only the items that you need. Also, once created, the ticket assignee can use a progress bar to show how much work is completed in the checklist. And, remember, a checklist will be generated based on the ticket “Summary” and “Description”.",
        },
      ],
      // text1imageSource: `${GifeBaseUrl}generate-checklist.gif`,
    },
  ],
};
