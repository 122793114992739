import React from "react";
import useResponsive from "./useResponsive";
import {
	CloseOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from "@ant-design/icons";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function ModalPage({ shown, close, image }) {
	const width = useResponsive();
	const breakPoint = 768;

	return (
		shown &&
		(width > breakPoint ? (
			<TransformWrapper initialScale={1}>
				{({ zoomIn, zoomOut }) => (
					<>
						<div className="modal-header">
							{width > breakPoint && (
								<>
									<button className="zoom-in" onClick={() => zoomIn()}>
										<ZoomInOutlined />
									</button>
									<button className="zoom-out" onClick={() => zoomOut()}>
										<ZoomOutOutlined />
									</button>
								</>
							)}
							<button className="close" onClick={close}>
								<CloseOutlined />
							</button>
						</div>
						<div className="modal" onClick={close}>
							<div
								className="modal-div"
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<TransformComponent>
									<img className="modal-content" src={image} alt="Not found" />
								</TransformComponent>
							</div>
						</div>
					</>
				)}
			</TransformWrapper>
		) : (
			<>
				<div className="modal-header">
					<button className="close" onClick={close}>
						<CloseOutlined />
					</button>
				</div>
				<div className="modal" onClick={close}>
					<img
						className="modal-content"
						src={image}
						alt="Not found"
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
				</div>
			</>
		))
	);
}
