import plus from "./../../../../../new/icons/plus.png";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CAOPcontent = {
  title: "Creating and Organizing Project",
  link: "creating-and-organizing-project",
  content: [
    {
      id: 0,
      title: "Creating a Project",

      onedescription:
        "To create a project, one needs to be authorized to do so, or you need to be an Owner or Admin.",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on a <img src=${taskicon} alt="task"  height="14" width:"14"/>  icon from the left side.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${plus} alt="task"  height="12" width:"12"/> button next to Projects  .`,
          // imageSource: `${ImageBaseUrl}Screenshot (53).png`,
        },
        {
          id: 3,
          title: ` New prompt will open on your screen; here, fill up all the required information like Project id, Name, category, etc.`,
          // imageSource: `${ImageBaseUrl}Screenshot (53).png`,
        },
        {
          id: 4,
          title: `Specific project permission`,
          // imageSource: `${ImageBaseUrl}creating-managing-project-anydone.png`,
        },
        {
          id: 5,
          title: `Hit the <b>Create</b> button`,
          imageSource: `${GifBaseUrl}new-project.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "When creating a new project, if you do not create or provide any details in the Task Folder, the system will automatically create a default task folder for your project.",
        },
      ],
    },
    {
      id: 1,
      title: "Archiving Project ",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on a  <img src=${taskicon} alt="task"  height="14" width:"14"/>  icon from the left side.`,
        },
        {
          id: 2,
          title: "Hover over the project that you want to archive.",
        },
        {
          id: 3,
          title: "Click on the three horizontal lines.",
        },
        {
          id: 4,
          title: "Click on the <b>Archive</b> option.",
        },
        {
          id: 5,
          title:
            "A confirmation message will pop up on your screen, then click on the <b>Yes</b> button.",
          imageSource: `${GifBaseUrl}archive-project.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "View Archived Projects",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on a  <img src=${taskicon} alt="task"  height="14" width:"14"/>  icon from the left side.`,
        },
        {
          id: 2,
          title: `Click on the Projects drop-down option and select Settings option.`,
        },
        {
          id: 3,
          title: " Here you'll see all of your archived projects.",
          imageSource: `${GifBaseUrl}viewing-archived-projects.gif`,
        },
      ],
    },
    // {
    //   id: 3,
    //   title: "Restoring Archived Project ",

    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard and click on a <img src=${taskicon} alt="task"  height="14" width:"14"/>  icon from the left side.`,
    //     },
    //     {
    //       id: 2,
    //       title:
    //         "Click on the Projects drop-down option and select <b>Settings</b> option",
    //     },
    //     {
    //       id: 3,
    //       title: "Here you'll see all of your archived projects",
    //     },
    //     {
    //       id: 4,
    //       title: "Here, select the project that you want to Restore",
    //     },
    //     {
    //       id: 5,
    //       title: "Click on the <b>three horizontal dots</b>",
    //     },
    //     {
    //       id: 6,
    //       title: ` Click on the <b>Restore</b> option`,
    //     },
    //     {
    //       id: 7,
    //       title: `A confirmation message will pop up on your screen; click on <b>Yes</b> button `,
    //     },
    //   ],
    // },
  ],
};
