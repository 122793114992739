import React, { useEffect, useState } from "react";

import { Col } from "antd";
import {
  ContentDiv,
  NoteDiv,
  H3Text,
  PText,
  StepContent,
} from "../../../../styleComponents";
import ModalPage from "../../../common/ModalPage";

import ReactHtmlParser from "react-html-parser";
import { CAMTFcontent } from "./CreatingAndManagingTaskFolder/content";
function LayoutPage(props) {
  //   console.log(props.content);
  const data = props.content ? props.content : CAMTFcontent.content[0];
  const [showImage, setShowImage] = useState("");
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setShowImage("");
      }
    };
    document.addEventListener("keydown", handleKeyPress);
  });

  return (
    <>
      <Col
        xs={{ span: 24 }}
        md={{ span: 10 }}
        lg={{ span: 14 }}
        className="second-column"
      >
        <ContentDiv>
          <H3Text mb="1rem">{data.title}</H3Text>
          {data.onetitle && <PText>{data.onetitle}</PText>}
          {data.onedescription && <PText>{data.onedescription}</PText>}

          {data.steps && (
            <StepContent>
              {data.steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          <ModalPage
            shown={showImage}
            close={() => {
              setShowImage("");
            }}
            image={showImage}
          />
          {data.Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
          {data.text1content && (
            <PText>{ReactHtmlParser(data.text1content)}</PText>
          )}
          {data.text1imageSource && (
            <img
              src={data.text1imageSource}
              alt="not-loaded"
              width="100%"
              style={{
                marginBottom: "2rem",
                marginTop: "2rem",

                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
              }}
              onClick={() => {
                setShowImage(data.text1imageSource);
              }}
            />
          )}
        </ContentDiv>
        {data.add_title && (
          <PText>
            <b>{data.add_title}</b>
          </PText>
        )}

        <ContentDiv>
          {data.add_steps && (
            <StepContent>
              {data.add_steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.text2content && <PText>{data.contentplus}</PText>}
          {data.Add_Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.Add_Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
        </ContentDiv>
        {data.one_more_title && <PText>{data.one_more_title}</PText>}

        <ContentDiv>
          {data.one_more_steps && (
            <StepContent>
              {data.one_more_steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.text3content && <PText>{data.text3content}</PText>}
          {data.one_More_Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.one_More_Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
        </ContentDiv>
        <ContentDiv>
          {data.isthisPermissonSetting && (
            <div>
              <PText style={{ textDecoration: "underline" }}>
                After clicking on the Ticket option, you can change the
                following settings:
              </PText>

              <div>
                <H3Text>
                  <b>Who can Create a project?</b>
                </H3Text>
                <PText>
                  Here, you can set who can create projects within your
                  workspace. You can choose between <b>Owner</b>, <b>Admin</b>,
                  and <b>Everyone except guests</b>.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Who can create a ticket folder?</b>
                </H3Text>
                <PText>
                  You can specify who can create a ticket folder. You can select
                  from the Owner, Admin, and Everyone except guests.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Who can create board?</b>
                </H3Text>
                <PText>
                  Specify who can create the board. You have the choice between
                  the Owner, Admin, and Everyone except guests.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Who can archive project?</b>
                </H3Text>
                <PText>
                  You can specify who can archive projects. You can select from
                  Owner, Admin, and Everyone except guest.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Project Visibility</b>
                </H3Text>
                <PText>
                  Here, you can set who can view and edit projects within your
                  workspace.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Auto Assign Ticket</b>
                </H3Text>
                <PText>
                  Here, our AI-powered system will automatically assign the
                  tickets to the members of the project who have no task pending
                  and is free.
                </PText>
              </div>
            </div>
          )}
        </ContentDiv>
        <ContentDiv>
          {data.isEditProjectPermisson && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div>
                <H3Text>
                  <b>Who can Create a project?</b>
                </H3Text>
                <PText>
                  Here, you can set who can create projects within your
                  workspace. You can choose between <b>Owner</b>, <b>Admin</b>,
                  and <b>Everyone except guests</b>.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Who can create a ticket folder?</b>
                </H3Text>
                <PText>
                  You can specify who can create a ticket folder. You can select
                  from the Owner, Admin, and Everyone except guests.
                </PText>
              </div>

              <div>
                <H3Text>
                  <b>Who can archive the project?</b>
                </H3Text>
                <PText>
                  You can specify who can archive projects. You can select from
                  Owner, Admin, and Everyone except guest.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Project Visibility</b>
                </H3Text>
                <PText>
                  Here, you can set who can view and edit projects within your
                  workspace.
                </PText>
              </div>
              <div>
                <H3Text>
                  <b>Auto Assign Ticket</b>
                </H3Text>
                <PText>
                  Here, our AI-powered system will automatically assign the
                  tickets to the members of the project who have no task pending
                  and is free.
                </PText>
              </div>
            </div>
          )}
        </ContentDiv>
        {data.isEditProjectPermisson && (
          <PText>
            <b>
              <i>
                Remember, after making all the changes, don’t forget to hit{" "}
                <b>Save</b> option
              </i>
            </b>
          </PText>
        )}
      </Col>
      <Col
        xs={{ span: 0 }}
        md={{ span: 5 }}
        lg={{ span: 4 }}
        className="third-column"
      ></Col>
    </>
  );
}

export default LayoutPage;
