import plus from "./../../../../../new/icons/plus.png";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CAMTFcontent = {
  title: "Creating and Managing Ticket Folder",
  link: "creating-and-managing-task-folder",
  content: [
    {
      id: 0,
      title: "Creating New Ticket Folder",
      steps: [
        {
          id: 1,
          title: `Login to your  dashboard. `,
        },
        {
          id: 2,
          title: `Click on <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 3,
          title: `Navigate to the project where you want to create ticket folder.`,
        },
        {
          id: 4,
          title: `Click on the <img src=${plus} alt="plus"  height="14" width:"14"/> button next to your project.`,
        },
        {
          id: 5,
          title: "Click on <b>Ticket</b> folder.",
        },
        {
          id: 6,
          title: "Add necessary details.",
        },
        {
          id: 6,
          title: "Lastly, hit Create button.",
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "A Ticket folder (named 'Default folder') is created by default once you create a new project.",
        },
      ],
      text1imageSource: `${GifeBaseUrl}ticket-folder-new.gif`,
    },
    {
      id: 1,
      title: "Edit Ticket Folder",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on a  <img src=${taskicon} alt="task"  height="14" width:"14"/>  icon.`,
        },
        {
          id: 2,
          title:
            "Hover over the ticket folder that you want to edit and click on the <b>three dots</b>.",
        },
        {
          id: 3,
          title: "New prompt will pop up, here select  the <b>Edit</b> option.",
        },
        {
          id: 4,
          title: " Make the necessary changes.",
        },
        {
          id: 5,
          title: "Then, hit the <b>Update</b> button",
          imageSource: `${GifeBaseUrl}ticket-folder-edit..gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Pin ticket folder to DMs, CoSpace, or Members",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on a  <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Hover over the ticket folder that you want to pin and click on the three dots.",
        },
        {
          id: 3,
          title:
            "New prompt will pop up, here select  the Pin to message option.",
        },
        {
          id: 4,
          title:
            "Select <b>DMs, CoSpace, or Message</b> to pin the ticket folder",
        },
        {
          id: 5,
          title: "Then, hit the <b>Add</b> button",
          imageSource: `${GifeBaseUrl}pin-ticket-folder.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Archiving ticket folder",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on a <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Hover over the ticket folder that you want to archive and click on the three dots.",
        },
        {
          id: 3,
          title: "New prompt will pop up, here select <b>Archive</b> option",
        },
        {
          id: 4,
          title: "Then, hit the <b>archive</b> button",
          imageSource: `${GifeBaseUrl}archiving-ticket-folder.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "Share ticket folder",
      steps: [
        {
          id: 1,
          title: `Login to your dashboard, click on a <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Hover over the ticket folder that you want to share and click on the three dots.",
        },
        {
          id: 3,
          title: "Click on the <b>Share</b> option.",
        },
        {
          id: 4,
          title: "Copy the link and share it with your peers",
          imageSource: `${GifeBaseUrl}share-ticket-folder.gif`,
        },
      ],
      text1content: `Remember, if you choose the <b>'Restricted'</b> option while sharing a task folder, the user must be logged in to view it. And members from other workspaces won't have access unless they are logged in to your workspace or added as project members.`,
    },
  ],
};
