import plus from "./../../../../../new/icons/plus.png";
import down from "./../../../../../new/icons/down.svg";
import iicon from "./../../../../../new/icons/i.svg";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IMMcontent = {
  title: "Inviting and Managing Members",
  link: "inviting-and-managing-members",
  content: [
    {
      id: 0,
      title: "Invite Member",
      onedescription:
        "There are three ways to invite members.</br><br/><b>Option: 1</b>",

      steps: [
        {
          id: 1,
          title: `From your dashboard, click on your Workspace icon from the left side corner.`,
        },
        {
          id: 2,
          title: `From the dropdown menu, select the <b>Member</b> option.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${plus} alt="plus" height="12" width="12"/> button from the  right side corner.`,
        },
        {
          id: 4,
          title: `Enter the email address of the member.`,
        },
        {
          id: 5,
          title: `Select <b>As a member </b>option.`,
        },
        {
          id: 6,
          title: `Click on the <b>invite</b>.`,
          imageSource: `${GifBaseUrl}inviting-members.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "If you don't want to manually add email addresses and names, you can simply copy the invite link from here and share it with the person you want to invite. You can share the same link to invite multiple people.",
        },
      ],

      add_title: "Option: 2",
      
      add_steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> icon from the left. `,
        },
        {
          id: 2,
          title: "Select the <b>Invite Members</b> option.",
        },
        {
          id: 3,
          title: "Then continue the process mentioned above.",
          imageSource: `${GifBaseUrl}inviting-member-alternative.gif`,
        },
      ],

      third_title: "Option: 3",
      third_steps: [
        {
          id: 1,
          title: `Click on you workspace icon from the top left.`,
        },
        {
          id: 2,
          title: "Select <b>Members</b>.",
        },
        {
          id: 3,
          title: `Click on the <img src=${plus} alt="plus" height="14px" width="14px"/> button from the left.`,
        },
        {
          id: 4,
          title: "Then, continue the process as mentioned above.",
          imageSource: `${GifBaseUrl}inviting-member-alternatives.gif`,
        },
      ],

      third_title_extra: [
        {
          id: 1,
          title:
            "While following Option: 3 to invite members, here you can also view all of your invited members, externals, and companies and also, you can search for them simply by entering their name.",
          imageSource: `${GifBaseUrl}searching-person.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Deactivate Member",
      onedescription:
        "There are two ways to deactivate member from you workspace.</br><br/><b>Option: 1</b>",

      steps: [
        {
          id: 1,
          title: `From your dashboard, click on your Workspace from the left.`,
        },
        {
          id: 2,
          title: `From the dropdown menu, select the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Click on the <b>Members</b>.`,
        },
        {
          id: 4,
          title: `Search the member whose account you want to deactivate.`,
        },
        {
          id: 5,
          title: `Click on <b>three horizontal dots</b> option.`,
        },
        {
          id: 6,
          title: `Click on <b>Deactivate</b>.`,
          imageSource: `${GifBaseUrl}deactivating-inviting-members.gif`,
        },
      ],

      add_title: "Option: 2",
      add_steps: [
        {
          id: 1,
          title: `Click on your workspace icon from the top left.`,
        },
        {
          id: 2,
          title: "Select <b>Members</b>.",
        },
        {
          id: 3,
          title: `Click on the <b>three horizontal dots</b>.`,
        },
        {
          id: 4,
          title: `Search the member whose account you want to deactivate.`,
        },
        {
          id: 5,
          title: "Select the <b>Deactivate</b>.",
          imageSource: `${GifBaseUrl}deactivating-inviting-members-alternative.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Change Members Role",
      showMemberRoles: true,

      onedescription:
        "</br>There are two ways to change members' roles.</br></br><b>Option: 1</b> ",

      steps: [
        {
          id: 1,
          title: `From the dashboard, click on your Workspace icon from the left.`,
        },
        {
          id: 2,
          title: `From the dropdown menu, select the <b>Workspace Settings</b> option.`,
        },
        {
          id: 3,
          title: `Click on the <b>Members</b> option.`,
        },
        {
          id: 4,
          title: `Navigate to the member whose role you want to change.`,
        },
        {
          id: 5,
          title: `Click on the <b>three horizontal dots</b> option.`,
        },
        {
          id: 6,
          title: `Click on <b>Change Role</b> option and select the role that you want to assign.`,
        },
        {
          id: 7,
          title: `Click on <b>Save</b>.`,
          imageSource: `${GifBaseUrl}change-role.gif`,
        },
      ],

      add_title: "Option: 2",
      add_steps: [
        {
          id: 1,
          title: `Click on your workspace icon from the top left.`,
        },
        {
          id: 2,
          title: "Select <b>Members</b>.",
        },
        {
          id: 3,
          title: `Click on the <b>three horizontal dots</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>Change Role</b> option and select the role that you want to assign.`,
        },
        {
          id: 5,
          title: "Click on <b>Save</b>.",
          imageSource: `${GifBaseUrl}change-role-alternative.gif`,
        },
      ],
    },
  ],
};
