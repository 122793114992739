import React, { useEffect, useState } from "react";

import { Col } from "antd";
import {
	ContentDiv,
	NoteDiv,
	H3Text,
	PText,
	StepContent,
} from "../../../../styleComponents";
import ModalPage from "../../../common/ModalPage";
import ReactHtmlParser from "react-html-parser";
import { MCcontent } from "./ManagingCalendar/content";
function LayoutPage(props) {
	//   console.log(props.content);
	const data = props.content ? props.content : MCcontent.content[0];
	const [showImage, setShowImage] = useState("");
	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === "Escape") {
				setShowImage("");
			}
		};
		document.addEventListener("keydown", handleKeyPress);
	});

	return (
		<>
			<Col
				xs={{ span: 24 }}
				md={{ span: 10 }}
				lg={{ span: 14 }}
				className="second-column"
			>
				<ContentDiv>
					<H3Text mb="1rem">{data.title}</H3Text>
					{data.onetitle && <PText>{data.onetitle}</PText>}

					{data.steps && (
						<StepContent>
							{data.steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					<ModalPage
						shown={showImage}
						close={() => {
							setShowImage("");
						}}
						image={showImage}
					/>
					{data.Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
					{data.text1content && <PText>{data.text1content}</PText>}
				</ContentDiv>
				{data.add_title && <PText>{data.add_title}</PText>}

				<ContentDiv>
					{data.add_steps && (
						<StepContent>
							{data.add_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					{data.text2content && <PText>{data.contentplus}</PText>}
					{data.Add_Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.Add_Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
				</ContentDiv>
				{data.one_more_title && <PText>{data.one_more_title}</PText>}

				<ContentDiv>
					{data.one_more_steps && (
						<StepContent>
							{data.one_more_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					{data.text3content && <PText>{data.text3content}</PText>}
					{data.one_More_Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.one_More_Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
				</ContentDiv>
			</Col>
			<Col
				xs={{ span: 0 }}
				md={{ span: 5 }}
				lg={{ span: 4 }}
				className="third-column"
			></Col>
		</>
	);
}

export default LayoutPage;
