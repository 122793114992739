// import AnydoneProto from './protos/anydone_pb';

export class LocalDb {
	constructor() {
		this.sessionKey = "anyDoneSession";
	}

	getSessions() {
		const itemGot = localStorage.getItem(this.sessionKey);
		if (itemGot !== null) {
			return JSON.parse(itemGot);
		}
		return null;
	}

	getUser() {
		const itemGot = localStorage.getItem(this.sessionKey);
		if (itemGot) {
			return JSON.parse(itemGot).user;
		}
		return null;
	}

	setInitialToken(initialTokenObj, isInitialToken) {
		const itemGot = localStorage.getItem("anyDoneSession")
			? JSON.parse(localStorage.getItem("anyDoneSession"))
			: undefined;
		if (itemGot) {
			if (isInitialToken) {
				initialTokenObj.isLoggedIn = true;
				const updatedTokenList = [];
				itemGot.tokensList &&
					itemGot.tokensList.map((singleToken) =>
						updatedTokenList.push({
							...singleToken,
							isLoggedIn: true,
						})
					);
				updatedTokenList.push(initialTokenObj);
				itemGot.tokensList = updatedTokenList;
			} else {
				itemGot.tokensList = [initialTokenObj];
			}
			localStorage.setItem("anyDoneSession", JSON.stringify(itemGot));
			this.setSessionList(itemGot);
		}
		return null;
	}

	getTokensList() {
		const itemGot = localStorage.getItem("sessionList");
		// console.log({ itemGot });
		if (itemGot && JSON.parse(itemGot)) {
			const tokenList = [];
			for (const item in JSON.parse(itemGot)) {
				JSON.parse(itemGot)[item].tokensList &&
					JSON.parse(itemGot)[item].tokensList.map((singleToken) =>
						tokenList.push({
							...singleToken,
							serviceprovider: singleToken.serviceprovider,
							primaryColor: singleToken.colorsList && singleToken.colorsList[0],
							secondaryColor:
								singleToken.colorsList && singleToken.colorsList[1],
							isLoggedIn:
								singleToken.isLoggedIn !== undefined
									? singleToken.isLoggedIn
									: true,
							user: JSON.parse(itemGot)[item].user,
						})
					);
			}
			return tokenList;
		}
		return null;
	}

	getUserSessionId() {
		const selectedSpId = localStorage.getItem("selectedSpId");
		const selectedToken =
			this.getTokensList() &&
			this.getTokensList().find(
				(i) => i.serviceprovider && i.serviceprovider.accountid === selectedSpId
			);

		return selectedToken && selectedToken.sessionid;
	}

	getSpecificSuperAdmin(spAccountId) {
		return (
			this.getTokensList() &&
			this.getTokensList().filter(
				(single) =>
					single.serviceprovider &&
					single.serviceprovider.accountid === spAccountId
			)
		);
	}

	getSuperAdminList() {
		const idList = [];
		this.getTokensList() &&
			this.getTokensList().map((single) =>
				idList.push(single.serviceprovider && single.serviceprovider.accountid)
			);
		return idList;
	}

	getServiceProvider() {
		if (this.getUser()) {
			return this.getUser().billingaccount;
		}
	}

	isServiceProvider() {
		return this.getServiceProvider() !== undefined;
	}

	isGuest() {
		return (
			this.getEmployee() &&
			this.getEmployee().account &&
			this.getEmployee().account.accounttype === 6
		);
	}

	getEmployee() {
		if (this.getUser()) {
			return this.getUser().employee;
		}
	}

	isEmployee() {
		return this.getEmployee() !== undefined;
	}
	getUserProfileAccount() {
		if (this.isEmployee() !== null) {
			return this.getEmployee() && this.getEmployee().account;
		}
		if (this.isServiceProvider() !== null) {
			return this.getServiceProvider() && this.getServiceProvider().account;
		}
	}

	getAccountDetail() {
		if (this.isEmployee()) {
			return this.getEmployee().account;
		}
		if (this.isServiceProvider()) {
			return this.getServiceProvider().account;
		}
	}

	getAccountSettingsList() {
		if (this.isEmployee()) {
			return this.getEmployee().settingsList;
		}
		if (this.isServiceProvider()) {
			return this.getServiceProvider().settingsList;
		}
	}

	getAccountType() {
		if (this.getUser()) {
			return this.getUser().accounttype;
		}
	}

	getUserAccountIdList() {
		const accountIdList = [];
		const sessionList = localStorage.getItem("sessionList")
			? JSON.parse(localStorage.getItem("sessionList"))
			: {};
		for (const property in sessionList) {
			accountIdList.push(property);
		}
		return accountIdList;
	}

	getUserAccountId() {
		if (this.isEmployee()) {
			return this.getEmployee().account.accountid;
		}
		if (this.isServiceProvider()) {
			return this.getServiceProvider().account.accountid;
		}
	}

	getUserAccountEmailId() {
		if (this.isEmployee()) {
			return this.getEmployee().account.email;
		}
		if (this.isServiceProvider()) {
			return this.getServiceProvider().account.email;
		}
	}

	getProfileId() {
		if (this.isEmployee()) {
			return this.getEmployee().employeeprofileid;
		}
		if (this.isServiceProvider()) {
			return this.getServiceProvider().serviceproviderprofileid;
		}
	}

	getPermission() {
		return this.isEmployee() !== undefined && this.getEmployee() !== undefined
			? this.getEmployee().permissions
			: undefined;
	}

	getSpecificUserAccountId(user) {
		if (user) {
			if (user.employee) {
				return user.employee.account && user.employee.account.accountid;
			}
			if (user.billingaccount) {
				return (
					user.billingaccount.account && user.billingaccount.account.accountid
				);
			}
		}
	}

	getServiceId() {
		const service = this.getSessions();
		return (
			service &&
			service.user &&
			service.user.employee &&
			service.user.employee.serviceproviderid
		);
	}

	setSessionList(session, callback) {
		// console.log({ session });
		if (session) {
			try {
				const sortStr = localStorage.getItem("sessionList")
					? JSON.parse(localStorage.getItem("sessionList"))
					: {};
				sortStr[this.getSpecificUserAccountId(session.user)] = session;
				localStorage.setItem("sessionList", JSON.stringify(sortStr), (error) =>
					callback(error)
				);
				callback(false);
			} catch (error) {
				console.log("Error while setting session list.", error);
			}
		}
	}

	replaceSessionList(session, callback) {
		// console.log('replace session', { session });
		if (session) {
			try {
				localStorage.setItem("sessionList", JSON.stringify(session), (error) =>
					callback(error)
				);
				callback(false);
			} catch (error) {
				console.log("Error while setting session list.", error);
			}
		}
	}

	setSession(session, callback) {
		console.log("setSession", localStorage, this, session);
		try {
			localStorage.setItem(this.sessionKey, JSON.stringify(session), (error) =>
				callback(error)
			);
			callback(false);
		} catch (error) {
			console.log("Error while setting session.", error);
		}
	}

	setAccountSession(session) {
		localStorage.setItem("accountSession", JSON.stringify(session));
	}

	removeSession(/* callback */) {
		try {
			// localStorage.removeItem(this.sessionKey, error => callback(error));
			localStorage.clear();
		} catch (error) {
			console.log("Error while removing session.", error);
		}
	}

	saveInLocalDB(key, dataToSave, callback) {
		try {
			localStorage.setItem(key, JSON.stringify(dataToSave), (error) =>
				callback(error)
			);
			callback(false);
		} catch (error) {
			console.log("Error while setting session.", error);
		}
	}

	getSavedItemFromDB(key) {
		const itemGot = localStorage.getItem(key);
		if (itemGot !== null) {
			return JSON.parse(itemGot);
		}
		return null;
	}

	changeUserOnlineStatus(status) {
		const anyDoneSession =
			localStorage.getItem("anyDoneSession") &&
			JSON.parse(localStorage.getItem("anyDoneSession"));
		if (anyDoneSession.user.billingaccount) {
			anyDoneSession.user.billingaccount.account.onlinestatus = status;
		} else {
			anyDoneSession.user.employee.account.onlinestatus = status;
		}
		return anyDoneSession;
	}

	getActiveSp() {
		return (
			this.getTokensList() &&
			this.getTokensList().filter((singleSp) => singleSp.token) &&
			this.getTokensList().filter((singleSp) => singleSp.token)[0]
		);
	}

	// ticket permissions
	getPermissionForViewTicket() {
		if (this.isEmployee() === true) {
			const permissionObj =
				this.getPermission() &&
				this.getPermission().map((single) => {
					return single.permission;
				});
			if (
				(permissionObj && permissionObj.includes("ticket.admin") === true) ||
				(permissionObj && permissionObj.includes("ticket.write") === true) ||
				(permissionObj && permissionObj.includes("ticket.read") === true)
			) {
				return true;
			}
			return false;
		}
		return true;
	}

	getPermissionForCreateTicket() {
		if (this.isEmployee() === true) {
			const permissionObj =
				this.getPermission() &&
				this.getPermission().map((single) => {
					return single.permission;
				});
			if (
				(permissionObj && permissionObj.includes("ticket.admin") === true) ||
				(permissionObj && permissionObj.includes("ticket.write") === true)
			) {
				return true;
			}
			return false;
		}
		return true;
	}

	getPermissionForTicketAdmin() {
		if (this.isEmployee() === true) {
			const permissionObj =
				this.getPermission() &&
				this.getPermission().map((single) => {
					return single.permission;
				});
			if (permissionObj && permissionObj.includes("ticket.admin") === true) {
				return true;
			}
			return false;
		}
		return true;
	}

	getPermissionListOfActiveUser = () => {
		const tokensList =
			this.getSessions() &&
			this.getSessions().tokensList &&
			this.getSessions().tokensList.find(
				(singleToken) =>
					singleToken.serviceprovider.accountid ===
					localStorage.getItem("selectedSpId")
			);

		return tokensList && tokensList.permissionList;
	};

	hasInboxPermission = () => {
		return (
			this.getPermissionListOfActiveUser() &&
			this.getPermissionListOfActiveUser().find(
				(singlePermission) => singlePermission.permission === "inbox.read"
			)
		);
	};

	hasTicketAdminPermission = () => {
		return (
			this.getPermissionListOfActiveUser() &&
			this.getPermissionListOfActiveUser().find(
				(singlePermission) => singlePermission.permission === "ticket.admin"
			)
		);
	};
}

export default new LocalDb();
