import plus from "./../../../../../new/icons/plus.png";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const PPcontent = {
  title: "Profile Preferences",
  link: "profile-preferences",
  content: [
    {
      id: 0,
      title: "Change Language and Timezone",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>profile icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Preferences</b> option.`,
        },
        {
          id: 3,
          title: `Click on <b>General</b>.`,
        },
        {
          id: 4,
          title: `Here, choose your preferred <b>Language</b> and <b>Timezone</b>.`,
        },
        {
          id: 5,
          title: `Then click on the <b>Save</b> button.`,
          imageSource: `${GifBaseUrl}change-language-and-timezone.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Change Appearance",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>profile icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Preferences</b> option.`,
        },
        {
          id: 3,
          title: ` Click on <b>Appearance</b>.`,
        },
        {
          id: 4,
          title: `Here, choose your <b>preferred theme</b>, <b>thread view</b>, and <b>message pane</b>.`,
        },
        {
          id: 5,
          title: `Then click on the <b>Save</b> button.`,
          imageSource: `${GifBaseUrl}change-appearance.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Notification Settings",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>profile icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Preferences</b> option.`,
        },
        {
          id: 3,
          title: `Click on <b>Notification</b>.`,
        },
        {
          id: 4,
          title: `Here, you can make the following changes.`,
          imageSource: `${GifBaseUrl}notification-setting.gif`,
        },
      ],
      showNotificationInfo: true,
    },
    {
      id: 3,
      title: "Change and Set Weekly working hours",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>profile icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Preferences</b> option.`,
        },
        {
          id: 3,
          title: `Click on <b>Workdays & Leaves</b>.`,
        },
        {
          id: 4,
          title:
            "Here, make changes or set your weekly working hours accordingly.",
        },
        {
          id: 5,
          title: `Then click on the <b>Save</b> button.`,
          imageSource: `${GifBaseUrl}workdays-and-holidays.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "How to set leaves?",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>profile icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Preferences</b> option.`,
        },
        {
          id: 3,
          title: `Click on <b>Workdays & Leaves</b>.`,
        },
        {
          id: 4,
          title: `Click on <b>Leaves</b>, then click on the <img src=${plus} alt="plus" height="12" width="12"/>  button.`,
        },
        {
          id: 4,
          title: "Set your leave date and give a description.",
        },
        {
          id: 5,
          title: `Then click on <b>Add</b> button.`,
          imageSource: `${GifBaseUrl}set-leaves.gif`,
        },
      ],
    },
    // {
    // 	id: 5,
    // 	title: "Screen Recording",
    // 	steps: [
    // 		{
    // 			id: 1,
    // 			title: "Go to your dashboard, click on message like icon",
    // 		},
    // 		{
    // 			id: 2,
    // 			title: "Now go to the text editor section.",
    // 		},
    // 		{
    // 			id: 3,
    // 			title:
    // 				" Click on the screen like icon and new propmt will open, here, give it a tittle.",
    // 		},
    // 		{
    // 			id: 4,
    // 			title:
    // 				"Also, you'll see options like Recod voice and Record video, press the toggle button according to your preference.",
    // 		},
    // 		{
    // 			id: 5,
    // 			title: "Then click, on Start button.",
    // 		},
    // 		{
    // 			id: 6,
    // 			title:
    // 				" In a new prompt click on the tab or widow that you want to record.",
    // 		},
    // 		{
    // 			id: 7,
    // 			title: "Then, click on the Share button.",
    // 		},
    // 		{
    // 			id: 8,
    // 			title:
    // 				"After you done the recording you can share it with your peers, for that click on the Send button .",
    // 		},
    // 		{
    // 			id: 9,
    // 			title: "Or you can can Create ticket or Discard.",
    // 			imageSource: ``,
    // 		},
    // 	],
    // },
  ],
};
