import { Col, Breadcrumb, Menu } from "antd";
import React, { useEffect, useState } from "react";
import MainHeader from "../../common/MainHeader";
import useResponsive from "../../common/useResponsive";
import GoBack from "../../common/GoBack";
import { FAQContents } from "./FAQContents";
import { RowDiv, SecStartedContent, Stickydiv } from "../../../styleComponents";
import BorderDiv from "../../common/BorderDiv";
import { Helmet } from "react-helmet";
import LayoutFaq from "./LayoutFaq";
import { Waypoint } from "react-waypoint";

export default function FAQ({ handleWaypointEnter, handleWaypointLeave }) {
	const [active, setActive] = useState("0");
	const width = useResponsive();
	const breakPoint = 768;
	const [content, setContent] = useState(FAQContents[0]);
	useEffect(() => {
		setContent(FAQContents[0]);
	}, []);

	const handleClick = (e) => {
		setActive(e.key);
	};
	const showComponent = (id) => {
		const selectedContent = FAQContents.filter(
			(menuContent) => id === menuContent.id
		);
		setContent(selectedContent[0]);
	};
	// console.log(FAQContents[0]);

	return (
		<div>
			<Helmet>
				<title>Frequently Asked Questions</title>
				<meta
					name="description"
					content="Feel free to poke around these topics to find just what are you looking for."
				/>
			</Helmet>
			<MainHeader
				heading="Frequently Asked Questions"
				content="Feel free to poke around these topics to find just what are you looking for."
			/>

			<Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

			<SecStartedContent>
				<RowDiv no={2}>
					<Col
						xs={{ span: 0 }}
						md={{ span: 6 }}
						lg={{ span: 4 }}
						className="first-column"
					>
						<Stickydiv>
							<GoBack />
							<BorderDiv mb={1} mt={1} />
							<div className="started-items">
								<Menu
									onClick={handleClick}
									selectedKeys={active}
									mode="vertical"
									className="side-menu"
								>
									{FAQContents.map((items, index) => (
										<Menu.Item
											key={index}
											onClick={() => {
												showComponent(index);
											}}
										>
											{index + 1}.&nbsp;{items.title}
										</Menu.Item>
									))}
								</Menu>
							</div>
						</Stickydiv>
					</Col>
					{active && (
						<Col
							xs={{ span: 24 }}
							md={{ span: 18 }}
							lg={{ span: 16 }}
							className="second-column"
						>
							<Breadcrumb separator=">">
								<Breadcrumb.Item href="/">Help Center</Breadcrumb.Item>
								<Breadcrumb.Item style={{ color: "#162A62" }}>
									Frequently Asked Questions
								</Breadcrumb.Item>
							</Breadcrumb>
							{width < breakPoint ? (
								FAQContents.map((items, index) => (
									<div key={index} style={{ marginBottom: "3rem" }}>
										<LayoutFaq value={items} />
									</div>
								))
							) : (
								<>
									<LayoutFaq value={content} />
								</>
							)}
						</Col>
					)}
				</RowDiv>
			</SecStartedContent>
		</div>
	);
}
