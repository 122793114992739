import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Subcontent = {
  title: "Subtasks",
  link: "subtasks",
  content: [
    {
      id: 0,
      title: "Generate Subtasks",
      onedescription:
        "You can generate subtasks in a ticket. You can only generate subtasks for a ticket that has already been created.",
      steps: [
        {
          id: 1,
          title: `Login to you dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 3,
          title: "Navigate to the project and its ticket folder.",
        },
        {
          id: 4,
          title: `Click on the ticket where you want to create subtask.`,
        },

        {
          id: 5,
          title: "Click on the <b>Generate Subtask</b>.",
          imageSource: `${GifeBaseUrl}generate-subtasks.gif`,
        },
      ],
      Note: [
        {
          title:
            "After generating the Subtasks you can select the subtask that you want from the list. Also, you can assign each subtask to different members of your workspace. And, remember, subtasks will be generated based on the ticket “Summary” and “Description”.",
        },
      ],
    },
  ],
};
