import plus from "./../../../../../new/icons/plus.png";
import down from "./../../../../../new/icons/down.svg";
import iicon from "./../../../../../new/icons/i.svg";
import message from "./../../../../../new/icons/message.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/getting-started/";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CGcontent = {
  title: "Creating CoSpaces",
  link: "creating-groups",
  content: [
    {
      id: 0,
      title: "Create New CoSpace",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on the  <img src=${message} alt="message" height="14" width="14"/>   icon from the left-hand side.`,
        },
        {
          id: 2,
          title: `Navigate to <b>CoSpaces</b> and click on the <img src=${plus} alt="plus" height="12" width="12"/> button.`,
        },
        {
          id: 3,
          title: `Now, on the right side, give your cospace a name.`,
        },
        {
          id: 4,
          title: `Add cospace <b>participants</b>.`,
        },
        {
          id: 5,
          title: ` Press the <b>toggle</b> button to make your cospace private or public.`,
        },
        {
          id: 6,
          title: `Then, click on the <b>Create</b> button.`,
          imageSource: `${GifBaseUrl}creating-new-cospace.gif`,
        },
      ],
      add_title: "Alternatively",
      add_steps: [
        {
          id: 1,
          title: `From your dashboard, click on the <img src=${plus} alt="plus" height="12" width="12"/> button from the top.`,
        },
        {
          id: 2,
          title: `Click on <b>New CoSpace</b>.`,
        },
        {
          id: 3,
          title: "Give the required information.",
        },
        {
          id: 4,
          title: ` Then, click on the <b>Create</b> button.`,
          imageSource: `${GifBaseUrl}creating-new-cospace-alrrnave.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Creating New subject",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Navigate to the <b>cospaces</b> and click on the <b>CoSpace</b> in which you want to create a new subject.`,
        },
        {
          id: 3,
          title: `Now click on the <img src=${down} alt="plus" height="28" width="28"/> button from the top of right side.`,
        },
        {
          id: 4,
          title: ` Click on <b>New Subject</b>.`,
        },
        {
          id: 5,
          title: ` Name the <b>cospace</b>, and add <b>participants </b>.`,
        },
        {
          id: 6,
          title: `Click on <b>Toggle</b> button to make it private or public.`,
        },
        {
          id: 7,
          title: `Now, click on <b>Create</b> button.`,
          imageSource: `${GifBaseUrl}creating-new-subject.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Convert Private Message Thread to CoSpace",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on <img src=${message} alt="message" height="14" width="14"/> icon.`,
        },
        {
          id: 2,
          title: `Go to the direct Messages and click on the private message thread which you want to convert into a cospace.`,
        },
        {
          id: 3,
          title: `Click on the sign indicated by <img src=${iicon} alt="iicon" height="28" width="28"/> button from the right side corner.`,
        },
        {
          id: 4,
          title: " Click on the Convert to <b>CoSpace</b> option.",
        },
        {
          id: 5,
          title: " Enter the CoSpace Name.",
        },
        {
          id: 6,
          title: `Click on <b>Save </b>.`,
        },
        {
          id: 7,
          title: `Now, click on the sign indicated by <img src=${iicon} alt="iicon" height="28" width="28"/> button from the right side corner.`,
        },
        {
          id: 8,
          title: `Click on the <b>Add Participants</b> option.`,
        },
        {
          id: 9,
          title: `Enter participants <b>name</b> or <b>emails</b> then, press <b>Add</b> button.`,
          imageSource: `${GifBaseUrl}private-message-to-threat.gif`,
        },
      ],
    },
  ],
};
