import React from "react";
import empty from "../images/empty_profile_holder_icon.svg";

export const extractWidth = (size) => {
    if (size)
        return (
            size.toString().replace("px", "") &&
            parseInt(size.toString().replace("px", ""))
        );
};

export const generateSizeForThumbnail = (width) => {
    return extractWidth(width) < 50
        ? 250
        : extractWidth(width) < 300
        ? 250
        : "";
};

export const generateUrlForThumbnail = (src, width) => {
    let size = generateSizeForThumbnail(width);
    if (size) {
        let extension = src.substring(src.lastIndexOf(".") + 1);
        return src.replace("." + extension, `_${size}.${extension}`);
    }
    return src;
};

const ImageComponent = ({ src, width, height, borderRadius, styles }) => {
    return (
        <div
            style={{
                width: width,
                height: height,
                borderRadius: borderRadius || "50%",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #f1f1f1",
                // background: 'rgb(86 86 86 / 70%)',
                ...styles,
            }}
        >
            <img
                src={generateUrlForThumbnail(src, width) || src || empty}
                alt="public"
                onError={(e) => (e.target.src = src || empty)}
                style={{ height: "100%" }}
            />
        </div>
    );
};
export default ImageComponent;
