import styled from "styled-components";

export const Box = styled.div`
  padding: 7rem 14rem 0 14rem;
  position: relative;
  bottom: 0;
  width: 100%;
  color: ;

  .border-div {
    margin-top: 50px;
    border: 1px solid #d9d9d9;
  }
  @media only screen and (max-width: 767px) {
    padding: 70px 20px 20px;
  }
  @media only screen and (min-width: 300px) and (max-width: 480px) {
  }
  @media only screen and (max-width: 300px) {
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
  grid-gap: 30px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(45%, 2fr));
  }
`;

export const FooterLink = styled.a`
  color: #5d6c82;
  margin-bottom: 10px;
  font-size: 16px;
  text-decoration: none;

  &:hover {
    color: #5d6c82;
    transition: 200ms ease-in;
    text-decoration: underline;
  }
`;

export const Heading = styled.p`
  font-weight: 600;
  font-size: 2rem;
  line-height: 30px;
  color: #5d6c82;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const DownfooterDiv = styled.div`
  margin: 30px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  color: #5d6c82;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }

  @media (min-width: 768px) and (max-width: 1457px) {
    flex-wrap: nowrap;
  }
`;
export const SocialLinkDiv = styled.div`
  display: flex;
  flex-direction: row;

  height: 30px;
  /* width: 25%; */
  div {
    margin-right: 16px;
  }
  img {
    height: 24px;
  }
  @media (max-width: 768px) {
    height: auto;
    justify-content: center;
  }
`;
export const CenterDiv = styled.div`
  width: 46%;
  height: 30px;
  .inner-center-content {
    display: flex;
    justify-content: space-between;
    /* width: 100%; */
  }
  .footer-width {
    padding-right: 4px;
  }

  span {
    /* width: 30%; */
    font-weight: 400;
    font-size: 16px;
    line-height: 2rem;
    text-align: center;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: auto;
    height: auto;
    height: fit-content;
    margin-bottom: 30px;
    .inner-center-content {
      display: flex;
      width: 100%;
    }
  }
`;
export const DownloadDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 30px;

  .stores {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .redirect-download-page {
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2597e9;
    @media (min-width: 768px) and (max-width: 1457px) {
      margin-right: 0px;
      a {
        max-width: 135px;
      }
    }
  }
  .redirect-download-page:hover {
    text-decoration: underline;
    text-decoration-color: #2597e9;
  }

  a {
    color: #2597e9;
  }
  img {
    margin-right: 5px;
  }

  @media (max-width: 768px) {
    margin-bottom: 30px;
    height: auto;
    flex-direction: column;
    align-items: center;
    .stores {
      margin-top: 20px;
    }
    .redirect-download-page {
      margin-right: 0px;
    }
  }
`;
