import React from "react";
import {
  EachBoxListing,
  ExploreButton,
  PText,
  TitleImgDiv,
} from "../../styleComponents";
import BorderDiv from "./BorderDiv";
import { Link } from "react-router-dom";
import arrowRight from "../../new/homepage/arrow-right.svg";

function CardListBox({ each }) {
  return (
    <EachBoxListing>
      <TitleImgDiv color="#333333">
        {/* <img src={each.icon} alt={each.title + `icon`} /> */}
        {each.title}
      </TitleImgDiv>

      <BorderDiv mb={1} mt={1} />

      <PText
        style={{
          paddingLeft: "2rem",
          marginBottom: "4rem",
          textAlign: "start",
        }}
      >
        <ul>
          {each.listing.slice(0, 3).map((single) => {
            return (
              <li key={single.id} >
                <Link
                  to={{
                    pathname: `${each.link}?tabid=${single.id}`,
                  }}
                  onClick={()=>{
                  window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
              }}
                >
                
                  {single.title}
                </Link>
              </li>
            );
          })}
        </ul>
        <Link to={`${each.link}?tabid=0`} state={{ active: "0" }} onClick={()=>{
              window.scrollTo({
                          top: 0,
                          behavior: "smooth",
                        });
        }}>
          <ExploreButton>
            Learn More <img src={arrowRight} alt="arrow-right" />
          </ExploreButton>
        </Link>
      </PText>
    </EachBoxListing>
  );
}

export default CardListBox;
