export const contents = [
  {
    id: 1,
    title: "Message Experience",
    link: "message-experience",
    listing: [
      {
        id: "0",
        title: "Create a new Direct Message (DM)",
      },
      {
        id: "1",
        title: "Edit and Delete messages",
      },
      {
        id: "2",
        title: "Reply and Forward Message",
      },
      {
        id: "3",
        title: "Remind Me About This ",
      },
    ],
  },
  {
    id: 2,
    title: "Manage Messages",
    link: "manage-messages",
    listing: [
      {
        id: "0",
        title: "Create and Save Notes",
      },
      {
        id: "1",
        title: "Create New Section",
      },
      {
        id: "2",
        title: "Schedule Message",
      },
      {
        id: "3",
        title: "Pin and Unpin Message",
      },
    ],
  },
  {
    id: 3,
    title: "Message Edit",
    link: "message-edit",
    listing: [
      {
        id: "0",
        title: "Refining text with generative AI",
      },
      {
        id: "1",
        title: "Emoji and Reactions",
      },
      {
        id: "2",
        title: "Create New Post",
      },
      {
        id: "3",
        title: "Code editor",
      },
    ],
  },
  {
    id: 4,
    title: "CoSpaces",
    link: "groups",
    listing: [
      {
        id: "0",
        title: "Join CoSpace",
      },
      {
        id: "1",
        title: "Leave CoSpace",
      },
      {
        id: "2",
        title: "Leave and Delete CoSpace",
      },
      {
        id: "3",
        title: "Archive CoSpace",
      },
      {
        id: "4",
        title: "View Archived CoSpaces",
      },
      {
        id: "5",
        title: "Unarchive CoSpace",
      },
    ],
  },
  {
    id: 9,
    title: "Creating CoSpace",
    link: "creating-group",
    listing: [
      {
        id: "0",
        title: "Create New CoSpace",
      },
      {
        id: "1",
        title: "Creating New subject",
      },
      {
        id: "2",
        title: "Convert Private Message Thread to CoSpace",
      },
    ],
  },
  {
    id: 10,
    title: "Manage CoSpace",
    link: "manage-groups",
    listing: [
      {
        id: "0",
        title: "Mute CoSpace",
      },
      {
        id: "1",
        title: "Unmute CoSpace",
      },
      {
        id: "2",
        title: "Mute Specific Participants from the CoSpace",
      },
      {
        id: "3",
        title: "Unmute Specific Participant from the CoSpace",
      },
    ],
  },
  {
    id: 7,
    title: "Collab",
    link: "collab",
    listing: [
      {
        id: "0",
        title: "Starting a Collab",
      },
    ],
  },
  // {
  //   id: 7,
  //   title: "CoConnect",
  //   link: "calls",
  //   listing: [
  //     {
  //       id: "0",
  //       title: "Starting a CoConnect",
  //     },
  //     {
  //       id: "1",
  //       title: "Invite additional member during the CoConnect",
  //     },
  //     {
  //       id: "2",
  //       title: "Open CoVas in CoConnect",
  //     },
  //     {
  //       id: "3",
  //       title: "Make a Video Call with CoConnect",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   title: "MeetAssist",
  //   link: "meetassist",
  //   listing: [
  //     {
  //       id: "0",
  //       title: "Starting a MeetAssist",
  //     },
  //   ],
  // },
  {
    id: 9,
    title: "Manage Activity",
    link: "manage-activity",
    listing: [
      {
        id: "0",
        title: "Add Participants to a direct message",
      },
      {
        id: "1",
        title: "Mention Anything",
      },
      {
        id: "2",
        title: "Reply Privately",
      },
      {
        id: "3",
        title: "React to Messages",
      },
      {
        id: "4",
        title: "Search Anything",
      },
      {
        id: "5",
        title: "Share attachment",
      },
    ],
  },
];
