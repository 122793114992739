import plus from "./../../../../../new/icons/plus.png";
const ImageBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/meetings/";
const GifBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const WBcontent = {
	title: "Creating Meetings",
	link: "creating-meetings",
	content: [
		{
			id: 0,
			title: "Scope of Whiteboard",
			onetitle: `In anydone whiteboard, you can also set the background, add images, download screenshots, share your whiteboard activity with your peers, create unlimited boards, etc`,
			steps: [
				{
					id: 1,
					title: `First, create a meeting.`,
				},
				{
					id: 2,
					title: `Once the meeting is started, click on the board-like icon.`,
				},
				{
					id: 3,
					title: `New windows will open here, and you can draw and present your ideas in a most collaborative way.`,
					imageSource: ``,
				},
			],
			text1content: `<i>Remember, Whiteboard is available in meetings, one-time, quick, and video calls.</i>`,
		},
	],
};
