import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import anydonelogo from "../../new/titlelogo.svg";
import CloseIcon from "../../new/icons/close.png";
import HbarsIcon from "../../new/icons/Hbars.svg";
import Hbars1Icon from "../../new/icons/Hbars1.svg";
import anydoneWlogo from "../../new/title-w-logo.svg";
import LocalDb from "../../../localStorage";
import { InboxIcon, removeWordAfterSpace } from "../../utils/helper";
import { LocalHost } from "../../../globalConstants";
import { Drawer } from "antd";
import { FlexDiv, LogoContainer, NavDiv } from "../../styleComponents";

export default function Nav({ isPastWaypoint, userSession, sessionLoading }) {
  const userAccount = LocalDb.getUserProfileAccount();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
  }, []);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <NavDiv className={`to-toggle ${isPastWaypoint ? "is-past-waypoint" : ""}`}>
      <LogoContainer>
        <Link to="/" style={{ color: "black", cursor: "pointer" }}>
          {isPastWaypoint ? (
            <img
              src={anydoneWlogo}
              alt="not found"
              style={{ height: "30px" }}
            />
          ) : (
            <img src={anydonelogo} alt="not found" style={{ height: "30px" }} />
          )}
        </Link>
      </LogoContainer>
      <FlexDiv direction={"row"}>
        {sessionLoading ? null : userSession !== null ? (
          <div className="user-if-there">
            {InboxIcon(
              "DIRECT_MESSAGE",
              userAccount && (userAccount.fullname || userAccount.fullName),
              "28px",
              (userAccount && userAccount.profilepic) ||
                (userAccount && userAccount.profilePic)
            )}
            <span style={{ color: isPastWaypoint ? "black" : "white" }}>
              {userAccount &&
                removeWordAfterSpace(
                  userAccount && (userAccount.fullname || userAccount.fullName)
                )}
            </span>
          </div>
        ) : (
          <>
            {screenWidth < 768 ? (
              <button
                type="primary"
                onClick={showDrawer}
                className="hamburgerIcon"
              >
                {isPastWaypoint ? (
                  <img src={HbarsIcon} alt="close-icon" />
                ) : (
                  <img src={Hbars1Icon} alt="close-icon" />
                )}
              </button>
            ) : (
              <div className="sigin">
                <div
                   onClick={() => {
              window.open("http://app.anydone.com/", "_blank");
            }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "18px",
                    marginRight: "2rem",
                    color: isPastWaypoint ? "black" : "white",
                  }}
                >
                  Sign in
                </div>
                <div>
                  <button
                    className="try-for-btn"
                    onClick={() => {
                      window.open(
                        "http://app.anydone.com/register/company",
                        "_blank"
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    Try for free
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        <Drawer
          title={
            <LogoContainer>
              <Link to="/" style={{ color: "black", cursor: "pointer" }}>
                <img src={anydonelogo} alt="not found" height={"30px"}/>
              </Link>
            </LogoContainer>
          }
          placement="right"
          onClose={onClose}
          closeIcon={
            <img
              src={CloseIcon}
              alt="close-icon"
              style={{ height: "18px", width: "18px" }}
            />
          }
          open={open}
        >
          <button
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 25px",
              backgroundColor: "transparent",
              border: "1px solid #2396e9",
              borderRadius: "1rem",
              fontSize: "16px",
              marginBottom: "2rem",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("http://app.anydone.com/", "_blank");
            }}
          >
            Sign in
          </button>
          <button
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 25px",
              backgroundColor: "#2396e9",
              borderRadius: "1rem",
              fontSize: "16px",
              outline: "none",
              border: "none",
              color: "white",
              cursor: "pointer",
            }}
            onClick={() => {
              window.open("http://app.anydone.com/register/company", "_blank");
            }}
          >
            Try for free
          </button>
        </Drawer>
      </FlexDiv>
    </NavDiv>
  );
}
