import React, { useEffect, useState } from "react";

import { Col } from "antd";
import {
  ContentDiv,
  NoteDiv,
  H3Text,
  PText,
  StepContent,
} from "../../../../styleComponents";
import ModalPage from "../../../common/ModalPage";
import ReactHtmlParser from "react-html-parser";
import { UAcontent } from "./UsingAnydone/content";
function LayoutPage(props) {
  //   console.log(props.content);
  const data = props.content ? props.content : UAcontent.content[0];
  const [showImage, setShowImage] = useState("");
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setShowImage("");
      }
    };
    document.addEventListener("keydown", handleKeyPress);
  });

  return (
    <>
      <Col
        xs={{ span: 24 }}
        md={{ span: 10 }}
        lg={{ span: 14 }}
        className="second-column"
      >
        <ContentDiv>
          <H3Text mb="1rem">{data.title}</H3Text>

          {data.onetitle && <PText>{data.onetitle}</PText>}
          {data.onedescription && (
            <PText>{ReactHtmlParser(data.onedescription)}</PText>
          )}
          {data.steps && (
            <StepContent>
              {data.steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.Note?.map((each) => {
                  return (
                    <li key={each.id}>
                      <i>{each.title}</i>
                    </li>
                  );
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
          <ModalPage
            shown={showImage}
            close={() => {
              setShowImage("");
            }}
            image={showImage}
          />

          {data.text1content && (
            <PText>{ReactHtmlParser(data.text1content)}</PText>
          )}
        </ContentDiv>

        {data.add_title && (
          <PText>
            {data.add_title === "Alternatively," ? (
              <b>
                <i>{data.add_title}</i>
              </b>
            ) : (
              <b>{data.add_title}</b>
            )}
          </PText>
        )}

        <ContentDiv>
          {data.add_steps && (
            <StepContent>
              {data.add_steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.text2content && <PText>{data.contentplus}</PText>}
          {data.Add_Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.Add_Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
        </ContentDiv>
        {data.third_title && (
          <PText>
            <b>{data.third_title}</b>
          </PText>
        )}
        <ContentDiv>
          {data.third_steps && (
            <StepContent>
              {data.third_steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.text2content && <PText>{data.contentplus}</PText>}
          {data.Add_Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.Add_Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
        </ContentDiv>

        <ContentDiv>
          {data.third_title_extra?.map((each) => (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div key={each.id}>{each.title}</div>
              <br />
              {each.imageSource && (
                <img
                  src={each.imageSource}
                  alt="not-loaded"
                  width="100%"
                  style={{
                    marginBottom: "2rem",
                    boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                  }}
                  onClick={() => {
                    setShowImage(each.imageSource);
                  }}
                />
              )}
            </div>
          ))}
        </ContentDiv>

        <ContentDiv>
          {data.showNotificationInfo && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div>
                <PText>
                  <b>Notify about:</b>&nbsp; Choose when to get notified. You
                  can select between all new messages and calls, direct
                  messages, calls, and reply, and don't notify.
                </PText>
              </div>
              <div>
                <PText>
                  <b>Notification Channels:</b>&nbsp;You can select in what way
                  you wish to receive a notification. You can choose between
                  Push, In-App, or Email.
                </PText>
              </div>
              <div>
                <PText>
                  <b>Sound & Appearance:</b>&nbsp;You can set how the
                  notification looks and behave and even see the real-time
                  example
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <i>
                    Lastly, remember to click the Save button after making
                    changes.
                  </i>
                </PText>
              </div>
            </div>
          )}

          {data.isgeneralSetting && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div>
                <PText>
                  <b>Workspace Language:</b>&nbsp;Click on the Workspace
                  Language drop-down menu option and select your preferred
                  language for your Workspace.
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <b>Email Display:</b>&nbsp;You can set who can view the
                  company member's email addresses. You can change either No one
                  or Members and guests of the new company.
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <b>Who can invite members?:</b>&nbsp;You can choose who can
                  invite members to your workspace. You can set either Admin or
                  Everyone except guests.
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <b>Default CoSpace:</b>&nbsp;Ticking this option enables
                  automatically adding members to the General CoSpace without
                  any manual work.
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <i>
                    Remember, whatever changes you make under General Settings,
                    remember to hit the Save button at last.
                  </i>
                </PText>
              </div>
            </div>
          )}
          {data.showMemberRoles && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div>
                <PText>You can change a member's role to Owner or Admin.</PText>
              </div>
              <br />
              <div>
                <PText>
                  <b>What Owner can do?</b>
                </PText>
              </div>

              <div>
                <PText>
                  Once a member's role is changed to the Owner, one has the full
                  access to the workspace setting and they can make changes to
                  every aspect of workspace, but they can't change the role of
                  the primary owner (whose email is used while signing up at
                  anydone).
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <b>What Admin can do?</b>
                </PText>
              </div>

              <div>
                <PText>
                  Admin permission is limited by the owner. Admin can make
                  changes to the workspace based on the permissions set by the
                  owner.
                </PText>
              </div>
            </div>
          )}
        </ContentDiv>
      </Col>
      <Col
        xs={{ span: 0 }}
        md={{ span: 5 }}
        lg={{ span: 4 }}
        className="third-column"
      ></Col>
    </>
  );
}

export default LayoutPage;
