export const attachmentHelper = (attachments) => {
    const filteredList =
        attachments &&
        attachments.length > 0 &&
        attachments.map(function (item) {
            delete item.id;
            return item;
        });
    return filteredList;
};
