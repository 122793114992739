import plus from "./../../../../../new/icons/plus.png";

const GifBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const ABcontent = {
	title: "Bot",
	link: "anydone-bot",
	content: [
		{
			id: 0,
			title: "Adding Bot",

			steps: [
				{
					id: 1,
					title: `From your anydone dashboard, click on the <b>Workspace</b> icon and select Workplace Settings.`,
				},
				{
					id: 2,
					title: `Navigate to Automation and click on <b>Bot</b>.`,
				},
				{
					id: 3,
					title: `Click on <img src=${plus} alt="plus" height="12" width="12"/>  button from the right side corner.`,
				},
				{
					id: 4,
					title: `Now, give a <b>bot name</b>, add <b>image</b>, select all the necessary details.`,
				},
				{
					id: 5,
					title: `Click on <b>Visible To Inbox</b> section.`,
				},
				{
					id: 6,
					title: `Now, click on <b>Create</b> button.`,
					imageSource: `${GifBaseUrl}bot-adding.gif`,
				},
			],
		},
	],
};
