import Project from "../../../../../new/icons/project.svg";
import Automation from "../../../../../new/icons/automation.svg";
const imageUrl2="https://static-edge-a.anydone.com/assets/help-page/all-gif/"


const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
export const ARcontent = {
	title: "Creating Automation Tools",
	link: "automation-tools",
	content: [
		
		{
			id: 0,
			title: "Secrets",
			steps:[
                {
                    id:1,
                    title:`Log in to your Anydone account.`,
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon`
                },
                {
                    id:3,
                    title:`Navigate to <b>"Secrets"</b> and click on it.`
                },
                {
                    id:4,
                    title:`Click on the <b>"+"</b> button in the right-side corner.`
                },
                {
                    id:5,
                    title:`Give the secret a name and add a description.`
                },
                {
                    id:6,
                    title:`Add the <b>“API key”</b> under <b>"Value."</b>`
                },
                {
                    id:7,
                    title:`Select the <b>Scope</b> for your secret.`
                },
                {
                    id:8,
                    title:`Lastly, hit the <b>"Create"</b> button.`,
                    imageSource:`${imageUrl}creating-secret.gif`
                }
            ],
       Note:[
        {
            id:1,
            title:` Under Value, you need to add the API key of your preferred model. Currently, we only support OpenAI.`
        }
       ],

	
		},
        {
            id:1,
            title:"Authentication",
            onetitle:`The Authentication block enables you to verify users before they interact with your Flowcess. For example, if you've created a Flowcess using the <a href="anydone-creation?tabid=2">Knowledge Base</a> and <b>run it</b> through the <a href="anydone-app?tabid=1">ChatPlugin</a>, users will need to verify email to engage with your ChatPlugin.  <br><br>`,
            imageSource:`${imageUrl2}auth-block-flowcess.gif`,
            textcontent:`This is just an example you can use this block for any of your relevant business automation processes. `
        },
        {
            id:2,
            title:"Data Request",
            onetitle:`The Data Request block allows you to send forms and messages. It includes options for sending the form as a reply in a conversation or via email.<br><br>
<b>Form Selection:</b> You can select the <a href="/project-management/forms?tabid=0">form</a> that you want to get fulfilled by your users.<br><br>
<b>Send as Reply:</b> You can send the selected form as a reply. You can also create a custom message and attach the form, ensuring that the recipient receives both the predefined text and the relevant form when the action is triggered.<br><br>
<b>Send in Mail:</b> When choosing to send the form or message via email, you are prompted to select an email <a href="anydone-app?tabid=1">created in the app directory</a>. You then need to fill in key details such as the recipient’s email address, subject line, and message body. Additionally, there is an attachment section where you can upload files to be sent along with the email.<br><br>
In the video, we've built the Flowcess with <a href="anydone-creation?tabid=2">Knowledge Base</a>,<a href="automation-tools?tabid=1"> Authentication</a>, and Data Request blocks. Users must first authenticate with your Knowledge Base, and once done, they will receive an email at the address the user used to authenticate, along with attachments and forms.
`,
imageSourcee:`${imageUrl2}data-request-block-flowcess.gif`,
text1content:`However, you can use this block for any of your automation processes. This flexibility allows you to customize the automation process to suit your unique business needs.
`
        },
        {
            id:3,
            title:"Compare Block",
            onetitle:`Compare block makes logical decisions based on specific conditions. It works by comparing values from the Left-Hand Side (LHS) and Right-Hand Side (RHS). This is useful for automating processes that depend on certain conditions.
            <br><br>
<ul>
<li><b>Comparison Options</b>:You can choose from several comparison such as: <br>

<ul>
<li><b>Is Equal To</b>: Checks if the value on the LHS is equal to the value on the RHS.</li>
<li><b>Is Not Equal To</b>: Verifies if the LHS value differs from the RHS value.</li>

<li><b>Is Less Than</b>: Checks if the LHS value is less than the RHS value.</li>

<li><b>Is Less Than or Equal To</b>: Confirms if the LHS value is less than or equal to the RHS value.</li>

<li><b>Is Greater Than</b>: Verifies if the LHS value is greater than the RHS value.</li>
<li><b>Is Greater Than or Equal To</b>: Ensures the LHS value is greater than or equal to the RHS value.</li>

</ul>
</li>
</ul>
<br><br>
This block allows you to create automation based on comparison between two variables, whether they are numbers, dates, or other values.<br><br>

<ul>
<li><b>Define Output</b>:Once you define input, you can specify the actions to be taken based on the result for example:<br><br>

<ul>
<li><b>If True</b>: If the comparison evaluates as true (e.g., LHS is equal to RHS), you can define the next action in the automation process. 
</li>
<li>
<b>If False:</b> If the comparison evaluates as false (e.g., LHS is not equal to RHS), a different action can be triggered. </li>
</ul>
</li>
</ul><br><br>

By using the Compare Block in Flowcess, you can automate decision-making within your workflows, ensuring that specific actions are executed depending on whether conditions are met or not. <br><br>
In the video, we’ve built a Flowcess with Comparison Block using the anydone<a href="anydone-creation?tabid=2"> knowledge base</a>. When a user submits a query that isn’t available in the Knowledge Base, the Flowcess automatically triggers a <a href="anydone-creation?tabid=5">Text Generation model</a> to generate a response. This ensures that even when predefined information isn’t available, users still get responses from the <a href="anydone-creation?tabid=5">third party model</a> (text generation).<br><br> 
`,
imageSource:`${imageUrl2}compare-block-flowcess.gif`,
text1content:`Remember, this is just one example of how you can build a Flowcess with a Compare block. You could set up comparisons between different data points, and based on whether conditions are met, trigger specific actions, ensuring seamless automation across various operations.`
        },
        {
            id:4,
            title:"Contains Block",
            onetitle:`The Contains block in Flowcess can be used to check if specific data or values are present within a dataset or text field and trigger actions based on the result of that check. It allows you to filter and respond to incoming data based on whether certain keywords, numbers, or strings are contained within it.<br><br>
            <ul>
            <li><b>Define the Input Source</b>: First, you need to specify the source from which the data is coming. </li>
            <li><b>Checking for Specific Values</b>: After defining the input source, you need to set the condition by specifying the value or phrase to look for within the data. </li>
            <li><b>Define Output</b>:<br><ul>
            <li><b>If True</b>: If the specified value is found, you can trigger certain actions. This could include sending notifications, updating records, forwarding information to another team, and more.</li>
            <li><b>If False</b>: If the value is not found, you can define alternative actions. For instance, if a form submission doesn’t include a required keyword, it might trigger a follow-up request for more information.</li>
            </ul></li>
            </ul>
            `,
            imageSource:`${imageUrl2}contain-block-flowcess.gif`,
            text1content:`
            For example, we’ve used the <a href="anydone-creation?tabid=3">Knowledge Graph</a> to handle user queries. However, since the created Knowledge Graph has limitations therefore, it can’t respond to troubleshooting issues, we’ve used the Contains Block. <br><br>
When a user asks about a troubleshooting issue, the system detects this and responds with instructions to contact us directly. If the query is unrelated to troubleshooting, the user can continue interacting with the Knowledge Graph. <br><br>
This is just a basic example, you can use the Contains Block to build Flowcess for any type of automation you need.

            `
        },
        {
            id:5,
            title:"Counts Block",
            onetitle:`The Counts Block in Flowcess automates actions based on the number of items or occurrences within a defined data source. This block allows you to count specific values and trigger actions based on how the count compares to certain conditions.<br><br>
            
            <ul>
            <li><b>Define the Source</b>: You need to start by selecting the source to count from. Then, the Counts Block will tally the total number of instances according to the criteria you’ve set.</li>
            <li><b>Set the Condition</b>: After defining the source, you can compare the count using a variety of conditions:<br>
            <ul>
            <li><b>Is Greater Than</b>: Checks if the count exceeds a specified value.</li>
            <li><b>Is Less Than</b>: Confirms if the count is below a given value.</li>
            <li><b>Is Equal To</b>: Verifies if the count matches an exact number.</li>
            <li><b>Is Greater Than or Equal To</b>: Ensures the count is greater than or equal to a specific value.</li>
            <li><b>Is Less Than or Equal To</b>: Ensures the count is less than or equal to a given value</li>
            </ul>
            </li>
            </ul>
            These conditions allow you to tailor the automation based on the specific thresholds or limits you define, giving you control over when and how actions are triggered based on the count.<br><br>
            <b>Define Output</b>:<br>
            <ul>
            <li><b>If True</b>: If the condition is met, you can define actions for the next step.</li>
            <li><b>If False</b>: If the condition is not met you can set or define an alternative action. </li>
            </ul><br>

            `,
            imageSource:`${imageUrl2}count-block-flowcess.gif`,
            text1content:`Here we've used the <a href="anydone-creation-process?tabid=8">Face Detection</a> to show how to use the Counts block in Flowcess. Nevertheless, you can use this block to automate tasks based on volume,  track order volumes, monitoring form submissions, or more. `
        },
        {
            id:6,
            title:"Check Block",
            onetitle:`The Check Block in Flowcess can be used to evaluate specific conditions or criteria and determine whether to continue based on whether the condition is met or not. <br><br>
            <ul>
            <li><b>Define the Condition</b>: The Check Block allows you to build an automation process on the following conditions:<br>
            <ul>
            <li>
            <b>Is Equal To</b>: Verifies if a specific value matches the predefined condition.
            </li>
            <li><b>Is Not Equal To</b>: Ensures that a value does not match the defined condition.</li>
            <li><b>Is Greater Than</b>: Confirms that a value exceeds a certain number.</li>
            <li><b>Is Less Than</b>: Checks if a value is below a specified threshold.</li>
            <li><b>Is Greater Than or Equal To</b>: Ensures the value is greater than or equal to the given condition.</li>
            <li><b>Is Less Than or Equal To</b>: Verifies if a value is less than or equal to the condition.</li>
            </ul>
            </li>
            <li>
            <b>Define Output</b>:<br>
            <ul>
           <li> <b>If True</b>: If the condition is met, the  workflow continues to the next set of actions, or trigger another process.</li>
<li><b>If false</b>: If the condition is not met you can reroute the process for further action.</li>
            </ul>
            </li>
            </ul>
            `,
            imageSource:`${imageUrl2}check-block-flowcess.gif`,
            text1content:`Here, we've used the <a href="anydone-creation-process?tabid=8">Face Detection</a> process to demonstrate how to use the Check Block in Flowcess. However, you can apply this block to automate decision-making processes, such as validating input data, checking order statuses, or monitoring specific conditions in real-time, etc. `
        },
        {
            id:7,
            title:"Send Mail",
            onetitle:`Send mail block in Flowcess automates the process of sending emails, it allows you to send customized emails at specific points in your automation, based on predefined condition.<br>
            <ul>
            <li><b>Mail Template</b>: You can  select an email template such as Text and HTML.</li> 
<li><b>Email Content</b>: The block allows you to write a personalized message. You can also embed content within the email, such as user names, order details, or other relevant data, ensuring each email is tailored to the recipient.</li>
<li><b>Attachments</b>: You can add documents, forms, or any other files that need to be included in the email.</li>
<li><b>Triggering Conditions</b>: You can define the conditions or triggers that activate the send mail block within the Flowcess, ensuring that emails are sent automatically and in real-time based on actions within the workflow.</li>
<li><b>Handling Outcomes</b>: Once the email is sent, you can define what happens next. For instance, if the email is successfully sent, the Flowcess can proceed to the next step, such as logging the email or notifying a team member, etc.</li>
            </ul>
            Before using the Send Mail block in Flowcess, you first need to <a href="anydone-app?tabid=4">create an email</a> from the app directory. Once this is ready, you can integrate it into your Send Mail block within Flowcess.<br><br>
            <button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;">See how it works</button>


            `
        },
        {
            id:8,
            title:"Save Data Block",
            onetitle:`The Save Data block in Flowcess is designed to collect and store user data and information:<br><br>
            <ul>
            <li><b>Select the Form</b>: You need to select the form you want to use for data collection. This form is predefined and contains various fields that need to be filled out by users.</li>
<li><b>Define Form Field Values</b>: Specify the values for each field in the form.</li> 
<li><b>User Interaction</b>: Users will answer each question one by one.</li> 
<li><b>Save Responses</b>: As users provide their answers, the data is saved in real-time. Responses are stored in the form's designated folder within the project.</li> 
<li><b>Easy Data Access</b>: After the data has been saved, you can access it from the project folder where the form was created.  </li>
            </ul>`
        },
        {
            id:9,
            title:"Template and File",
            onetitle:`For the <b>Template</b> and <b>File</b> blocks, let's understand it with an example.<br>In the below Flowcess, you can use the OCR Block to generate raw text from scanned documents and images. Then, you can use the Template Block to format this raw text into a structured template.<br><br> Finally, the File Block allows you to choose the file type (e.g., PDF, CSV, JSON, and more) to save or view the response.<br><br>`,
            imageSource:`${imageUrl2}template-file-block.gif`

        }

       
	],
};

