export const GSContents = [
  {
    id: 1,
    title: "Using anydone",
    link: "using-anydone",
    listing: [
      {
        id: "0",
        title: "Registering New User",
      },
      {
        id: "1",
        title: "Download anydone",
      },
      {
        id: "2",
        title: "Login to Another anydone Workspace",
      },
    ],
  },

  {
    id: 4,
    title: "Inviting and Managing Members",
    link: "inviting-and-managing-members",
    listing: [
      {
        id: "0",
        title: "Inviting Member",
      },
      {
        id: "1",
        title: "Deactivate Member ",
      },
      {
        id: "2",
        title: "Change Members Role",
      },
      {
        id: "3",
        title: "Reset Member Password",
      },
    ],
  },
  {
    id: 5,
    title: "Inviting and Managing Externals",
    link: "inviting-and-managing-externals",
    listing: [
      {
        id: "0",
        title: "Inviting Externals",
      },
      {
        id: "1",
        title: "Convert External To Member",
      },
      {
        id: "2",
        title: "Edit External Detail",
      },
      {
        id: "3",
        title: "Deactivate External",
      },
    ],
  },
  {
    id: 6,
    title: "Inviting and Managing Company",
    link: "inviting-and-managing-company",
    listing: [
      {
        id: "0",
        title: "Inviting Company",
      },
      {
        id: "1",
        title: "Editing Company Details and Information",
      },
    ],
  },
  {
    id: 7,
    title: "Profile Preferences",
    link: "profile-preferences",
    listing: [
      {
        id: "0",
        title: "Change Language and Timezone",
      },
      {
        id: "1",
        title: "Change Appearance",
      },
      {
        id: "2",
        title: "Notification Settings",
      },
      {
        id: "3",
        title: "Change and Set Weekly working hours",
      },
      // more...
    ],
  },
  {
    id: 8,
    title: "Manage Profile",
    link: "manage-profile",
    listing: [
      {
        id: "0",
        title: "Edit your profile",
      },
      {
        id: "1",
        title: "Upload a profile photo",
      },
      {
        id: "2",
        title: "Set your status and availability",
      },
      {
        id: "3",
        title: "Set your Status and availability for a specific time",
      },
      {
        id: "4",
        title: "Change your display name",
      },
      {
        id: "5",
        title: "Set Holidays",
      },
      {
        id: "6",
        title: "Set Workdays",
      },
    ],
  },
  {
    id: 9,
    title: "Manage Notification",
    link: "manage-notification",
    listing: [
      {
        id: "0",
        title: "How to View Notifications?",
      },
      {
        id: "1",
        title: "How to Filter Notifications?",
      },
      {
        id: "2",
        title: "Pause Notification",
      },
    ],
  },
  {
    id: 10,
    title: "Manage Account",
    link: "manage-account",
    listing: [
      {
        id: "0",
        title: "Forget password",
      },
      {
        id: 1,
        title: "Change Password",
      },
      {
        id: 2,
        title: "Set up two-factor authentication",
      },
      {
        id: 3,
        title: "Sign out of anydone account",
      },
    ],
  },

  {
    id: 12,
    title: "Workspace Settings",
    link: "workspace-setting",
    listing: [
      {
        id: "0",
        title: "Creating new Workspace",
      },
      {
        id: "1",
        title: "Edit Workspace Details",
      },
      {
        id: "2",
        title: "Delete Workspace",
      },
      {
        id: "3",
        title: "Archive Workspace",
      },
      {
        id: "4",
        title: "Unarchive Workspace",
      },
    ],
  },
  {
    id: 11,
    title: "Setting And Preferences",
    link: "setting-and-preferences",
    listing: [
      {
        id: "0",
        title: "General Settings",
      },
      {
        id: "1",
        title: "Account Settings ",
      },
      {
        id: "2",
        title: "Message Setting",
      },
      // {
      //   id: "3",
      //   title: "Meeting Setting",
      // },
      // more
    ],
  },
];
