import React from "react";

const BorderDiv = (props) => {
	const { mb, mt } = props;
	const divstyles = {
		width: "100%",
		height: "0px",
		border: "1px solid #D9D9D9",
		marginTop: `${mt}rem`,
		marginBottom: `${mb}rem`,
	};
	return <div style={divstyles}></div>;
};

export default BorderDiv;
