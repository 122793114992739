import React from "react";
import { Divider } from "antd";
import { HeadingText } from "../../../styleComponents";
import QuestionCard from "../../common/QuestionCard";

// import { FAQContents } from "./FAQContents";
function LayoutFaq(props) {
  // console.log(props.value);
  const { title, QuestionAnswers } = props.value;

  // const { title, QuestionAnswers } = FAQContents[0];

  return (
    <div>
      <HeadingText>{title && title}</HeadingText>
      <Divider dashed className="dividers" />

      <div className="frequent-list">
        <QuestionCard QuestionAnswers={QuestionAnswers} />
      </div>
    </div>
  );
}

export default LayoutFaq;
