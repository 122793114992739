import plus from "./../../../../../new/icons/plus.png";
import down from "./../../../../../new/icons/down.svg";
import iicon from "./../../../../../new/icons/i.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/getting-started/";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MngAcontent = {
  title: "Manage Account",
  link: "manage-account",
  content: [
    {
      id: 0,
      title: "Forget password",
      steps: [
        {
          id: 1,
          title: `Visit the <a href=${`https://app.anydone.com/`}>login page</a> of  <a href=${`https://anydone.com`}> anydone</a> and click on the <b>Forget Password</b> button.`,
          imageSource: `${GifBaseUrl}login-page-anydone.png`,
        },
        {
          id: 2,
          title: `New interface will appear on your screen, here enter your email address.`,
        },
        {
          id: 3,
          title: `Click on the <b>Send Reset Code</b> button.`,
          imageSource: `${GifBaseUrl}resent-code-anydone.png`,
        },
        {
          id: 4,
          title: `Again, the new interface will appear on your screen. Enter the code you received in your email inbox, and then create a new password.`,
        },
        {
          id: 5,
          title: `Click on the <b>Reset</b> button.`,
          imageSource: `${GifBaseUrl}password-reset-anydone.png`,
        },
        {
          id: 6,
          title: `Now, you will be able to login to your account with a new password.`,
        },
      ],
    },
    {
      id: 1,
      title: "Change Password",
      steps: [
        {
          id: 1,
          title: `Go   to your dashboard, and click on your <b>profile icon</b> from the top right corner.`,
        },
        {
          id: 2,
          title: `Click on your name from the given option.`,
        },
        {
          id: 3,
          title: `Click on the <b>Change Password</b> from the left side.`,
        },
        {
          id: 4,
          title: `New prompt will appear on your screen here; enter your <b>old password</b> and then enter the <b>new password</b>.`,
        },
        {
          id: 5,
          title: `Then click on the <b>Change</b> button.`,
          imageSource: `${GifBaseUrl}change-password.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Set up two-factor authentication",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on your <b> profile icon</b> from  the top right corner.`,
        },
        {
          id: 2,
          title: `Click on the <b> Preferences </b>.`,
        },
        {
          id: 3,
          title: `Click on the <b>Privacy and Security</b>.`,
        },
        {
          id: 4,
          title: `Now press the toggle button to enable two-factor authentication.`,
        },
        {
          id: 5,
          title: `Now, click on the <b>Save</b> button`,
          imageSource: `${GifBaseUrl}two-factor-authentication.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Sign out of anydone account",
      steps: [
        {
          id: 1,
          title: ` Login to your dashboard.`,
        },
        {
          id: 2,
          title: `Click on your profile icon from the top right corner.`,
        },
        {
          id: 3,
          title: `Click on the <b>Logout from this account</b> option .`,
        },
        {
          id: 4,
          title: `Click on the <b>Logout</b> button.`,
          imageSource: `${GifBaseUrl}sign-out.gif`,
        },
      ],
    },
  ],
};
