import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CAMTcontent = {
  title: "Ticket Reports",
  link: "ticket-report",
  content: [
    {
      id: 0,
      title: "View Ticket Reports",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left side.`,
        },
        {
          id: 2,
          title:
            "Click on the arrow button facing downward, located to the right of the Project.",
        },
        {
          id: 3,
          title: "Click on the <b>Ticket Reports</b> option.",
        },
      ],
      text1content: `Here you can see every single details of every tickets created in your workspace
      You can see its status, members, you can view sprint, sprint process time, ticket process time, completed sprints and more. Also, you can export tickets as well.
      `,
      text1imageSource: `${GifeBaseUrl}ticket-dashboard.gif`,
    },
  ],
};
