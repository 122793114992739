import plus from "./../../../../../new/icons/plus.png";
import down from "./../../../../../new/icons/down.svg";
import send from "./../../../../../new/icons/send.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/messages-and-calls/";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MMcontent = {
  title: "Manage Messages",
  link: "manage-messages",
  content: [
    {
      id: 0,
      title: "Create and Save Notes",
      steps: [
        {
          id: 1,
          title: ` Navigate to the <b>"Direct Messages"</b> section.`,
        },
        {
          id: 2,
          title: `Click on the tab labeled with your own name [Your Name] (You).`,
        },
        {
          id: 3,
          title: ` Now, start creating notes for future reference.`,
          // imageSource: `${GifBaseUrl}`,
        },
      ],

      Note: [
        {
          id: 1,
          title:
            "You can hover over any message you sent here and choose Forward, Reply, or React.",
        },
      ],

      // add_steps: [
      //   {
      //     id: 1,
      //     title: `Go to the <b>Direct Messages</b> section.`,
      //   },
      //   {
      //     id: 2,
      //     title: "Hover over the thread that you want to move.",
      //   },
      //   {
      //     id: 3,
      //     title: `Click on the button indicated by <b>three horizontally aligned dots</b>.`,
      //   },
      //   {
      //     id: 4,
      //     title: `Click on the <b>Move Thread</b> option.`,
      //   },
      //   {
      //     id: 5,
      //     title: `Click on the <b>“Favorite' tab”</b> to move the thread to the favorite section.`,
      //   },
      //   {
      //     id: 6,
      //     title: `Or, select <img src=${plus} alt="plus" height="12" width="12"/> New Section option & give a name to it.`,
      //   },

      //   {
      //     id: 7,
      //     title: "Then hit the <b>Create</b> button.",
      //     imageSource: `${GifBaseUrl}new-section.gif`,
      //   },
      // ],
    },
    {
      id: 1,
      title: "Create New Section",

      steps: [
        {
          id: 1,
          title: ` Go to the <b>Direct Messages</b> section.`,
        },
        {
          id: 2,
          title: `Hover over the thread that you want to move.`,
        },
        {
          id: 3,
          title: `Click on the button indicated by <b>three horizontally aligned dots</b>.`,
        },
        {
          id: 4,
          title: ` Click on the <b>Move Thread</b> option.`,
        },
        {
          id: 5,
          title: `Click on the <b>"Favorite tab"</b> to move the thread to the favorite section.`,
        },
        {
          id: 6,
          title: `Or, select <img src=${plus} alt="plus" height="12" width="12"/> New Section option & give a name to it.`,
        },
        {
          id: 7,
          title: `Then hit <b>Create</b> button.`,
          imageSource: `${GifBaseUrl}new-section.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Schedule Message",
      onetitle: "Schedule Message",
      steps: [
        {
          id: 1,
          title: ` Type a message in a text editor for an individual, multiple participants, or a group.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${down} alt="down-icon" height="24" width="24"/>
					button alongside the message send button.`,
        },
        {
          id: 3,
          title: `Set a date and time to schedule the message from the default options.`,
        },
        {
          id: 4,
          title: `Or click on the <b>Custom</b> option to set up the date and time manually.`,
        },
        {
          id: 5,
          title: `A prompt will appear to set a desired date and time.`,
        },
        {
          id: 6,
          title: `Then, add the desired time and date and click on the <b>Apply</b> button.`,
        },
        {
          id: 7,
          title: `Now, click on the <b>Schedule</b> button.`,
          imageSource: `${GifBaseUrl}schedule-message.gif`,
        },
      ],
      add_title_bold: "View Schedule Message",
      add_steps: [
        {
          id: 1,
          title: ` Go to the message thread from the individual, multiple participants, or group where you scheduled a message.`,
        },
        {
          id: 2,
          title: `Click on the <b>View</b> option, then the scheduled message will be shown in a separate segment on the right side of the screen.`,
        },
        {
          id: 3,
          title: `Hover over the scheduled message, click on the <img src=${send} alt="sendicon" height="12" width="12" style="margin-left: 5px; margin-right:5px"/> button then you will see the options like <b>edit</b> message, <b>delete</b>, <b>send immediately</b>, and r<b>eschedule</b>.`,
          imageSource: `${GifBaseUrl}message-scheduling.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Pin and Unpin Message",
      onetitle: "Pin Message ",
      steps: [
        {
          id: 1,
          title:
            "Go to the message segment, Direct Messages (DM), DM with Multiple Participants, or Group conversation.",
        },
        {
          id: 2,
          title: "Click on the message thread that you want to work on.",
        },
        {
          id: 3,
          title: `Hover over the message you want to <b>pin</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>3 vertical dots option</b>.`,
        },
        {
          id: 5,
          title: ` Click on the <b>Pin</b> option.`,
        },
        {
          id: 6,
          title: `Pinned message will now appear on the top section of the active thread indicated by a push pin 📌.`,
          imageSource: `${GifBaseUrl}pin-message.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title: "Multiple messages can be pinned within a single thread.",
        },
      ],
      add_title_bold: "Unpin Message",
      add_steps: [
        {
          id: 1,
          title: `Click on the <b>pinned messages</b> icon indicated by a push pin 📌 at the top right corner of the message thread.`,
        },
        {
          id: 2,
          title:
            "A separate segment will appear on the right side of the screen.",
        },
        {
          id: 3,
          title:
            "Hover over the message, and Unpin option will appear on the right side.",
        },
        {
          id: 4,
          title: `Click on the <b>Unpin</b> button.`,
          imageSource: `${GifBaseUrl}unpin-message.gif`,
        },
      ],
      one_more_title: "Alternatively",
      one_more_steps: [
        {
          id: 1,
          title: "Hover over the pinned message that needs to be unpinned.",
        },
        {
          id: 2,
          title: `Click on the <b>Option</b> menu.`,
        },
        {
          id: 3,
          title: ` Click on the <b>Unpin</b> option.`,
          imageSource: `${GifBaseUrl}unpin-message-view-alternative.gif`,
        },
      ],
    },
  ],
};
