import { useEffect, useState } from "react";
import { BaseAPI } from "../../globalConstants";
import LocalDb from "../../localStorage";
import TokenHandler from "../../token";
import { transformResponse } from "./userSesssionDTO";

const useSessionFromCookie = () => {
    const [loading, setLoading] = useState(false);
    const [userSession, setUserSession] = useState(null);

    const [fetchingSession, setFetchingSession] = useState(true);

    const cookieObject = getCookies();
    console.log("ABCD coooo", cookieObject);
    const keysCookieObject = Object.keys(cookieObject);
    let selectedTokenKey = keysCookieObject.find((i) =>
        i.includes("_selected")
    );
    if (selectedTokenKey === undefined) {
        selectedTokenKey = keysCookieObject[0];
    }

    useEffect(() => {
        async function fetchSession(token) {
            console.log("fetch session with token", token);
            setLoading(true);
            var raw = JSON.stringify({
                stringValues: [token],
            });
            try {
                const data = await fetch(`${BaseAPI}v1/sessions`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "X-Anydone-Product": "ANYDONE_INBOX",
                        "Content-Type": "application/json",
                    },
                    body: raw,
                });
                const json = await data.json();
                if (json.success === false) {
                    console.log("Session expired. Please login again.");
                    // window.open(
                    // 	`https://app.anydone.com?redirectUrl=https://help.anydone.com`,
                    // 	"_self"
                    // );
                } else if (json.success === true) {
                    console.log(json.userSessionDetails, "KKKKKKKK");
                    if (!json.userSessionDetails) {
                        console.log("Session expired. Please login again.");
                        // setTimeout(() => {
                        // 	window.open(
                        // 		`https://app.anydone.com?isSessionExpired=true&redirectUrl=https://help.anydone.com`,
                        // 		"_self"
                        // 	);
                        // }, 3000);
                    }
                    const sessionCurrent = transformResponse(
                        json.userSessionDetails[0],
                        token
                    );
                    setUserSession(sessionCurrent);
                    LocalDb.setSession(sessionCurrent, () => {});
                    setLoading(false);
                    TokenHandler.setToken();
                } else {
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
            setFetchingSession(false);
        }
        if (cookieObject) {
            if (
                isEmptyStringObject(cookieObject) ||
                Object.keys(cookieObject).length === 0
            ) {
                //do something when there is no cookie
                // window.open(
                // `https://app.anydone.com?redirectUrl=https://help.anydone.com`,
                // 	"_self"
                // );
            } else {
                try {
                    fetchSession(cookieObject[selectedTokenKey]);
                } catch (error) {
                    console.log(error);
                    setFetchingSession(false);
                    setLoading(false);
                }
            }
        }
    }, []);
    return { loading, fetchingSession, userSession };
};

const getCookies = () => {
    const cookies = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const pair of document.cookie.split(";")) {
        const [key, ...value] = pair.split("=");
        const keyValue = value.join("=");
        cookies[key.trim()] = decodeURIComponent(keyValue);
    }
    return cookies;
};

function isEmptyStringObject(obj) {
    return (
        Object.keys(obj).length === 1 &&
        obj.hasOwnProperty("") &&
        obj[""] === ""
    );
}

export default useSessionFromCookie;
