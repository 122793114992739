import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const AAcontent = {
  title: "Auto Assign",
  link: "auto-assign",
  content: [
    {
      id: 0,
      title: "Auto Assign",
      steps: [
        {
          id: 1,
          title: ` Go to your dashboard.`,
        },
        {
          id: 2,
          title: ` Click on your workspace icon in the top left corner.`,
        },
        {
          id: 3,
          title: `Select <b>"Workspace Settings."</b>`,
        },
        {
          id: 4,
          title: `Navigate to <b>"Administration"</b> > <b>"Settings & Permissions."</b>`,
          // `Click on the <img src=${plus} alt="task"  height="14" width:"14"/> button from the right side corner.`,
        },
        {
          id: 5,
          title: `Click on <b>"Projects."</b>`,
        },

        {
          id: 6,
          title: `Press the toggle button to enable the <b>"Auto Assign Ticket"</b> option.`,
        },
        {
          id: 6,
          title: `Finally, click the <b>"Save"</b> button.`,
          imageSource: `${GifeBaseUrl}ticket-auto-assign-anydone.gif`,
        },
      ],
      Note: [
        {
          title:
            "Our AI-powered system will then automatically assign tickets to project members who have no pending tasks and are available.",
        },
      ],
    },
  ],
};
