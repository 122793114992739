import AppDirectory from "../../../../../new/icons/appdirec.svg";
import Project from "../../../../../new/icons/project.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"

// import Automation from "../../../../../images/help-page-icons/automation.svg"

export const ABcontent = {
	title: "Create App Directory",
	link: "anydone-creation",
	content: [
		
		{
			id: 0,
			title: "Anydone",
			onetitle:`<b><u>Creating Anydone (Workspace) app</u></b><br><br> 
Creating an anydone app requires you to first <a>create a CoSpace</a>. Once you've created it, follow the steps.<br><br> Alternatively, you can integrate your Flowcess into an existing CoSpace within your anydone workspace also.
`,
			steps: [
				{
					id: 1,
					title: `Go to your dashboard.`,
				},
				{
					id: 2,
					title: `Navigate to the <img src=${AppDirectory} alt="app directory" height="12" width="12"/>  icon and click on it.`,
				},
				{
					id: 3,
					title: `Select <b>"Anydone."</b>`,
				},
				{
					id: 4,
					title: `Click on the <b>"+"</b> button in the top right corner.`,
				},
				{
					id: 5,
					title: `Enter a name.`,
				},
				{
					id: 6,
					title: `Select a type (<b>CoSpace</b>).`,
				},
				{
					id: 7,
					title: `Choose your preferred CoSpace.`,
				},
				{
					id: 8,
					title: `Finally, click the <b>"Create"</b> button.<br>`,
					imageSource: `${imageUrl}app-directory-anydone.gif`


				},
				
				
			],
			add_title:"<b><u>Creating Anydone (Form) app</u></b> <br><br> If you want to create an anydone(form) app, make sure you have already <a>created a form folder</a> and, within that folder, also <a>created a form</a>.",
			add_steps:[
				{
					id:1,
					title:`Log in to anydone.`
				},
				{
					id:2,
					title:`Click on the <img src=${AppDirectory} alt="app directory" height="12" width="12"/> like icon.`
				},
				{
					id:3,
					title:`Click on <b>Anydone</b>.`
				},
				{
					id:4,
					title:`Enter a name for the app`
				},
				{
					id:5,
					title:`Under <b>'Type,'</b> select 'Form.'`
				},
				{
					id:6,
					title:`Select the <b>Project.</b>`
				},
				{
					id:7,
					title:`Select the <b>Folder.</b>`
				},
				{
					id:8,
					title:`Now, select the <b>Form.</b>`
				},
				{
					id:9,
					title:`Lastly, click the <b>'Create'</b> button.`,
					imageSource: `${imageUrl}app-directory-form.gif`


				},
			]

		},
		{
			id: 1,
			title: "Chat Plugin",
			onetitle:"<b><u>Creating Chat plugin app</u></b> ",
			steps: [

{
	id:1,
	title:`Log in to your anydone.`
},
{
	id:2,
	title:`Click on the <b><img src=${AppDirectory} alt="app directory" height="12" width="12"/> </b>icon on the left-hand side.`
},
{
	id:3,
	title:`Select<b> Chat Plugin</b>.`
},
{
	id:4,
	title:`Click on the <b>"+" button </b>in the right-side corner.`
},
{
	id:5,
	title:`Give the chat plugin a <b>name</b>.`
},
{
	id:6,
	title:`<b>Customize</b> it according to your preference.`
},
{
	id:7,
	title:`Click on the <b>"Next"</b> button.
`
},
{
	id:8,
	title:`Select the <b>Data Attributes</b> you want to capture.`
},
{
	id:9,
	title:`Lastly, hit the <b>"Create"</b> button.<br><br><b>Customization options:</b><br> If you click on the <b>Tabs >> Home</b> and then click on Settings, you can customize the following:<br><br>There are two things you can customize: <b>Browse Popular Topics</b> and <b>Blog</b>. For both, you can:<br><br>• Add a topic and enter the corresponding URL and url of your blog that you want to &nbsp&nbsp&nbspdisplay.<br>• You can add multiple topics, and blogs for each of them.
	`,
	imageSource: `${imageUrl}customize-home-tab.gif`
	



},

				
			],


	add_title:`Similarly, you can customize the chat plugin in various ways, such as changing the action color, background color, message, and more.<br><br>`,
imageSourcee: `${imageUrl}customize-chatplugin.gif`,
add_titlee:`<br>Once you’re done customizing the chat plugin, click on the Next button.
Now, define the Data Attributes you want to collect from the users who interact with your chat plugin.`,

imageSourceee: `${imageUrl}specified-attribute-chatplugin.png`,

one_more_subtitle:`Now, once users interact with the chat plugin you can view the attributes by following the below steps:
`,
	one_more_steps:[
		{
			id:1,
			title:`Log in to your <b>anydone</b> dashboard.`
		},
		{
			id:2,
			title:`Click on the <b><img src=${Project} alt="project" height="12" width="12"/></b> icon.`
		},
		{
			id:3,
			title:`Navigate to the project where you’ve created your Flowcess.`
		},
		{
			id:4,
			title:`Click on the <b>Flowcess</b> folder.`
		},
		{
			id:5,
			title:`Hover over the Flowcess whose user attributes you want to view.`
		},
		{
			id:6,
			title:`Click on the <b>three vertical dots</b>`
		},
		{
			id:7,
			title:`Select <b>View user information</b><br>`,
			imageSource: `${imageUrl}view-user-information-chatplugin.png`


		},
	],
	text3content:`Here, you can view your user information based on you selected attributes<br>`,
	imageSource3: `${imageUrl}user-information-chatplugin.png`,
	text4content:`Note: User name and email address only can be fetched if you’ve added an <a>Authentication</a> block while <a>creating the Flowcess.</a> `



	


		},
		{
			id: 2,
			title: "Live Capture",
			onetitle:"<b><u>Creating Live Capture app</u></b> ",
			steps: [
				{
					id: 1,
					title: `Log in to your anydone dashboard.`
				},
				{
					id: 2,
					title: `Click on the <b><img src=${AppDirectory} alt="app directory" height="12" width="12"/></b> icon on the left side.`
				},
				{
					id: 3,
					title: `Select <b>"Live Capture."</b>`
				},
				{
					id: 4,
					title: `Enter a name for the app.`
				},
				{
					id: 5,
					title: `Customize the Live Capture app according to your preferences.`
				},
				{
					id: 6,
					title: `Click the <b>"Create"</b>	 button.<br>`,
		          	imageSource: `${imageUrl}live-capture-app.gif`,

				},

				
			],


			add_title:"<b>Customizing Live Capture App</b><br>Watch the video to customize your live capture app according to your needs.<br><br> ",
			imageSourcee: `${imageUrl}live-capture-app-customization.gif`,


			Add_Note: [
				{
				  id: 1,
				  title:
					"It's not necessary to create a Live Capture app with all three options (image, video, audio). You can choose one, two, or all of them, depending on what you want to capture.",
				},
			  ],
		},
		{
			id: 3,
			title: "Webhook",
			onetitle:"<b><u>Creating Webhook app</u></b>",
			steps: [
				{
					id: 1,
					title: `Log in to your anydone dashboard.`
				},
				{
					id: 2,
					title: `Click on the <b><img src=${AppDirectory} alt="app directory" height="12" width="12"/></b> icon on the left-hand side.`
				},
				{
					id: 3,
					title: `Click on the <b>"Webhook"</b> option.`
				},
				{
					id: 4,
					title: `Enter a name.`
				},
				{
					id: 5,
					title: `Finally, click the <b>"Create"</b> button.`,
					imageSource: `${imageUrl}anydone-webhook-app.gif`


				},

				
			],
		},
		{
			id: 4,
			title: "Mail",
			onetitle:"<b><u>Creating mail app(Gmail)</u></b>",

			steps: [
				{
					id: 1,
					title: `Log in to your anydone dashboard.`
				},
				{
					id: 2,
					title: `Click on the <img src=${AppDirectory} alt="app directory" height="12" width="12"/> icon.`
				},
				{
					id: 3,
					title: `Click on the <b>"Mail"</b> option.`
				},
				{
					id: 4,
					title: `Enter a name for the app.`
				},
				{
					id: 5,
					title: `Under <b>"Email Provider,"</b> select <b>"Gmail."</b>`
				},
				{
					id: 6,
					title: `Enter your email address.`
				},
				{
					id: 7,
					title: `Enter a <b>Password </b><i>(Recommended: do not enter your actual password, generate the password from google)</i>.`
				},
				{
					id: 8,
					title: `Under <b>"SMTP Configuration,"</b> define the settings according to your preference.`
				},
				{
					id: 9,
					title: `Click the <b>"Create"</b> button.`,
					imageSource: `${imageUrl}mail-gmail-app-directiory.gif`


				},

				
			],
			add_title:"<b>Generate a password from Google by following the below steps:</b>",
			add_steps:[
				{
					id:1,
					title:`Go to your gmail <b>account setting</b> and above the search placeholder type <b>“App Password”</b>.`,
					imageSource: `${imageUrl}gmail-generating-password-first.png`


				},
				{
					id:2,
					title:`Then, type the platform name for example <b>“anydone”</b> and click the <b>“Create”</b> button.`,
					imageSource: `${imageUrl}app-directory-gmail-second.png`


				},
				{
					id:3,
					title:`Now, Google will generate the password. Copy the password and enter in the password placeholder. `,
					imageSource: `${imageUrl}app-directory-gmail-generated-password-third.png`


				}
			],
			Add_Note: [
				{
				  id: 1,
				  title:
					" Before generating an app password in Google, make sure that you've activated two-factor authentication (2FA) on your Gmail account.",
				},
			  ],
			  one_more_title:"<b><u>Creating mail app(SMTP)</u></b>",
			  one_more_steps:[
				{
					id:1,
					title:`Log in to your anydone dashboard.`
				},
				{
					id:2,
					title:`Click on the <img src=${AppDirectory} alt="app directory" height="12" width="12"/> icon.`
				},
				{
					id:3,
					title:`Click on the <b>"Mail"</b> option.`
				},
				{
					id:4,
					title:`Enter a name for the app.`
				},
				{
					id:5,
					title:`Under <b>"Email Provider,"</b> select <b>"SMTP."</b>`
				},
				{
					id:6,
					title:`Enter your email address.
`
				},
				{
					id:7,
					title:`Enter a <b>Password</b> <i>(Recommended: do not enter your actual password)</i>.`
				},
				{
					id:8,
					title:`Under <b>"SMTP Configuration,"</b> define the settings according to your preference.`
				},
				{
					id:9,
					title:`Click the <b>"Create"</b> button.<br>`,
					imageSource: `${imageUrl}mail-smpt-app-directiory.gif`


				},
			  ],
			  one_More_Note:[
				{
					id: 1,
				  title:
					"Regardless of which email service you use, we recommend that you do not enter your actual password. Generate app password based on your email service, and enter that generated password here.",
				
				}	

			  ]
		},
		{
			id: 5,
			title: "Slack",
			onetitle:"<b><u>Creating Slack app</u></b> ",
			steps: [
				{
					id: 1,
					title: `Log in to your Anydone dashboard.`
				},
				{
					id: 1,
					title: `Click on the <b><img src=${AppDirectory} alt="app directory" height="12" width="12"/>	</b> icon.`
				},
				{
					id: 1,
					title: `Select <b>"Slack"</b> from the listed options.`
				},
				{
					id: 1,
					title: `Click the <b>"+"</b>	 button in the top right corner.`
				},
				{
					id: 1,
					title: `Enter a name for the app.`
				},
				{
					id: 1,
					title: `Add the <b>Signing Secret</b> and <b>User Token.</b>`
				},
				{
					id: 1,
					title: `Finally, click the <b>"Create"</b> button.`,
					imageSource: `${imageUrl}slack-app-directory.gif`


				},

				
			],
			Note:[
				{
					id: 1,
				  title:
					"To generate the User Token and Secret Key, follow the setup guide that is accessible during the app creation process.",
				
				}	

			  ]
		},
		

	],
};

