export const ATCcontent = {
	title: "Auto Ticket Create",
	link: "auto-ticket-create",
	content: [
		{
			id: 0,
			title: "Setting up auto ticket create bot",
			steps: [
				{
					id: 1,
					title: "Go to your dashboard and click on your workspace.",
				},
				{
					id: 2,
					title: "Click on the Automation option.",
				},
				{
					id: 3,
					title: "Click on Auto Ticket Create.",
				},
				{
					id: 4,
					title: "Click on the + button.",
				},
				{
					id: 5,
					title: "Give it a name.",
				},
				{
					id: 6,
					title:
						"Under Custom Rules, add all the possible text on which you want a bot to create a ticket.",
				},
				{
					id: 7,
					title: "Click on the Create button.",
				},
			],
			text1content: `Remember that, the ability to create an auto ticket is exclusive to the owner and admin. You can create multiple fixed reply bots, and once created, you have the flexibility to enable, disable, edit, or delete them as needed. 
				<br/>
			One more thing, after creating an auto ticket create, you are required to create a bot and add it to your desired group.
			`,
		},
		{
			id: 1,
			title: "Auto Ticket Creation",
			onedescription:
				"First, you need to enable the Auto Assign Ticket option.",
			steps: [
				{
					id: 1,
					title: "From your anydone dashboard, click on the workspace icon.",
				},
				{
					id: 2,
					title: "Select Workspace Settings.",
				},
				{
					id: 3,
					title: "Under Administration, navigate to Settings & Permissions.",
				},
				{
					id: 4,
					title: "Click on the Ticket option.",
				},
				{
					id: 5,
					title:
						"From the right side, navigate to Auto Assign Ticket, and click on the toggle button to enable it.",
				},
				{
					id: 6,
					title: "Click on the Save button.",
				},
			],
			text1content: `<b><i>Once you enable the auto-assign ticket option, our system will automatically assign the tickets to the members of the project who have no task pending and are free.</i></b> `,
		},
	],
};
