import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Cuscontent = {
  title: "Custom Field",
  link: "custom-field",
  content: [
    {
      id: 0,
      title: "Create Custom Fields",
      steps: [
        {
          id: 1,
          title: `Login to you dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 3,
          title: "Navigate to the project and its ticket folder.",
        },
        {
          id: 4,
          title: `Navigate to the project and click on the ticket folder where you want to customize a field.`,
        },
        {
          id: 5,
          title: "From the top right, click on the <b>Settings</b> option.",
        },

        {
          id: 6,
          title: "Firstly, you'll see all the default fields of the ticket.",
        },
        {
          id: 7,
          title: `Click on the <img src=${plus} alt="plus"  height="14" width:"14"/> button in the right corner.`,
        },
        {
          id: 8,
          title: `Give a name and description to the field.`,
        },
        {
          id: 9,
          title: "Select the <b>Field type</b> from the drop-down menu.",
        },

        {
          id: 10,
          title: "Click the <b>Create</b> button.",
        },
        {
          id: 11,
          title: `Select the <b>Ticket type</b> where you want to add the custom field.`,
        },
        {
          id: 12,
          title:
            "Now, make your preferred changes from the new prompt that appears on your screen.",
        },

        {
          id: 13,
          title: "Lastly, hit the <b>Save</b> button.",
          // imageSource: `${GifeBaseUrl}ticket-status-approval.gif`,
        },
      ],
      Note: [
        {
          title:
            "Only Workspace owner, admin or project owner can create and add custom fields to the ticket. ",
        },
      ],
      text1imageSource: `${GifeBaseUrl}custom-field .gif`,
    },
  ],
};
