import plus from "./../../../../../new/icons/plus.png";
import message from "./../../../../../new/icons/message.svg";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MAcontent = {
  title: "Manage Activity",
  link: "manage-activity",
  content: [
    {
      id: 0,
      title: "Add Participants to a direct message.",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Navigate to Direct messages and click on the <img src=${plus} alt="plus" height="12" width="12"/> button right next to it.`,
        },
        {
          id: 3,
          title: `You'll find a search bar on the right side. Use it to search and add participants to your conversation.`,
        },
        {
          id: 4,
          title: `Type your message in the text editor and send it.`,
          imageSource: `${GifBaseUrl}add-multiple-participant.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Mention Anything",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Navigate to the message thread or cospace where you want to mention.`,
        },
        {
          id: 3,
          title: `Now go to the text editor, and type for eg; @ticket, meet, member, file, teams, etc and mention to your respective peers.`,
          imageSource: `${GifBaseUrl}mention-anything.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Reply Privately",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Navigate to the message thread or cospace where you want to mention.`,
        },
        {
          id: 3,
          title: `Hover over the message that you want to reply privately to and click on the <b>three dots</b>.`,
        },
        {
          id: 4,
          title: `Click on <b>Private reply</b> from the options that appear.`,
        },
        {
          id: 5,
          title: `New prompt will open on your right side.`,
        },
        {
          id: 6,
          title: `Here in the text editor and send your message.`,
          imageSource: `${GifBaseUrl}private-reply.gif`,
        },
      ],
      Note: [
        {
          id: 1,
          title:
            "You can reply privately in CoSpace, Subjects and Multiparticipants. ",
        },
      ],
    },
    {
      id: 3,
      title: "React to Messages",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `Navigate to the message thread or CoSpace.`,
        },
        {
          id: 3,
          title: `Hover over the message that you want to react.`,
        },
        {
          id: 4,
          title: `Now, select your preferred emojis.`,
          imageSource: `${GifBaseUrl}react-to-message.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "Search anything",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on the <img src=${message} alt="message" height="14" width="14"/> icon from the left side.`,
        },
        {
          id: 2,
          title: `You can see the search bar at the top right side; click on it.`,
        },
        {
          id: 3,
          title: `Here type and search for anything in your workspace for eg; CoSpace, direct messages, members, tickets, or files, and so on.`,
          imageSource: `${GifBaseUrl}searching-anything.gif`,
        },
      ],
      text1content: `<i>Remember, suppose you select cospaces on the search bar then, type the cospace name you want to search. Similarly, if you select a member, you can type the member name you are looking for right next to it</i>.`,
    },
    {
      id: 5,
      title: "Share attachment",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on the <img src=${message} alt="message" height="14" width="14"/> from the left side.`,
        },
        {
          id: 2,
          title: `Navigate to the message thread or CoSpace where you want to share the attachment.`,
        },
        {
          id: 3,
          title: `Go to the <b>text editor</b>.`,
        },
        {
          id: 4,
          title: `Click on the Attachment icon, and you’ll see two options, <b>Upload from files</b> and <b>Upload from computer</b>.`,
        },
        {
          id: 5,
          title: `Now, <b>select one</b> and then <b>share it</b> with your peers.`,
          imageSource: `${GifBaseUrl}share-attachment.gif`,
        },
      ],
    },

    {
      id: 6,
      title: "Share Screen and recording",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, and click on <img src=${message} alt="message" height="14" width="14"/> from the left side.`,
        },
        {
          id: 2,
          title: `Now go to the text editor section.`,
        },
        {
          id: 3,
          title: `Click on the screen-like icon, and a new prompt will open; here, give it a title.`,
        },
        {
          id: 4,
          title: `Also, you'll see options like <b>Record voice</b> and <b>Record video</b>; press the toggle button according to your preference.`,
        },
        {
          id: 5,
          title: `Then click on the <b>Start</b> button`,
        },
        {
          id: 6,
          title: `In a new prompt, click on the tab or window that you want to record.`,
        },
        {
          id: 7,
          title: `Then click on the <b>Share</b> button.`,
        },
        {
          id: 8,
          title: `After you have done the recording, you can share it with your peers, for that click on the <b>Send</b> button.`,
        },
        {
          id: 9,
          title: `Or you can <b>Create ticket</b> or <b>Discard</b>.`,
          imageSource: `${GifBaseUrl}screen-recording.gif`,
        },
      ],
    },
  ],
};
