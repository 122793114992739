import plus from "./../../../../../new/icons/plus.png";
import taskicon from "./../../../../../new/icons/task.svg";
import deleteIcon from "./../../../../../new/icons/delete.svg";
import eye from "./../../../../../new/icons/eye.png";
import share from "./../../../../../new/icons/share.png";
import paper from "./../../../../../new/icons/copy.png";
import setting from "./../../../../../new/icons/Settings.png";

const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IGCcontent = {
  title: "Forms",
  link: "forms",
  content: [
    {
      id: 0,
      title: "Creating a New Form",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            " Navigate to the project where you want to create form and click on <b>Create New</b>.",
        },
        {
          id: 3,
          title: "Select <b>Form folder</b>  and give a name to it.",
        },

        {
          id: 4,
          title: `Click on <img src=${plus}  alt="plus"  height="12px" width="14px" /> icon from the bottom right corner`,
        },
        {
          id: 5,
          title: `Name the form and give it a description.`,
        },
        {
          id: 6,
          title:
            "Now build a form simply by dragging and dropping from left to right.",
        },
        {
          id: 7,
          title: `You can view your form simply by clicking on <img src=${eye}  alt="plus"  height="12px" width="14px" /> icon from the top right.`,
        },
        {
          id: 8,
          title: `And share by clicking on <img src=${share} alt="task"  height="14" width:"14"/> icon from the top right, you can share it directly by copying link or you can share it through email.`,
          imageSource: `${GifeBaseUrl}creating-new-form.gif`,
        },
      ],
    },

    {
      id: 1,
      title: "Editing Form Field",
      onedescription:
        "You can edit, delete and duplicate each form field by following this process",
      steps: [
        {
          id: 1,
          title: "Click the form whose field you want to edit.",
        },
        {
          id: 2,
          title: "Click on the field that you want to edit.",
        },
        {
          id: 3,
          title: `You want to duplicate it click on the <img src=${paper} alt="task"  height="14" width:"14"/> icon from the top right.`,
        },
        {
          id: 4,
          title: `If you want to delete it click on on the <img src=${deleteIcon} alt="task"  height="14" width:"14"/> from the top left.`,
        },
        {
          id: 5,
          title: `And if you want to make changes to the form click on the <img src=${setting} alt="task"  height="14" width:"14"/> icon from the top left.`,
          imageSource: `${GifeBaseUrl}editing-form-field.gif`,
        },
      ],
    },

    {
      id: 2,
      title: "Share Form",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon`,
        },
        {
          id: 2,
          title:
            "Navigate to the <b>Project</b> and click on the <b>Form Folder</b> that you want to share.",
        },
        {
          id: 3,
          title: "Select the form that you want to share.",
        },
        {
          id: 4,
          title: `Then, click on the <b>three horizontal dots</b>.`,
        },
        {
          id: 5,
          title: `Select <b>Share</b> from the option.`,
        },
        {
          id: 6,
          title: `Choose whether you want to share from by <b>Link</b> or by <b>Mail</b>.`,
          imageSource: `${GifeBaseUrl}sharing-form.gif`,
        },
      ],
    },

    {
      id: 3,
      title: "Delete Form",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon`,
        },
        {
          id: 2,
          title:
            "Navigate to the <b>Project</b> and click on the <b>Form Folder</b> that you want to delete.",
        },
        {
          id: 3,
          title: "Select the form that you want to delete.",
        },
        {
          id: 4,
          title: `Then, click on the <b>three horizontal dots</b>.`,
        },
        {
          id: 5,
          title: `Select <b>Delete</b> from the option.`,
          imageSource: `${GifeBaseUrl}deleting-form.gif`,
        },
      ],
    },
  ],
};
