import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Sprintcontent = {
  title: "Creating & Managing Sprint Folders",
  link: "creating-managing-sprint-folders",
  content: [
    {
      id: 0,
      title: "Create Sprint Folder",
      onedescription:
        "To create and start a sprint, first you need to create a Sprint folder.",
      steps: [
        {
          id: 1,
          title: `Login to you dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 3,
          title:
            "Hover over the project where you want to create a new sprint.",
        },
        {
          id: 4,
          title: `Click on the + button next to your project.`,
        },
        {
          id: 5,
          title: `Click on the <b>Ticket</b> folder.`,
        },
        {
          id: 6,
          title: "Add the necessary details.",
        },
        {
          id: 7,
          title: `Press the <b>toggle</b> button to enable the sprint.`,
        },

        {
          id: 8,
          title: "Finally, hit the <b>Create</b> button.",
          imageSource: `${GifeBaseUrl}sprint-folder.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Create new Sprint ",
      onedescription:
        "After creating the Sprint Folder then you create sprints.",
      steps: [
        {
          id: 1,
          title: `Navigate to the Sprint Folder where you want to create Sprint.`,
        },
        {
          id: 2,
          title: `From the top, click on the Sprint option.`,
        },
        {
          id: 3,
          title: "Click on the New Sprint from the top .",
        },
        {
          id: 4,
          title: `Add the necessary details for the sprint.`,
        },

        {
          id: 5,
          title: "Click on <b>Create</b> button.",
          imageSource: `${GifeBaseUrl}sprint-details.gif`,
        },
      ],
      Note: [
        {
          title:
            " If you create a ticket in the Sprint Folder, it will be saved in the Backlog by default. Additionally, if you go to the Sprint and then create a ticket, it will be added to that sprint.",
        },
      ],
    },
  ],
};
