import plus from "./../../../../../new/icons/plus.png";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

const NewImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/new-images/";

function getDownloadURL() {
  var os = navigator.platform.toLowerCase();
  let link;
  if (os.includes("win")) {
    link = "https://anydone.com/download-page-windows.html";
  } else if (os.includes("mac")) {
    link = "https://anydone.com/download-page-mac.html";
  } else if (os.includes("linux")) {
    link = "https://anydone.com/download-page-linux.html";
  }

  // Default URL in case the OS is not recognized
  return link;
}

export const UAcontent = {
  title: "Using anydone",
  link: "using-anydone",
  content: [
    {
      id: 0,
      title: "Registering New User",
      steps: [
        {
          id: 1,
          title: `Visit our website <a href=${"https://anydone.com"} target="_blank">anydone</a>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Try for Free</b> button from the top right`,
          imageSource: `${GifBaseUrl}try-for-free.png`,
        },
        {
          id: 3,
          title: `Step 3: Now enter the required details shown in the new windows.`,
          imageSource: `${GifBaseUrl}anydone-organisation-form.png`,
        },
        {
          id: 4,
          title:
            "Verification code will be sent to your registered email. So open your email and copy the confirmation code and enter here",
          imageSource: `${GifBaseUrl}confirmation-code.png`,
        },
        {
          id: 5,
          title:
            "Now anydone greeting message will pop up, here, click on the Create a Workplace option.",
          imageSource: `${GifBaseUrl}create-workspace.png`,
        },

        {
          id: 6,
          title: "Enter the workspace details.",
        },
        {
          id: 7,
          title: "Click on <b>Create</b> button",
          imageSource: `${GifBaseUrl}/entering-worksapce-detail.png`,
        },
        {
          id: 8,
          title: "That's it you've create your first workspace to anydone",
        },
        {
          id: 9,
          title: "Now, click on <b>Get Started</b>.",
          imageSource: `${GifBaseUrl}get-started.png`,
        },
        {
          id: 10,
          title:
            "Now, start inviting your members to join your workspace, and once you done click on <b>Invite Members</b>.",
          imageSource: `${NewImageBaseUrl}invite-members.png`,
        },
      ],
    },
    {
      id: 1,
      title: "Download anydone",
      steps: [
        {
          id: 1,
          title: `Go to our official  <a href=${"https://anydone.com"} target="_blank">website</a> and click on Download anydone from bottom.`,
        },
        {
          id: 2,
          title: `Now, download anydone according to your operating system (Windows, Mac or Linux).`,
        },
        {
          id: 3,
          title: `Once the download is complete, unzip the file.`,
        },
        {
          id: 4,
          title: `Again, click on the anydone and start the installation.`,
        },
        {
          id: 5,
          title: `Once installation is complete, enter your anydone email and password.`,
          imageSource: `${GifBaseUrl}downloading-anydone.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Login to Another anydone Workspace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on <img src=${plus} alt="plus" height="12" width="12"/> from the bottom left corner.`,
        },
        {
          id: 2,
          title: `Click on <b>Sign in to another workplace</b> option.`,
        },
        {
          id: 3,
          title: `New prompt will pop up, here enter your login credentials.`,
        },
        {
          id: 4,
          title: `Click on <b>Login</b> option.`,
          imageSource: `${GifBaseUrl}signin-to-new-workspace.gif`,
        },
      ],
    },
  ],
};
