import plus from "./../../../../../new/icons/plus.png";
import camera from "./../../../../../new/icons/camera.svg";

const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/getting-started/";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MngPcontent = {
  title: "Manage Profile",
  link: "manage-profile",
  content: [
    {
      id: 0,
      title: "Edit your profile",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on your <b>profile icon</b>.`,
        },
        {
          id: 2,
          title: `Click on your name from the given option.`,
        },
        {
          id: 3,
          title: `Click on the <b>Edit Profile</b> button from the right side.`,
        },
        {
          id: 4,
          title: `Now make the changes you want on your profile and click on the <b>Update</b> button.`,
          imageSource: `${GifBaseUrl}edit-profile.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Upload a profile photo",
      steps: [
        {
          id: 1,
          title: `Login to your dashboard, and click on your <b>profile icon</b> from the top right.`,
        },
        {
          id: 2,
          title: `Click on your name from the given option.`,
        },
        {
          id: 3,
          title: `Click on the <img src=${camera} alt="plus-icon" height="16px" width="16px" /> icon from the left side.`,
        },
        {
          id: 4,
          title: `Now click on the <b>Upload Photo</b> button.`,
        },
        {
          id: 5,
          title: `Select your desired photo from your device and upload it.`,
          imageSource: `${GifBaseUrl}change-profile-picture.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Set your status and availability",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on your <b> profile icon</b> from  the top right corner.`,
        },
        {
          id: 2,
          title: `Click on the <b> Set your status </b>.`,
        },
        {
          id: 3,
          title: `Select your preferred status from the given option.`,
        },
        {
          id: 4,
          title: `Now click on the <b> Save </b> button.`,
          imageSource: `${GifBaseUrl}change-profile-status.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Set your Status and availability for a specific time",
      steps: [
        {
          id: 1,
          title: ` Go to your dashboard, click on your <b> profile icon</b> from  the top right corner.`,
        },
        {
          id: 2,
          title: `Click on the <b> Set your status </b>.`,
        },
        {
          id: 3,
          title: `Select your preferred status from the given option.`,
        },
        {
          id: 4,
          title: `Now click on the drop-down menu of <b>Clear after </b>.`,
        },
        {
          id: 5,
          title: `Select the specific time from the default option.`,
        },

        {
          id: 6,
          title: `Or, click on the <b>Custom option</b> and set your preferred date and time.`,
        },
        {
          id: 7,
          title: `Lastly, click on the <b>Save </b> button.`,
          imageSource: `${GifBaseUrl}change-availability.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "Change your display name",
      steps: [
        {
          id: 1,
          title: ` Go to your dashboard, click on your <b> profile icon </b> from  the top right corner.`,
        },
        {
          id: 2,
          title: `Click on your name from the given option.`,
        },
        {
          id: 3,
          title: `Click on the <b>Edit Profile</b> button from the right side`,
        },
        {
          id: 4,
          title: `Now, under the Name section change the default name as you like.`,
        },
        {
          id: 5,
          title: `Then click on the <b> Update </b> button.`,
          imageSource: `${GifBaseUrl}change-profile-name.gif`,
        },
      ],
    },
    {
      id: 5,
      title: "Set Holidays",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>Workspace icon</b> from the left side.`,
        },

        {
          id: 2,
          title: `Click on <b>Holidays & Workdays</b>.`,
        },
        {
          id: 3,
          title: `Click on the <b>Holidays</b> and then press the <img src=${plus} alt="plus-icon" height="12px" width="12px" /> button.`,
        },
        {
          id: 4,
          title: "Now add the required details and set your holidays.",
          imageSource: `${GifBaseUrl}set-holidays.gif`,
        },
      ],
    },
    {
      id: 6,
      title: "Set Workdays",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>Workspace icon</b> from the left side.`,
        },

        {
          id: 2,
          title: `Click on <b>Holidays & Workdays</b>.`,
        },
        {
          id: 3,
          title: `Click on the <b>Workdays</b> option.`,
        },
        {
          id: 4,
          title: "Now set your workdays according to your preference.",
          imageSource: `${GifBaseUrl}set-workdays.gif`,
        },
      ],
    },
  ],
};
