import Project from "../../../../../new/icons/project.svg";
import Automation from "../../../../../new/icons/automation.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
const imageUrl2="https://static-edge-a.anydone.com/assets/help-page/all-gif/"
export const ABcontent = {
	title: "Creating Automation Tool (Process)",
	link: "anydone-creation-process",
	content: [
		
		{
			id: 0,
			title: " Speech to Text ",
            onetitle:`Before creating a speech to text process make sure you have created the model in <a href="anydone-creation?tabid=5">third-party model</a>.`,
			steps:[
                {
                    id:1,
                    title:`Log in to your Anydone account.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon`
                },
                {
                    id:3,
                    title:`Select the <b>Process</b> option.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button.`
                },
                {
                    id:5,
                    title:`Enter the name and description.`
                },
                {
                    id:6,
                    title:`Under <b>Type</b>, select the <b>Speech to Text</b> option.`
                },
                {
                    id:7,
                    title:`Define the Input <br><br>Now, under <b>Input Mapping</b><br><br>
If you select <b>Dynamic</b>, the Flowcess will prompt the user to provide an audio file, the audio source language, and the text destination language when it runs.<br>
If you select <b>Static</b>, you must specify the audio file, the audio source language, and the text destination language while creating the process.<br> 
<b>Here, we’ve created the process by selecting the Static option.</b><br>
Now, under <b>Output Mapping</b><br>
From the Output Sample click the <b>+</b> button<br>
`,
imageSource: `${imageUrl}process-speech-to-text.gif`,

                },
            ],
            
            text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;">See how it works</button>`,

	
		},
        {
        id:1,
        title:" Text to Speech",
        onetitle:`Before creating a speech to text process make sure you have created the model in <a href="anydone-creation?tabid=5">third-party model</a>.`,

        steps:[
                {
                    id:1,
                    title:`Log in to your Anydone account.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> icon`
                },
                {
                    id:3,
                    title:`Select the <b>Process</b> option.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button.`
                },
                {
                    id:5,
                    title:`Enter the name and description.`
                },
                {
                    id:6,
                    title:`Under <b>Type</b>, select the <b>Text to speech</b> option.<br>Now, under <b>Input Mapping</b><br>
If you select <b>Dynamic</b>, the Flowcess will prompt the user to provide an audio file, the audio source language, and the text destination language when it runs.<br>
If you select <b>Static</b>, you must specify the audio file, the audio source language, and the text destination language while creating the process.<br> 
<b>Here, we’ve created the process by selecting the Dynamic option.</b><br><br>
Now, under <b>Output Mapping</b><br><br>
From the Output Sample click the <b>+</b> button
`,
imageSource: `${imageUrl}process-text-to-speech.gif`,
                },
               
            ],
            text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=5" style="color:white;">See how it works</a></button>`,
            
        },
        {
            id:2,
            title:"OCR",
    
            steps:[
                    {
                        id:1,
                        title:`Log in to anydone.`
                    },
                    {
                        id:2,
                        title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                    },
                    {
                        id:3,
                        title:`Click the <b>‘+’</b> button from the right side corner.`
                    },
                    {
                        id:4,
                        title:`Enter a <b>name</b> and <b>description</b>.`
                    },
                    {
                        id:5,
                        title:`Under <b>"Type,"</b> select the <b>“OCR”</b> option.`
                    },
                    {
                        id:5,
                        title:`Under the <b>"Input Mapping"</b> section, add the inputs according to your preferences.`
                    },
                    {
                        id:6,
                        title:`Define the Output Mapping by clicking the + button under <b>"Output Sample."</b>`
                    },
                
                    {
                        id:7,
                        title:`Finally, click the <b>"Create"</b> button.
    `,
    imageSource: `${imageUrl2}ocr-process.gif`,
                    },
                   
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
</a></button>`,
                    one_more_title:`<b><u>OCR Flowcess</u></b>`,
                    one_more_subtitle:`Before creating OCR Flowcess make sure that you’ve <a href="anydone-app?tabid=0">created an app directory</a> to run it and <a href="anydone-creation-process?tabid=2">created OCR process</a> as well. 

`,
    imageSource3    : `${imageUrl2}ocr-process-creation.gif
`,


              
                
        },
        {
            id:3,
            title:"Information Retrieval",
       
    
            steps:[
                    {
                        id:1,
                        title:`Log in to anydone.`
                    },
                    {
                        id:2,
                        title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                    },
                    {
                        id:3,
                        title:`Click on <b>Process</b>.
`
                    },
                    {
                        id:4,
                        title:`Click the <b>+</b> button from the right side corner.`
                    },
                    {
                        id:5,
                        title:`Enter a <b>name</b> and <b>description.</b>`
                    },
                    {
                        id:6,
                        title:`Under <b>“Type”</b>, select <b>Information Retrieval</b> process.`
                    },
                    {
                        id:7,
                        title:`Under <b>'IRS Configuration,'</b> choose between <b>'Custom'</b> or <b>'From Template.'</b>`
                    },
                    {
                        id:8,
                        title:`Define the <b>Extract Type</b> first, then add the <b>Key</b> to extract.`
                    },
                    {
                        id:9,
                        title:`Define <b>Input Mapping</b>.`
                    },
                    {
                        id:10,
                        title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                    },
                    {
                        id:11,
                        title:`Once you're done, click on the <b>Create</b> button.<br><br>Here we've selected <b>'Custom'</b> and chosen <b>'Key-Value'</b> from the Extract Type, so we've manually added the <b>Extract Key</b>.<br>
If you have a table and want to extract a value from it, under 'Table Name' you need to define the column name, and corresponding to it, you need to define its value type. If you don't want to extract a value from the table, you can leave it as it is.

`

                    },
                  
                   
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
               one_more_title:`<b><u>Information Retrieval Flowcess</u></b>`,
               one_more_subtitle:`Before creating Flowcess with Information Retrieval process make sure that you’ve <a href="anydone-app?tabid=0">created an app from app directory</a> to run it and <b>created Information Retrieval Process</b> as well.<br> `,
               imageSource3:`${imageUrl2}information-retrival-flowcess-creation.gif`
                
        },
        {
            id:4,
            title:"Grammar",
          
    
            steps:[
                    {
                        id:1,
                        title:`Log in to anydone.`
                    },
                    {
                        id:2,
                        title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                    },
                    {
                        id:3,
                        title:`Click on <b>Process</b>.`
                    },
                    {
                        id:4,
                        title:`Click the <b>+</b> button from the right side corner.`
                    },
                    {
                        id:5,
                        title:`Enter a <b>name</b> and <b>description</b>.`
                    },
                    {
                        id:6,
                        title:`Under <b>Type</b>, select <b>Grammar</b> option.`
                    },
                    {
                        id:7,
                        title:`Select a <b>Model</b> (<b>Default</b>: anydone Model).`
                    },
                    {
                        id:8,
                        title:`The <b>Input Mapping</b> will already be defined.`
                    },
                    {
                        id:9,
                        title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                    },
                    {
                        id:10,
                        title:`Once you're done, click the <b>Create</b> button.`,
                        imageSource:`${imageUrl2}information-retrival-flowcess-creation.gif`
                    },
               
                   
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
               add_title:`<b><u>Grammar Flowcess</u></b>`,
               text1content:`Before creating Grammar Flowcess make sure you’ve <b>created Grammer Process</b>, then you need to create an app from an <a href="anydone-app?tabid=0">app directory</a> to run it.  `
                
        },
        {
            id:5,
            title:"Translation",
         
    
            steps:[
                {
                    id:1,
                    title:`Log in to anydone.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                },
                {
                    id:3,
                    title:`Click on <b>Process</b>.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button from the right side corner.`
                },
                {
                    id:5,
                    title:`Enter a <b>name</b> and <b>description</b>.`
                },
                {
                    id:6,
                    title:`Under <b>Type</b>, select <b>Grammar</b> option.`
                },
                {
                    id:7,
                    title:`Select the <b>Model</b> (<b>Default</b>: anydone Model).`
                },
                {
                    id:8,
                    title:`The <b>"Input Mapping"</b> will already be defined. Now, define the <b>Input Type</b> and <b>Data Type</b> (for example, <b>Input Type</b>: Dynamic).`
                },
                {
                    id:9,
                    title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                },
                {
                    id:10,
                    title:`Once  done, click the <b>Create</b> button.`,
                    imageSource:`${imageUrl2}process-translation.gif`

                },
           
            
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
            add_title:`<b><u>Translation Flowcess</u></b>`,
            text1content:`Before creating Translation Flowcess make sure that you’ve created an app <a href="anydone-app?tabid=0">from the app directory</a> to run it and <b>created Translation Process</b> as well. `,
            imageSource3:`${imageUrl2}creating-flowcess-translation.gif`
                
        },
        {
            id:6,
            title:"Text Summarization",
   
             
    
            steps:[
                {
                    id:1,
                    title:`Log in to anydone.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                },
                {
                    id:3,
                    title:`Click on <b>Process</b>.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button from the right side corner.`
                },
                {
                    id:5,
                    title:`Enter a <b>name</b> and <b>description</b>.`
                },
                {
                    id:6,
                    title:`Under <b>"Type,"</b> select the <b>"Text Summarization"</b> option.`
                },
                {
                    id:7,
                    title:`Select the <b>Model</b> (<b>Default</b>: anydone Model).`
                },
                {
                    id:8,
                    title:`The <b>"Input Mapping"</b> will already be defined. Now, define the <b>Input Type</b> and <b>Data Type</b> (for example, <b>Input Type</b>: Dynamic).`
                },
                {
                    id:9,
                    title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                },
                {
                    id:10,
                    title:`Once  done, click the <b>Create</b> button.`,
                    imageSource:`${imageUrl2}text-summerization-process.gif`
                },
           
            
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
            add_title:`<b><u>Text Summarization Flowcess</u></b>`,
            text1content:`Before creating Text Summarization Flowcess make sure that you’ve created an app <a href="anydone-app?tabid=0">from the app directory</a> to run it and <b>Text Summarization Process</b> as well. `,
            imageSource3:`${imageUrl2}text-summerization-flowcess.gif`
                
                
        },
        {
            id:7,
            title:"Object Detection",
                   
             
    
            steps:[
                {
                    id:1,
                    title:`Log in to anydone.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                },
                {
                    id:3,
                    title:`Click on <b>Process</b>.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button from the right side corner.`
                },
                {
                    id:5,
                    title:`Enter a <b>name</b> and <b>description</b>.`
                },
                {
                    id:6,
                    title:`Under <b>"Type,"</b> select the <b>"Text Summarization"</b> option.`
                },
                {
                    id:7,
                    title:`Select the <b>Model</b> (<b>Default</b>: anydone Model).`
                },
                {
                    id:8,
                    title:`The <b>"Input Mapping"</b> will already be defined. Now, define the <b>Input Type</b> and <b>Data Type</b> (for example, <b>Input Type</b>: Dynamic).`
                },
                {
                    id:9,
                    title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                },
                {
                    id:10,
                    title:`Once  done, click the <b>Create</b> button.`,
                    imageSource:`${imageUrl2}object-detection-process.gif`
                },
           
            
                ],
                Note:[
                    {
                        id:1,
                        title:`Note: To detect object more than two you need to define output manually .`
                    }
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
            add_title:`<b><u>Object Detection Flowcess</u></b>`,
            text1content:`Before creating Object Detection Flowcess make sure that you’ve created an app <a href="anydone-app?tabid=0">from the app directory</a> to run it and <b>created an Object Detection Process</b> as well. `,
            imageSource3:`${imageUrl2}object-detection-flowcess.gif`
                
        },
        {
            id:8,
            title:"Face Detection",
                         
    
            steps:[
                {
                    id:1,
                    title:`Log in to anydone.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                },
                {
                    id:3,
                    title:`Click on <b>Process</b>.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button from the right side corner.`
                },
                {
                    id:5,
                    title:`Enter a <b>name</b> and <b>description</b>.`
                },
                {
                    id:6,
                    title:`Under <b>"Type,"</b> select the <b>"Text Summarization"</b> option.`
                },
                {
                    id:7,
                    title:`Select the <b>Model</b> (<b>Default</b>: anydone Model).`
                },
                {
                    id:8,
                    title:`The <b>"Input Mapping"</b> will already be defined. Now, define the <b>Input Type</b> and <b>Data Type</b> (for example, <b>Input Type</b>: Dynamic).`
                },
                {
                    id:9,
                    title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                },
                {
                    id:10,
                    title:`Once  done, click the <b>Create</b> button.`,
                    imageSource:`${imageUrl2}face-detection-process.gif`

                },
           
            
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
            add_title:`<b><u>Face Detection Flowcess</u></b>`,
            text1content:`Before creating Face Detection Flowcess make sure that you’ve <a href="anydone-app?tabid=0">created an app from the app directory</a> to run it and <b>created a Face Detection Process</b> as well. 
`,
imageSource3:`${imageUrl2}face-detection-process.gif`

                    
        },
        {
            id:9,
            title:"Face Recognition",
                                    
    
            steps:[
                {
                    id:1,
                    title:`Log in to anydone.`
                },
                {
                    id:2,
                    title:`Click on the <b><img src=${Automation} alt="automation" height="12" width="12"/></b> tool icon`
                },
                {
                    id:3,
                    title:`Click on <b>Process</b>.`
                },
                {
                    id:4,
                    title:`Click the <b>+</b> button from the right side corner.`
                },
                {
                    id:5,
                    title:`Enter a <b>name</b> and <b>description</b>.`
                },
                {
                    id:6,
                    title:`Under <b>"Type,"</b> select the <b>"Text Summarization"</b> option.`
                },
                {
                    id:7,
                    title:`Select the <b>Model</b> (<b>Default</b>: anydone Model).`
                },
                {
                    id:8,
                    title:`The <b>"Input Mapping"</b> will already be defined. Now, define the <b>Input Type</b> and <b>Data Type</b> (for example, <b>Input Type</b>: Dynamic).`
                },
                {
                    id:9,
                    title:`Under <b>Output Sample</b>, hover over your preferred output, click the <b>+</b> button, and it will be added to the <b>Output Mapping</b>.`
                },
                {
                    id:10,
                    title:`Once  done, click the <b>Create</b> button.`,
imageSource:`${imageUrl2}face-recognisition.gif`

                },
           
            
                ],
                text:`			<button style="display: block;  padding: 10px 20px; font-size: 16px; background-color: #007BFF; color: white; border: none; border-radius: 5px; cursor: pointer;"><a href="anydone-create-flowcess?tabid=6" style="color:white;">Now Run the Flowcess 
                </a></button>`,
            add_title:`<b><u>Face Recognition Flowcess</u></b>`,
            text1content:`Before creating Face Recognition  Flowcess make sure that you’ve <a href="anydone-app?tabid=0">created an app from the app directory</a> to run it and <b>created a Face Recognition  Process</b> as well. 
`,
imageSource3:`${imageUrl2}face-recognisition-flowcess.gif`
               
        },
     
		

	],
};

