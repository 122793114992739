import plus from "./../../../../../new/icons/plus.png";
import taskicon from "./../../../../../new/icons/task.svg";
import deleteIcon from "./../../../../../new/icons/delete.svg";
import eye from "./../../../../../new/icons/eye.png";

const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IGCcontent = {
  title: "Roadmap",
  link: "roadmap",
  content: [
    {
      id: 0,
      title: "Creating a Product Roadmap",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Now you can either create a new project or create a new roadmap within an existing one.",
        },
        {
          id: 3,
          title:
            "Here, we'll be <b>creating a roadmap</b> in an existing project.",
        },

        {
          id: 4,
          title: `So, hover over the project for which you want to create a roadmap and click on three vertical dots.`,
        },
        {
          id: 5,
          title: `Under <b>Create New</b>, Click on <b>Product Roadmap</b> Folder.`,
        },
        {
          id: 6,
          title:
            "Enter a <b>name</b> for the roadmap folder and click on <b>Create</b>.",
        },
        {
          id: 7,
          title: `Now click on the <b>Product Roadmap</b> folder that you created.`,
        },
        {
          id: 8,
          title: `Now, click on <img src=${plus} alt="plus" height="12" width="12"/> icon from the bottom right.`,
        },

        {
          id: 9,
          title:
            "Give a <b>name</b> and <b>description</b> to your roadmap and click on the <b>Create</b> button.",
        },
        {
          id: 10,
          title:
            "Here, give required details for your roadmap like title, duration, status and so on.",
        },
        {
          id: 11,
          title: "Now, click on the <b>Create</b> button.",
          imageSource: `${GifeBaseUrl}creating-product-roadmap.gif`,
        },
      ],

      // additional_title: [
      //   {
      //     id: 0,
      //     title: `Since you've created a roadmap, you can view them all on your board. In the roadmap board you can search the roadmap by name and priority. You can change its status easily. Simple drag and drop your roadmap and change its status. You can also create a roadmap from here, just click on the <img src=${plus} alt="plus" height="12" width="12"/> icon and enter required details.`,
      //     imageSource: `${GifeBaseUrl}product-roadmap.gif`,
      //   },
      // ],
      text1content: `Since you've created a roadmap, you can view them all on your board. In the roadmap board you can search the roadmap by name and priority. You can change its status easily. Simple drag and drop your roadmap and change its status. You can also create a roadmap from here, just click on the <img src=${plus} alt="plus" height="12" width="12"/>  icon and enter required details.`,
      text1imageSource: `${GifeBaseUrl}product-roadmap.gif`,
    },

    {
      id: 1,
      title: "Different Views for Roadmap",
      onedescription:
        "With anydone you can view your roadmap in multiple views such as List, Calendar, and Timeline.",
      steps: [
        {
          id: 1,
          title: `Login to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 2,
          title:
            "Navigate to the project and click on the roadmap folder that you've created",
        },
        {
          id: 3,
          title: "Now click on your preferred view option from the top.",
        },
      ],
      text1content:
        "In <b>List view</b> you can see all the roadmap folders created for your projects or you can view them on the basis of roadmap status and priority. From here you can edit it, change its status, priority or even link the ticket folder to your roadmap. Also you can delete the roadmap folder from here or can create a new roadmap as well.",
      text1imageSource: `${GifeBaseUrl}list-view.gif`,

      // text3content:
      //   "In the <b>Calendar view</b>, you have the ability to observe all the roadmap folders associated with your projects. You can also filter them based on the roadmap's status and priority. Within this view, you can perform various actions such as editing, changing status or priority, linking ticket folders to your roadmap, deleting a roadmap, and even creating a new roadmap.",
      // text3imageSource: `${GifeBaseUrl}calendar-view.gif`,

      // text4content:
      //   "Similar to list and calendar, <b>Timeline view</b> also lets you  easily navigate and visualize all the roadmap folders linked to your projects. You have the flexibility to sort them based on the status and priority of the roadmaps. This view allows you to perform multiple tasks like editing, modifying status or priority, connecting ticket folders to your roadmap, deleting a roadmap, and creating a new one.",
      // text4imageSource: `${GifeBaseUrl}timeline-view.gif`,
    },
  ],
};
