import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { searchContent } from "../../SearchContent";
import Highlighter from "react-highlight-words";
import { Helmet } from "react-helmet";
// import searchIcon from "../used_images/icons/search_icon.svg";

import { useLocation } from "react-router-dom";
import { ButtonTags } from "./common/ButtonTags";
import {
	FlexDiv,
	H3Text,
	PText,
	SecStartedContent,
	StartHeaderDiv,
} from "../styleComponents";
import { v4 as uuidv4 } from "uuid";
import SearchBar from "./common/SearchBar";
import { Waypoint } from "react-waypoint";

const SearchResult = ({ handleWaypointEnter, handleWaypointLeave }) => {
	const location = useLocation();
	const [show, setShow] = useState(false);
	// const [searchText, setSearchText] = useState(location.state);
	const [texttohighlight, setTexttohighlight] = useState("");
	const [data, setData] = useState([]);

	useEffect(() => {
		let inputVal = location.state;
		if (inputVal) {
			searchFunction(inputVal.toLocaleLowerCase());
		}
	}, [location.state]);

	const searchFunction = (inputVal) => {
		setData(undefined);
		const toShowData = [];
		searchContent.forEach((element) => {
			if (element.from === "using-anydone") {
				if (element.title.toLocaleLowerCase().includes(inputVal)) {
					// const { title, path, godown, activeTab, parentPath, parentTopic } =
					// element;
					delete element["content"];
					toShowData.push(element);
					// console.log(element);
				}
			} else {
				let ArrayofSentences = element.content.split(".");
				const { title, path, godown, activeTab, parentPath, parentTopic } =
					element;
				ArrayofSentences.forEach((eachsentences) => {
					if (eachsentences.toLocaleLowerCase().includes(inputVal)) {
						let obj = {
							id: uuidv4(),
							title,
							content: eachsentences,
							path,
							godown,
							activeTab,
							parentTopic,
							parentPath,
						};

						toShowData.push(obj);
					}
				});
			}
		});
		setData([...toShowData]);
		setShow(true);
		setTexttohighlight(inputVal);
	};
	return (
		<div className="search-page">
			<Helmet>
				<title>Search Anything on anydone</title>
			</Helmet>
			<StartHeaderDiv>
				{data && (
					<p className="header-info">
						{data.length} results found for “{texttohighlight}”
					</p>
				)}

				<SearchBar state={location.state} searchFunction={searchFunction} />
			</StartHeaderDiv>
			<Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

			<SecStartedContent>
				<ButtonTags />
				{show && (
					<div className="results-div">
						{data.length === 0 && (
							<FlexDiv direction={"column"} textalign={"center"}>
								<H3Text>
									Sorry! We didn't find anything like "{texttohighlight}"
								</H3Text>
								<PText>
									Try finding some different, or browse other help topics
									instead.
								</PText>
							</FlexDiv>
						)}
						{data?.map((each) => {
							return (
								<div key={each.id} className="each-result-div">
									<div className="title-info">
										<Link
											to={`/${each.path}`}
											state={{
												active: each?.activeTab,
												navigator: each?.godown,
											}}
										>
											<PText>
												<b>{each.title}</b>
											</PText>
										</Link>

										{each.belongsto && (
											<p className="belongs-to">{each.belongsto}</p>
										)}
									</div>
									{each.content && (
										<PText>
											<Highlighter
												highlightClassName="highlightclass"
												searchWords={[texttohighlight]}
												autoEscape={true}
												textToHighlight={each.content}
											/>
											.
										</PText>
									)}

									<Link to={`/${each.parentPath}`}>
										<p className="content-of-page">
											{each.parentTopic.toUpperCase()}
										</p>
									</Link>
								</div>
							);
						})}
					</div>
				)}
			</SecStartedContent>
		</div>
	);
};
export default SearchResult;
