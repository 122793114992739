import plus from "./../../../../../new/icons/plus.png";
// import down from "./../../../../../new/icons/down.svg";
// import iicon from "./../../../../../new/icons/i.svg";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const IMCcontent = {
  title: "Inviting and Managing Company",
  link: "inviting-and-managing-company",
  content: [
    {
      id: 0,
      title: "Inviting Company",
      steps: [
        {
          id: 1,
          title: `From your dashboard, click on your Workspace icon from the left.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: ` Under Administration, navigate to the <b>External Teams</b> and click on it.`,
        },
        {
          id: 4,
          title: `Click on <b>Company</b> from the top`,
        },
        {
          id: 5,
          title: `Click the <img src=${plus} alt="plus" height="14px" width="14px"/> icon from the right side corner.`,
        },
        {
          id: 5,
          title: `Enter a Company's name, email and all the details.`,
        },

        {
          id: 6,
          title: `Then, click on the <b>Invite</b> button.`,
          imageSource: `${GifBaseUrl}invite-company.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Editing Company Details and Information",
      steps: [
        {
          id: 1,
          title: `From the dashboard click on your workspace icon from the left.`,
        },
        {
          id: 2,
          title: `Select <b>Workspace Settings</b> option`,
        },
        {
          id: 3,
          title: `Under Administration click on <b>External Teams</b> option.`,
        },
        {
          id: 4,
          title: `Click on the <b>Company</b> option from the top.`,
        },
        {
          id: 5,
          title: `Hover over the company name that you want to edit.`,
        },
        {
          id: 6,
          title: `Click on the <b>three vertical dots</b> option.`,
        },
        {
          id: 7,
          title: `Select <b>Edit</b> option and make necessary changes.`,
        },
        {
          id: 8,
          title: `Click on the <b>Update</b>. `,
          imageSource: `${GifBaseUrl}edit-company-detail..gif`,
        },
      ],
    },
  ],
};
