import React, { useState } from "react";
import { Button, Col } from "antd";
import { buttonTags } from "./MainTagsContent";
import { Link } from "react-router-dom";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import arrowLeft from "../../new/homepage/arrow-left.svg";

export const ButtonTags = ({ selectedID }) => {
	// console.log(selectedID)
	let active = `${selectedID}`;
	const [scrolEnd, setscrolEnd] = useState("false"); // For detecting the scroll

	const slideLeft = () => {
		var slider = document.getElementById("slider");
		slider.scrollLeft = slider.scrollLeft - 500;
		setscrolEnd("false");
	};

	const slideRight = () => {
		var slider = document.getElementById("slider");
		slider.scrollLeft = slider.scrollLeft + 500;
		setscrolEnd("true");
	};
	return (
		<div className="main-tag-div">
			{/* Left Button */}
			{scrolEnd === "true" && (
				<LeftOutlined className="left-arrow" onClick={slideLeft} />
			)}
			<div className="menu-tags" id="slider">
				<Link to={`/`} style={{ marginRight: "2rem" }}>
					<button
						style={{
							border: "none",
							outline: "none",
							color: "#666666",
							fontSize: "16px",
							backgroundColor: "transparent",
							cursor: "pointer",
						}}
					>
						<img
							src={arrowLeft}
							alt="arrow-left"
							style={{ marginRight: "0.5rem" }}
						/>
						Go back
					</button>
				</Link>
				{buttonTags.map((item, index) => (
					<Link
						to={`/${item.path}`}
						state={{ selected: `${item.activeID}` }}
						key={index}
					>
						<Button
							ghost
							key={index}
							className={
								active === `${item.id}` ? "tut-tags-active" : "tut-tags"
							}
						>
							{item.name}
						</Button>
					</Link>
				))}
			</div>

			{/* Right Button */}
			<div className="hide">
				<RightOutlined className="right-arrow" onClick={slideRight} />
			</div>
		</div>
	);
};
