import plus from "./../../../../../new/icons/plus.png";
import calendar from "./../../../../../new/icons/calendar.svg";
const ImageBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/meetings/";
const GifBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CMcontent = {
	title: "Creating Meetings",
	link: "creating-meetings",
	content: [
		{
			id: 0,
			title: "General Meetings",
			steps: [
				{
					id: 1,
					title: `From the left side of your dashboard, click on the <img src=${calendar} alt="plus" height="12" width="12"/> icon.`,
				},
				{
					id: 2,
					title: `Click on the <img src=${plus} alt="plus" height="12" width="12"/> button from the right side.`,
					imageSource: `${ImageBaseUrl}how-to-create-meetings-anydone.png`,
				},
				{
					id: 3,
					title: `Click on the <b>new meeting</b>.`,
				},
				{
					id: 4,
					title: `Add the required details and information.`,
				},
				{
					id: 5,
					title: ` Then click on <b>Create</b> button.`,
					imageSource: `${GifBaseUrl}creating-meetings-anydone.gif`,
				},
			],
			add_title: "Alternatively",
			add_steps: [
				{
					id: 1,
					title: `From the top left side of your dashboard, click on <img src=${plus} alt="plus" height="12" width="12"/> button.`,
				},
				{
					id: 2,
					title: `Click on the <b>New Meeting</b>.`,
					imageSource: `${ImageBaseUrl}meetings-create-anydone.png`,
				},
				{
					id: 3,
					title: `Now add necessary details and information.`,
				},
				{
					id: 4,
					title: `Then, click <b>Create</b> button.`,
				},
			],
		},
		{
			id: 1,
			title: "Instant Meetings",

			steps: [
				{
					id: 1,
					title: `Go to your anydone dashboard, then go to text editor of any message thread and click on <b>video</b> like icon.`,
				},
				{
					id: 2,
					title: `A new prompt will appear on your screen.`,
				},
				{
					id: 3,
					title: `Add meeting <b>title</b>, <b>agenda</b>, and <b>set time</b>.`,
				},
				{
					id: 4,
					title: `Now, copy the meeting link and send it to the member with whom you want to have a meeting.`,
				},
				{
					id: 5,
					title: `Or you can click on share button add members.`,
				},
				{
					id: 6,
					title: `Then, click on <b>Join Now</b>.`,
					imageSource: `${GifBaseUrl}anydone-instant-meeting.gif`,
				},
			],
			add_title: "Alternatively",
			add_steps: [
				{
					id: 1,
					title: `From the top left side of your dashboard, click on <img src=${plus} alt="plus" height="12" width="12"/> button.`,
				},
				{
					id: 2,
					title: `Click on the <b>New Meeting Link</b>.`,
					imageSource: `${ImageBaseUrl}instant-meeting-anydone.png`,
				},
				{
					id: 3,
					title: `Add <b>meeting title</b>, <b>agenda</b>, and <b>set time</b>. `,
				},
				{
					id: 4,
					title: `Then, click on <b>Join Now</b>.`,
				},
			],
		},
		{
			id: 2,
			title: "Time Slots",
			onetitle:
				"anydone automatically identifies available time slots for all participants using the calendar so you can schedule meetings at the most convenient time.",
			steps: [
				{
					id: 1,
					title: `Go to your dashboard, and click on the <img src=${calendar} alt="plus" height="12" width="12"/> icon.`,
				},
				{
					id: 2,
					title: `Click on the <img src=${plus} alt="plus" height="12" width="12"/> button from the right side corner.`,
				},
				{
					id: 3,
					title: `Click on Create New Meeting.`,
				},
				{
					id: 4,
					title: ` Add necessary details.`,
				},
				{
					id: 5,
					title: `Below the list of participants, you will find the automatically generated available timeslots for you and your participants.`,
				},
			],
		},
	],
};
