export const searchContent = [
  {
    keywords: [""],

    title: "Registering New User",

    active: "0",

    path: "getting-started/using-anydone",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Using anydone",

    active: "1",

    path: "getting-started/using-anydone",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Create New CoSpace",

    active: "0",

    path: "messages-and-calls/creating-group",

    parent: "Message and Calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Create Sub CoSpace new subject",

    active: "1",

    path: "messages-and-calls/creating-group",

    parent: "Message and Calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Convert Private Message Thread to CoSpace",

    active: "2",

    path: "messages-and-calls/creating-group",

    parent: "Message and Calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Mute CoSpace",

    active: "0",

    path: "getting-started/manage-groups",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Unmute CoSpace",

    active: "1",

    path: "getting-started/manage-groups",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Mute Specific Participants from the CoSpace",

    active: "2",

    path: "getting-started/manage-groups",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Unmute Specific Participants from the CoSpace",

    active: "3",

    path: "getting-started/manage-groups",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Inviting Member",

    active: "0",

    path: "getting-started/inviting-and-managing-members",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Deactivate Member",

    active: "1",

    path: "getting-started/inviting-and-managing-members",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Change Members Role",

    active: "2",

    path: "getting-started/inviting-and-managing-members",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Inviting Externals",

    active: "0",

    path: "getting-started/inviting-and-managing-externals",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Convert External To Member",

    active: "1",

    path: "getting-started/inviting-and-managing-externals",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Edit External Detail",

    active: "2",

    path: "getting-started/inviting-and-managing-externals",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Deactivate External",

    active: "3",

    path: "getting-started/inviting-and-managing-externals",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Inviting Company",

    active: "0",

    path: "getting-started/inviting-and-managing-company",

    parent: "Getting Started",

    parentPath: "getting-started",
  },

  {
    keywords: [""],

    title: "Editing Company Details and Information",

    active: "1",

    path: "getting-started/inviting-and-managing-company",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Change Language and Timezone",

    active: "0",

    path: "getting-started/profile-preferences",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Change Appearance",

    active: "1",

    path: "getting-started/profile-preferences",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Notification Settings",

    active: "2",

    path: "getting-started/profile-preferences",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Change and Set Weekly working hours",

    active: "3",

    path: "getting-started/profile-preferences",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Edit your profile",

    active: "0",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Upload a profile photo",

    active: "0",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Set your status and availability",

    active: "2",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Set your Status and availability for a specific time",

    active: "3",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Change your display name",

    active: "4",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Set Holidays",

    active: "5",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Set Workdays",

    active: "6",

    path: "getting-started/manage-profile",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "How to View Notifications?",

    active: "0",

    path: "getting-started/manage-notification",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "How to Filter Notifications?",

    active: "1",

    path: "getting-started/manage-notification",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Pause Notification",

    active: "2",

    path: "getting-started/manage-notification",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Forget password",

    active: "0",

    path: "getting-started/manage-account",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Change password",

    active: "1",

    path: "getting-started/manage-account",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Set up two-factor authentication",

    active: "2",

    path: "getting-started/manage-account",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  {
    keywords: [""],

    title: "Sign out of anydone account",

    active: "3",

    path: "getting-started/manage-account",

    parent: "Getting Started",

    parentPath: "getting-started",
  },
  // end of getting started
  // start of messages and calls
  {
    keywords: [""],

    title: "Create a new Direct Message (DM)",

    active: "0",

    path: "messages-and-calls/message-experience",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Edit Message",

    active: "1",

    path: "messages-and-calls/message-experience",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Delete Message",

    active: "1",

    path: "messages-and-calls/message-experience",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Reply Message",

    active: "2",

    path: "messages-and-calls/message-experience",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Forward Message",

    active: "2",

    path: "messages-and-calls/message-experience",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Remind Me About This",

    active: "3",

    path: "messages-and-calls/message-experience",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Create and Save Notes for Self",

    active: "0",

    path: "messages-and-calls/manage-messages",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Create New Section",

    active: "1",

    path: "messages-and-calls/manage-messages",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Schedule Message ",

    active: "2",

    path: "messages-and-calls/manage-messages",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "View Schedule Message ",

    active: "2",

    path: "messages-and-calls/manage-messages",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Pin Message ",

    active: "3",

    path: "messages-and-calls/manage-messages",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Unpin Message ",

    active: "3",

    path: "messages-and-calls/manage-messages",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Refining text with generative AI",

    active: "0",

    path: "messages-and-calls/message-edit",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Emoji",

    active: "1",

    path: "messages-and-calls/message-edit",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Create New Post ",

    active: "2",

    path: "messages-and-calls/message-edit",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Code Editor ",

    active: "3",

    path: "messages-and-calls/message-edit",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Join CoSpace",

    active: "0",

    path: "messages-and-calls/groups",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Leave CoSpace",

    active: "1",

    path: "messages-and-calls/groups",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Leave and Delete CoSpace",

    active: "2",

    path: "messages-and-calls/groups",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Archive CoSpace",

    active: "3",

    path: "messages-and-calls/groups",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "View Archived CoSpace",

    active: "4",

    path: "messages-and-calls/groups",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Unarchive CoSpace",

    active: "5",

    path: "messages-and-calls/groups",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "General Settings",

    active: "0",

    path: "messages-and-calls/setting-and-preferences",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Account Settings ",

    active: "1",

    path: "messages-and-calls/setting-and-preferences",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Message Setting",

    active: "2",

    path: "messages-and-calls/setting-and-preferences",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  // {
  //   keywords: [""],

  //   title: "Meeting Setting",

  //   active: "3",

  //   path: "messages-and-calls/setting-and-preferences",

  //   parent: "Messages and calls",

  //   parentPath: "messages-and-calls",
  // },
  {
    keywords: [""],

    title: "Edit Workdays and Holidays",

    active: "4",

    path: "messages-and-calls/setting-and-preferences",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Creating new Workspace",

    active: "0",

    path: "messages-and-calls/workspace-setting",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Edit Workspace Details",

    active: "1",

    path: "messages-and-calls/workspace-setting",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Delete Workspace",

    active: "2",

    path: "messages-and-calls/workspace-setting",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Archive Workspace",

    active: "3",

    path: "messages-and-calls/workspace-setting",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Unarchive Workspace",

    active: "4",

    path: "messages-and-calls/workspace-setting",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "CoConnect/audio call",

    active: "0",

    path: "messages-and-calls/calls",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Video call",

    active: "1",

    path: "messages-and-calls/calls",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Add Participants to a direct message",

    active: "0",

    path: "messages-and-calls/manage-activity",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Mention Anything",

    active: "1",

    path: "messages-and-calls/manage-activity",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Reply Privately",

    active: "2",

    path: "messages-and-calls/manage-activity",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "React to Messages",

    active: "3",

    path: "messages-and-calls/manage-activity",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Search Anything",

    active: "4",

    path: "messages-and-calls/manage-activity",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Share attachment",

    active: "5",

    path: "messages-and-calls/manage-activity",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },

  // messages and calls end

  // Project Management start
  {
    keywords: [""],

    title: "Starting a Collab",

    active: "0",

    path: "messages-and-calls/collab",

    parent: "Messages and calls",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Creating a Project",

    active: "0",

    path: "project-management/creating-and-organizing-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Archiving Project ",

    active: "1",

    path: "project-management/creating-and-organizing-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "View Archived Projects",

    active: "2",

    path: "project-management/creating-and-organizing-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Restoring Archived Project ",

    active: "3",

    path: "project-management/creating-and-organizing-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Edit Project Permission",

    active: "0",

    path: "project-management/manage-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Edit Project Details",

    active: "1",

    path: "project-management/manage-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Sharing Project",

    active: "2",

    path: "project-management/manage-project",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Add members to Project ",

    active: "0",

    path: "project-management/project-members-and-permission-settings",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Change Project Member Permission",

    active: "1",

    path: "project-management/project-members-and-permission-settings",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Remove Member from Project",

    active: "2",

    path: "project-management/project-members-and-permission-settings",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Inviting Company to Project",

    active: "0",

    path: "project-management/invite-external-and-company-to-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Inviting External to Project",

    active: "1",

    path: "project-management/invite--and-company-to-project",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Creating and Managing Ticket Folder",

    active: "0",

    path: "ticket-management/creating-and-managing-task-folder",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Creating New Ticket Folder",

    active: "0",

    path: "ticket-management/creating-and-managing-task-folder",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Edit Ticket Folder",

    active: "1",

    path: "ticket-management/creating-and-managing-task-folder",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Archiving ticket folder",

    active: "3",

    path: "ticket-management/creating-and-managing-task-folder",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Share ticket folder",

    active: "4",

    path: "ticket-management/creating-and-managing-task-folder",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Creating ticket",

    active: "0",

    path: "project-management/creating-and-managing-ticket",

    parent: "Project Management",

    parentPath: "project-management",
  },
  // {
  //   keywords: [""],

  //   title: "Moving Ticket",

  //   active: "1",

  //   path: "project-management/creating-and-managing-ticket",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },

  // {
  //   keywords: [""],

  //   title: "Clone Ticket",

  //   active: "1",

  //   path: "project-management/creating-and-managing-ticket",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },
  // {
  //   keywords: [""],

  //   title: "Archive ticket",

  //   active: "2",

  //   path: "project-management/creating-and-managing-ticket",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },
  // {
  //   keywords: [""],

  //   title: "Restore archived tickets",

  //   active: "3",

  //   path: "project-management/creating-and-managing-ticket",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },
  // {
  //   keywords: [""],

  //   title: "Sharing ticket",

  //   active: "4",

  //   path: "project-management/creating-and-managing-ticket",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },
  // {
  //   keywords: [""],

  //   title: "Make Changes in Ticket",

  //   active: "5",

  //   path: "project-management/creating-and-managing-ticket",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },

  // Project Management end

  // meetings start

  {
    keywords: [""],

    title: "General Meetings",

    active: "0",

    path: "meetings/creating-meetings",

    parent: "Meetings",

    parentPath: "meetings",
  },

  {
    keywords: [""],

    title: "Instant Meetings",

    active: "1",

    path: "meetings/creating-meetings",

    parent: "Meetings",

    parentPath: "meetings",
  },

  {
    keywords: [""],

    title: "One Time Meeting",

    active: "0",

    path: "calendar/managing-calendar",

    parent: "Calendar",

    parentPath: "calendar",
  },

  {
    keywords: [""],

    title: "Import Calendar",

    active: "1",

    path: "calendar/managing-calendar",

    parent: "Calendar",

    parentPath: "calendar",
  },

  {
    keywords: [""],

    title: "Export Calendar",

    active: "2",

    path: "calendar/managing-calendar",

    parent: "Calendar",

    parentPath: "calendar",
  },

  {
    keywords: [""],

    title: "View holiday",

    active: "3",

    path: "calendar/managing-calendar",

    parent: "Calendar",

    parentPath: "calendar",
  },

  {
    keywords: [""],

    title: "Leave on Calendar",

    active: "3",

    path: "calendar/managing-calendar",

    parent: "Calendar",

    parentPath: "calendar",
  },

  {
    keywords: [""],

    title: "Activating Zipy",

    active: "0",

    path: "automation/anydone-zipy",

    parent: "Automation",

    parentPath: "automation",
  },

  {
    keywords: [""],

    title: "Using Zipy",

    active: "1",

    path: "automation/anydone-zipy",

    parent: "Automation",

    parentPath: "automation",
  },

  {
    keywords: [""],

    title: "Adding Bot",

    active: "0",

    path: "automation/anydone-bot",

    parent: "Automation",

    parentPath: "automation",
  },
  {
    keywords: [""],

    title: "Fixed reply",

    active: "0",

    path: "automation/automated-reply",

    parent: "Automation",

    parentPath: "automation",
  },
  {
    keywords: [""],

    title: "Resolution Reply",

    active: "1",

    path: "automation/automated-reply",

    parent: "Automation",

    parentPath: "automation",
  },
  {
    keywords: [""],

    title: "Setting up auto ticket create bot",

    active: "0",

    path: "automation/auto-ticket-create",

    parent: "Automation",

    parentPath: "automation",
  },
  {
    keywords: [""],

    title: "Auto Ticket Creation",

    active: "1",

    path: "automation/auto-ticket-create",

    parent: "Automation",

    parentPath: "automation",
  },
  {
    keywords: [""],

    title: "Roadmap",

    active: "0",

    path: "project-management/roadmap",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Creating a Product Roadmap",

    active: "0",

    path: "project-management/roadmap",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Different Views for Roadmap",

    active: "1",

    path: "project-management/roadmap",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "MeetAssist",

    active: "0",

    path: "messages-and-calls/meetassist",

    parent: "Messages And Calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Starting a MeetAssist",

    active: "0",

    path: "messages-and-calls/meetassist",

    parent: "Messages And Calls",

    parentPath: "messages-and-calls",
  },

  {
    keywords: [""],

    title: "Papers",

    active: "0",

    path: "project-management/papers",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Creating a New Paper",

    active: "0",

    path: "project-management/papers",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Editing a Paper",

    active: "1",

    path: "project-management/papers",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Write with Zipy",

    active: "2",

    path: "project-management/papers",

    parent: "Project Management",

    parentPath: "project-management",
  },
  // {
  //   keywords: [""],

  //   title: "Pin to Message",

  //   active: "3",

  //   path: "project-management/papers",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },
  // {
  //   keywords: [""],

  //   title: "Share the Paper",

  //   active: "4",

  //   path: "project-management/papers",

  //   parent: "Project Management",

  //   parentPath: "project-management",
  // },
  {
    keywords: [""],

    title: "Forms",

    active: "0",

    path: "project-management/forms",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Creating a New Form",

    active: "0",

    path: "project-management/forms",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Editing Form Field",

    active: "1",

    path: "project-management/forms",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Share Form",

    active: "2",

    path: "project-management/forms",

    parent: "Project Management",

    parentPath: "project-management",
  },
  {
    keywords: [""],

    title: "Delete Form",

    active: "3",

    path: "project-management/forms",

    parent: "Project Management",

    parentPath: "project-management",
  },

  {
    keywords: [""],

    title: "Screen Recording",

    active: "6",

    path: "messages-and-calls/manage-activity",

    parent: "Project Management",

    parentPath: "messages-and-calls",
  },
  {
    keywords: [""],

    title: "Creating and Managing Ticket",

    active: "0",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Creating ticket",

    active: "0",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  // {
  //   keywords: [""],

  //   title: "Moving Ticket",

  //   active: "1",

  //   path: "ticket-management/creating-and-managing-ticket",

  //   parent: "Ticket Management",

  //   parentPath: "ticket-management",
  // },
  {
    keywords: [""],

    title: "Clone Ticket",

    active: "1",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Archive ticket",

    active: "2",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Restore archived tickets",

    active: "3",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Sharing ticket",

    active: "4",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Make Changes in Ticket",

    active: "5",

    path: "ticket-management/creating-and-managing-ticket",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Ticket Dashboard",

    active: "0",

    path: "ticket-management/ticket-dashboard",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Your Work",

    active: "0",

    path: "ticket-management/ticket-dashboard",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Project Dashboard",

    active: "1",

    path: "ticket-management/ticket-dashboard",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  // {
  //   keywords: [""],

  //   title: "Viewing all Tickets",

  //   active: "2",

  //   path: "ticket-management/ticket-dashboard",

  //   parent: "Ticket Management",

  //   parentPath: "ticket-management",
  // },

  {
    keywords: [""],

    title: "Ticket Reports",

    active: "0",

    path: "ticket-management/ticket-report",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "View Ticket Reports",

    active: "0",

    path: "ticket-management/ticket-report",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Ticket Workflow",

    active: "0",

    path: "ticket-management/ticket-workflow",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Set Ticket Status Approval",

    active: "0",

    path: "ticket-management/ticket-workflow",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Set Ticket Transition",

    active: "1",

    path: "ticket-management/ticket-workflow",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Generate Checklist",

    active: "0",

    path: "ticket-management/checklist",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Generate Subtasks",

    active: "0",

    path: "ticket-management/subtasks",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Create Custom Fields",

    active: "0",

    path: "ticket-management/custom-field",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Create Sprint Folder",

    active: "0",

    path: "ticket-management/creating-managing-sprint-folders",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
  {
    keywords: [""],

    title: "Create new Sprint ",

    active: "1",

    path: "ticket-management/creating-managing-sprint-folders",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },

  {
    keywords: [""],

    title: "Auto Assign",

    active: "0",

    path: "ticket-management/auto-assign",

    parent: "Ticket Management",

    parentPath: "ticket-management",
  },
];
