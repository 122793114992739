import React, { useState } from "react";

import { Row, Col, Card } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { H3Text, PText } from "../../styleComponents";
import ReactHtmlParser from "react-html-parser";

function QuestionCard(props) {
  //   console.log(props.QuestionAnswers);
  const { QuestionAnswers } = props;
  const [showAnswer, setShowAnswer] = useState("");

  const display = (id) => {
    showAnswer === id ? setShowAnswer("") : setShowAnswer(id);
  };

  return (
    <div>
      {QuestionAnswers?.map((question, index) => (
        <Card
          className="question-card"
          key={index}
          onClick={() => {
            display(index);
          }}
        >
          <Row gutter={[10]}>
            <Col xs={{ span: 22 }} md={{ span: 23 }}>
              {showAnswer !== index ? (
                <H3Text>{question.question}</H3Text>
              ) : (
                <H3Text
                  style={{
                    color: "#162A62",
                  }}
                >
                  {question.question}
                </H3Text>
              )}
            </Col>
            <Col xs={{ span: 2 }} md={{ span: 1 }}>
              {showAnswer !== index ? (
                <DownOutlined
                  onClick={() => {
                    display(index);
                  }}
                />
              ) : (
                <UpOutlined
                  onClick={() => {
                    display(index);
                  }}
                />
              )}
            </Col>
          </Row>
          {showAnswer === index && (
            <Row className="ans-row">
              <Col>
                {question.answer.map((eachText) => (
                  <PText style={{ textAlign: "left" }}>
                    {ReactHtmlParser(eachText)}
                  </PText>
                ))}
                {question.isthereLink ? (
                  <PText>
                    <a
                      href={question.isthereLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {question.isthereLink}
                    </a>
                  </PText>
                ) : null}
              </Col>
            </Row>
          )}
        </Card>
      ))}
    </div>
  );
}

export default QuestionCard;
