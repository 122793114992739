import React, { useEffect, useState } from "react";

import { Col } from "antd";
import {
	ContentDiv,
	NoteDiv,
	H3Text,
	PText,
	StepContent,
} from "../../../../styleComponents";
import ModalPage from "../../../common/ModalPage";

import ReactHtmlParser from "react-html-parser";
import { AZcontent } from "./AnydoneZipy/content";
function LayoutPage(props) {
	//   console.log(props.content);
	const data = props.content ? props.content : AZcontent.content[0];
	const [showImage, setShowImage] = useState("");

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === "Escape") {
				setShowImage("");
			}
		};
		document.addEventListener("keydown", handleKeyPress);
	});
	return (
		<>
			<Col
				xs={{ span: 24 }}
				md={{ span: 10 }}
				lg={{ span: 14 }}
				className="second-column"
			>
				<ContentDiv>
					<H3Text mb="1rem">{ReactHtmlParser(data.title)}</H3Text>
					{data.onetitle && <PText>{ReactHtmlParser(data.onetitle)}</PText>}
					{data.imageSource && (
											<img
												src={data.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(data.imageSource);
												}}
											/>
										)}
					{data.steps && (
						<StepContent>
							{data.steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									
											
											{each.title2 && 
											<div style={{marginLeft:"31px"}}>
											<PText>{ReactHtmlParser(each.title2)}</PText>
											</div>
										}
										

											{each.imageSource2 && (
											<img
												src={each.imageSource2}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}

					{data.title2 && 
											<div style={{marginLeft:"31px"}}>
											<PText>{ReactHtmlParser(data.title2)}</PText>
											</div>
										}
										

											
										{data.imageSource2 && (
											<img
												src={data.imageSource2}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(data.imageSource2);
												}}
											/>
										)}
												
					{data.textcontent && <PText>{ReactHtmlParser(data.textcontent)}</PText>}
					<ModalPage
						shown={showImage}
						close={() => {
							setShowImage("");
						}}
						image={showImage}
					/>
					{data.Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
					
				</ContentDiv>
				{data.add_title && <PText>{ReactHtmlParser(data.add_title)}</PText>}
				{data.imageSourcee && (
											<img
												src={data.imageSourcee}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(data.imageSource);
												}}
											/>
										)}
										{data.add_titlee && <PText>{ReactHtmlParser(data.add_titlee)}</PText>}
				{data.imageSourceee && (
											<img
												src={data.imageSourceee}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(data.imageSource);
												}}
											/>
										)}
				
{data.text1content && (
						<PText>{ReactHtmlParser(data.text1content)}</PText>
					)}
				<ContentDiv>
					{data.add_steps && (
						<StepContent>
							{data.add_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
									
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					{data.text2content && <PText>{ReactHtmlParser(data.text2content)}</PText>}
					{data.Add_Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.Add_Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
				</ContentDiv>
				{data.one_more_title && <PText>{ReactHtmlParser(data.one_more_title)}</PText>}

				<ContentDiv>
				{data.one_more_subtitle && <PText>{ReactHtmlParser(data.one_more_subtitle)}</PText>}

					{data.one_more_steps && (
						<StepContent>
							{data.one_more_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					{data.text3content && <PText>{ReactHtmlParser(data.text3content)}</PText>}
					{data.imageSource3 && (
											<img
												src={data.imageSource3}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(data.imageSource3);
												}}
											/>
										)}
					{data.text4content && <PText>{ReactHtmlParser(data.text4content)}</PText>}

					{data.one_More_Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.one_More_Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
				</ContentDiv>
				{data.more_title && <PText>{ReactHtmlParser(data.more_title)}</PText>}

				<ContentDiv>
					{data.more_steps && (
						<StepContent>
							{data.more_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					{data.text && <PText>{ReactHtmlParser(data.text)}</PText>}

					{data.More_Note ? (
						<NoteDiv>
							<H3Text>Note:</H3Text>
							<ul>
								{data.More_Note?.map((each) => {
									return <li key={each.id}>{each.title}</li>;
								})}
							</ul>
						</NoteDiv>
					) : (
						<></>
					)}
					
				</ContentDiv>
				{data.last_title && <PText>{ReactHtmlParser(data.last_title)}</PText>}
				{data.lasttextcontent && (
						<PText>{ReactHtmlParser(data.lasttextcontent)}</PText>
					)}
				<ContentDiv>
					{data.last_steps && (
						<StepContent>
							{data.last_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					
				</ContentDiv>
				{data.lastt_text && <PText>{ReactHtmlParser(data.lastt_text)}</PText>}
				{data.lastt_title && <PText>{ReactHtmlParser(data.lastt_title)}</PText>}
			
				<ContentDiv>
					{data.lastt_steps && (
						<StepContent>
							{data.lastt_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
									</>
								);
							})}
						</StepContent>
					)}
					{data.lastt_text2 && <PText>{ReactHtmlParser(data.lastt_text2)}</PText>}
				</ContentDiv>
				{data.final_title && <PText>{ReactHtmlParser(data.final_title)}</PText>}
			
				<ContentDiv>
					{data.final_steps && (
						<StepContent>
							{data.final_steps?.map((each, i) => {
								return (
									<>
										<li>
											<span>{i + 1}</span>
											<PText>{ReactHtmlParser(each.title)}</PText>
										</li>
										{each.imageSource && (
											<img
												src={each.imageSource}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSource);
												}}
											/>
										)}
					{each.final_text2 && <PText>{ReactHtmlParser(each.final_text2)}</PText>}
					{each.imageSourceFinal && (
											<img
												src={each.imageSourceFinal}
												alt="not-loaded"
												width="100%"
												style={{
													marginBottom: "2rem",
													boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
												}}
												onClick={() => {
													setShowImage(each.imageSourceFinal);
												}}
											/>
										)}

									</>
								);
							})}
						</StepContent>
					)}
					
				</ContentDiv>
				<ContentDiv>
					{data.isAutoticketAssign && (
						<p>Here is something specific for this</p>
					)}
					{data.extra_title && <PText>{data.extra_title}</PText>}
					{data.extraDemoImageSource && <PText>Here is the demo</PText>}
				</ContentDiv>
			</Col>
			<Col
				xs={{ span: 0 }}
				md={{ span: 5 }}
				lg={{ span: 4 }}
				className="third-column"
			></Col>
		</>
	);
}

export default LayoutPage;
