import Project from "../../../../../new/icons/project.svg";
const imageUrl="https://storage.googleapis.com/anydone-static-files/assets/help-page/flowcess/"
export const ABcontent = {
	title: "Run Flowcess",
	link: "anydone-running-flowcess",
	content: [
		
		{
			id: 0,
			title: "Run Flowcess",
			onetitle:`After <a href="anydone-create-flowcess?tabid=0">creating the Flowcess,</a> you need to run it. Before doing so, make sure that you have <a href="anydone-app?tabid=0">created an app</a> for the Flowcess to run. Once you are done, follow the steps to run the Flowcess.`,

			steps: [
				{
					id: 1,
					title: `Log in to the anydone dashboard and click on the <b><img src=${Project} alt="project" height="12" width="12"/></b> icon.`,
				},
				{
					id: 1,
					title: `Navigate to your <b>project</b> and click on it.`,
				},
				{
					id: 1,
					title: `Click on the <b>Flowcess folder</b> and select a Flowcess that you want to run.`,
				},
				{
					id: 1,
					title: `Here, you will see all your created Flowcess.<br>`,
					imageSource: `${imageUrl}run-flowcess.png`
				},
				
			],
			title2:`If the Flowcess is unpublished, the <b>Publish</b> button will be green and remember, to run the Flowcess, you need to publish it first, so click the <b>Publish</b> button.<br><br>

			Once you publish the Flowcess, then, click on <b>Run</b> button, here, you’ll see three options: <b>Try Now, Time Based,</b> and, <b>Event Based.</b><br><br>`,
			imageSource2: `${imageUrl}run-flowcess-now-option.png`,




			add_title:"<b><u>Run Flowcess Time Based</u></b><br><br>",
			text1content:"<b>if selected “Now”</b>",
			add_steps:[
				{
					id:1,
					title:`Hover over <b>Time Based</b> and select the <b>'Now'</b> option.`
				},
				{
					id:2,
					title:`Enter a name for the instance.`
				},
				{
					id:3,
					title:`In the <b>Value</b> placeholder, add the probable prompt or possible query that your customer or user might enter.`
				},
				{
					id:4,
					title:`Then, click on the <b>Run</b> button <br>`,
					imageSource: `${imageUrl}time-based-now.gif`


				},
			],
			one_more_title:"<b>Now, to view the output follow these steps:</b>",
			one_more_steps:[
				{
					id:1,
					title:`Click on the <b>Running Instance.</b>`
				},
				{
					id:2,
					title:`Wait until the instance running gets complete.`
				},
				{
					id:3,
					title:`Then, click on the drop-down menu from the top right corner.`
				},
				{
					id:4,
					title:`From the options, select <b>Completed.</b>`
				},
				{
					id:5,
					title:`Now, click on <b>Sessions.</b>`
				},
				{
					id:6,
					title:`Click on the vertical three dots and select <b>View Output.</b>`
				},
				{
					id:7,
					title:`Here, you can see the output of your Flowcess`,
					imageSource: `${imageUrl}time-based-now-viewoutput.gif`

				},
			],
			more_title:`<b>If selected "Schedule"</b>`,
			more_steps:[
				{
					id:1,
					title:`Hover over <b>Time Based</b> and select the <b>Schedule</b> option.`
				},
				{
					id:1,
					title:`Enter a name for the instance.`
				},
				{
					id:1,
					title:`In the <b>Value</b> placeholder, add the probable prompt or possible query that your customer or user might enter.`
				},
			
				{
					id:1,
					title:`Then, click on the <b>Run</b> button. `
				},
			],
			last_title:"<b><u>Run Flowcess Event Based</u></b>",
			lasttextcontent:`<br>As we’ve already mentioned above, before running the Flowcess you need to decide where you want the Flowcess to run. Then, you need to <a href="anydone-app?tabid=0">create an app</a> based on your preferences. Once you are done, publish the Flowcess and follow the below steps.`,
			last_steps:[
				{
					id:1,
					title:`Click on the <b>Run</b> button.`
				},
				{
					id:2,
					title:`Select<b> Event Based</b> option.`,
					imageSource: `${imageUrl}run-flowcess-anydone.png`

				},
			],
			lastt_title:"<b><u>If selected Anydone</u></b>",
			lastt_steps:[
				{
					id:1,
					title:`Under Anydone, select the Anydone Cospace where you want to trigger the Flowcess.`
				},
				{
					id:2,
					title:`Finally, click the <b>Run</b> button.`,
					imageSource: `${imageUrl}run-anydone-flowcess-first.gif`

				},
			],
			final_title:"<b><u>If selected Chat Plugin</u></b>",
			final_steps:[
				{
					id:1,
					title:`Click on the <b>Run</b> button.`
				},
				{
					id:2,
					title:`Select <b>Event Based</b> option.`
				},
				{
					id:3,
					title:`Under the Chat <b>Plugin</b>, select the chat plugin you created to trigger the Flowcess.`
				},
				{
					id:4,
					title:`Finally, click the <b>Run</b> button.`,
					imageSource: `${imageUrl}run-chatplugin-flowcess.gif`,
					final_text2:`
					After clicking the <b>Run</b> button, a new prompt will appear. Here, you can copy the chat plugin <b>URL</b>, paste it into your browser tab, and interact with the chat plugin. You can also share this URL. <br><br>
Additionally, you can copy the <b>Embed Code</b> and paste it into your website's HTML file to integrate the chat plugin directly into your site.`,
		imageSourceFinal:`${imageUrl}run-flowcess-chatplugins.png`,

				}
			],
},
// 		{
// 			id: 1,
// 			title: "Test Flowcess",

// 			steps: [
// 				{
// 					id: 1,
// 					title: `Before creating a Flowcess, you need to create Automation Tools. These tools define your automation processes to manage your business operations.<br><br>

// <b>Types of Automation Tools:</b><br>

// 1)&nbsp AI<br>
					
//   &nbsp &nbsp-> <a>Entity recognition</a><br>
//   &nbsp &nbsp-> <a>Intent collection</a><br>
//   &nbsp &nbsp-> <a>Knowledge base creation</a><br>
//   &nbsp &nbsp-> <a>Knowledge graph organization</a><br>
//   &nbsp &nbsp-> <a>Automated replies</a><br>
//   &nbsp &nbsp-> <a>Integrating third-party models</a><br>
// `},

				
// 			],
// 		},
// 		{
// 			id: 2,
// 			title: "View Configuration",

// 			steps: [
// 				{
// 					id: 1,
// 					title: `The App Directory allows you to create apps where your created Flowcess will be triggered and run. Creating an app is simple, and there are different types you can choose from:<br><br>
// -><a>Anydone</a><br>
// -><a>Chat Plugin</a><br>
// -><a>Live Capture</a><br>
// -><a>Webhook</a><br>
// -><a>Mail</a><br>
// -><a>Slack</a><br>

// `},

				
// 			],
// 		},
// 		{
// 			id: 3,
// 			title: "View Flowcess Log",

// 			steps: [
// 				{
// 					id: 1,
// 					title: `Before creating a Flowcess, you need to create a Flowcess folder first, follow the step to create one:<br><br>
// ->Login to your anydone<br>
// ->Click on the <img src=${Project} alt="project" height="12" width="12"/> icon<br>
// ->You can create a new project or click on the already created project<br>
// ->Click on the + button right to your project name<br>
// ->Click on the Flowcess folder<br>
// ->Give it a name<br>
// ->Click on Create button<br><br>

// Once the  the Flowcess folder is created now you need to create flowcess, to create one follow the steps:<br><br>

// ->Click on the + button from the right corner<br>
// ->Here, add image, title and description<br>
// ->The, hit "Create" button <br>




// `},

				
// 			],
// 		},
// 		{
// 			id: 4,
// 			title: "View Flowcess Output",

// 			steps: [
// 				{
// 					id: 1,
// 					title: `Before creating a Flowcess, you need to create a Flowcess folder first, follow the step to create one:<br><br>
// ->Login to your anydone<br>
// ->Click on the <img src=${Project} alt="project" height="12" width="12"/> icon<br>
// ->You can create a new project or click on the already created project<br>
// ->Click on the + button right to your project name<br>
// ->Click on the Flowcess folder<br>
// ->Give it a name<br>
// ->Click on Create button<br><br>

// Once the  the Flowcess folder is created now you need to create flowcess, to create one follow the steps:<br><br>

// ->Click on the + button from the right corner<br>
// ->Here, add image, title and description<br>
// ->The, hit "Create" button <br>




// `},


				
// 			],
// 		},
// 		{
// 			id: 5,
// 			title: "View Flowcess Information",

// 			steps: [
// 				{
// 					id: 1,
// 					title: `Before creating a Flowcess, you need to create a Flowcess folder first, follow the step to create one:<br><br>
// ->Login to your anydone<br>
// ->Click on the <img src=${Project} alt="project" height="12" width="12"/> icon<br>
// ->You can create a new project or click on the already created project<br>
// ->Click on the + button right to your project name<br>
// ->Click on the Flowcess folder<br>
// ->Give it a name<br>
// ->Click on Create button<br><br>

// Once the  the Flowcess folder is created now you need to create flowcess, to create one follow the steps:<br><br>

// ->Click on the + button from the right corner<br>
// ->Here, add image, title and description<br>
// ->The, hit "Create" button <br>




// `},


				
// 			],
// 		},
// 		{
// 			id: 6,
// 			title: "View Flowcess Instance	",

// 			steps: [
// 				{
// 					id: 1,
// 					title: `Before creating a Flowcess, you need to create a Flowcess folder first, follow the step to create one:<br><br>
// ->Login to your anydone<br>
// ->Click on the <img src=${Project} alt="project" height="12" width="12"/> icon<br>
// ->You can create a new project or click on the already created project<br>
// ->Click on the + button right to your project name<br>
// ->Click on the Flowcess folder<br>
// ->Give it a name<br>
// ->Click on Create button<br><br>

// Once the  the Flowcess folder is created now you need to create flowcess, to create one follow the steps:<br><br>

// ->Click on the + button from the right corner<br>
// ->Here, add image, title and description<br>
// ->The, hit "Create" button <br>




// `},


				
// 			],
// 		},
		

	],
};

