import React, { useEffect, useState } from "react";
import { Col, Menu } from "antd";
import GoBack from "../../../../common/GoBack";
import { useLocation, useNavigate } from "react-router-dom";

import {
  RowDiv,
  SecStartedContent,
  Stickydiv,
} from "../../../../../styleComponents";
import BorderDiv from "../../../../common/BorderDiv";
// import { Helmet } from "react-helmet";
import MainHeader from "../../../../common/MainHeader";
import { ABcontent } from "./content";
import LayoutPage from "../LayoutPage";
import { Waypoint } from "react-waypoint";

function AnydoneCreationFlowcess({ handleWaypointEnter, handleWaypointLeave }) {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const tabsValue = searchParams.get("tabid");
  const [active, setActive] = useState(tabsValue);

  const handleClick = (e) => {
    setActive(e.key);
  };
  const [content, setContent] = useState();
  useEffect(() => {
    setActive(tabsValue);
    setContent(ABcontent.content[tabsValue]);
  console.log(tabsValue,999)
  }, [tabsValue]);
  return (
    <div>
      <MainHeader heading={ABcontent.title} />
      <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

      <SecStartedContent>
        {/* <ButtonTags selectedID={4} /> */}
        <RowDiv>
          <Col
            xs={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 4 }}
            className="first-column"
          >
            <Stickydiv height="30rem">
              <GoBack title="Automation" path="automation" />
              <BorderDiv mb={1} mt={1} />
              <div className="started-items">
                <Menu
                  onClick={handleClick}
                  selectedKeys={active}
                  mode="vertical"
                  className="side-menu"
                >
                  {ABcontent.content.map((items, index) => (
                    <Menu.Item
                      key={index}
                      onClick={() => {
                        navigate(`/automation/anydone-create-flowcess?tabid=${items.id}`);
                        window.scrollTo({
  top: 0,
  behavior: 'smooth'
});
                      }}
                    >
                      {items.title}
                    </Menu.Item>
                  ))}
                </Menu>
              </div>
            </Stickydiv>
          </Col>
          {active && <LayoutPage content={content} />}
        </RowDiv>
      </SecStartedContent>
    </div>
  );
}

export default AnydoneCreationFlowcess;
// import React, { useEffect, useState } from "react";
// import { Col, Menu } from "antd";
// import GoBack from "../../../../common/GoBack";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   RowDiv,
//   SecStartedContent,
//   Stickydiv,
// } from "../../../../../styleComponents";
// import BorderDiv from "../../../../common/BorderDiv";
// import MainHeader from "../../../../common/MainHeader";
// import { ABcontent } from "./content";
// import LayoutPage from "../LayoutPage";
// import { Waypoint } from "react-waypoint";

// function AnydoneCreationFlowcess({ handleWaypointEnter, handleWaypointLeave }) {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const searchParams = new URLSearchParams(location.search);
//   const tabsValue = searchParams.get("tabid");
//   const [active, setActive] = useState(tabsValue);
//   const [content, setContent] = useState();

//   const handleClick = (e) => {
//     setActive(e.key);
//     const filteredContent = ABcontent.content.find(
//       (item) => item.title === e.key
//     );
//     setContent(filteredContent);
//     navigate(`/automation/anydone-create-flowcess?tabid=${e.key}`);
//   };

//   useEffect(() => {
//     const filteredContent = ABcontent.content.find(
//       (item) => item.title === tabsValue
//     );
//     setActive(tabsValue);
//     setContent(filteredContent);
//   }, [tabsValue]);

//   return (
//     <div>
//       <MainHeader heading={ABcontent.title} />
//       <Waypoint onEnter={handleWaypointEnter} onLeave={handleWaypointLeave} />

//       <SecStartedContent>
//         <RowDiv>
//           <Col
//             xs={{ span: 24 }}
//             md={{ span: 5 }}
//             lg={{ span: 4 }}
//             className="first-column"
//           >
//             <Stickydiv height="19rem">
//               <GoBack title="Automation" path="automation" />
//               <BorderDiv mb={1} mt={1} />
//               <div className="started-items">
//                 <Menu
//                   onClick={handleClick}
//                   selectedKeys={active}
//                   mode="vertical"
//                   className="side-menu"
//                 >
//                   {ABcontent.content.map((item) => (
//                     <Menu.Item key={item.title}>{item.title}</Menu.Item>
//                   ))}
//                 </Menu>
//               </div>
//             </Stickydiv>
//           </Col>
//           {content && <LayoutPage content={content} />}
//         </RowDiv>
//       </SecStartedContent>
//     </div>
//   );
// }

// export default AnydoneCreationFlowcess;
