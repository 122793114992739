import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CAMTcontent = {
  title: "Creating and Managing Ticket",
  link: "creating-and-managing-ticket",
  content: [
    {
      id: 0,
      title: "Creating ticket",
      steps: [
        {
          id: 1,
          title: `From your anydone dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> icon.`,
        },
        {
          id: 2,
          title: "Click on the <b>Create New Ticket</b> option.",
        },
        {
          id: 3,
          title:
            "Here, from the top, you can select a project, task folder, and board to create a ticket.",
        },
        {
          id: 4,
          title: "Now, add all the necessary details.",
        },
        {
          id: 5,
          title: "Then, click on the <b>Create</b> button.",
          imageSource: `${GifeBaseUrl}createing-ticket.gif`,
        },
      ],
      add_title: "Alternatively,",
      add_steps: [
        {
          id: 1,
          title: `Go to your dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${taskicon} alt="plus" height="14px" width="14px"/> icon.`,
        },
        {
          id: 3,
          title:
            " Select the project then its folder where you want to create new ticket.",
        },
        {
          id: 4,
          title: `Click on the <img src=${plus} alt="plus" height="14px" width="14px"/> button from the right side corner.`,
        },
        {
          id: 5,
          title: `Add necessary details and click on <b>Create</b> button `,
          imageSource: `${GifeBaseUrl}ticket-create-alternative.gif`,
        },
      ],
    },

    {
      id: 1,
      title: "Clone Ticket ",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> project icon.`,
        },
        {
          id: 2,
          title: `Navigate to the project and click on the ticket folder whose ticket you want to clone.`,
        },
        {
          id: 3,
          title:
            "Click on the <b>Task, Story, Bug,</b> or <b>Epic</b> ticket category from where you want to clone the ticket.",
        },
        {
          id: 4,
          title:
            "Now hover over the ticket that you want to clone and click on three dots.",
        },
        {
          id: 5,
          title: "Click on the <b>Clone</b> option.",
        },
        {
          id: 6,
          title: "Here, edit ticket details if you want to before cloning it.",
        },
        {
          id: 7,
          title: "Then, hit <b>Create<b> option.",
          imageSource: `${GifeBaseUrl}cloning-ticket.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Archive ticket",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> project icon.`,
        },
        {
          id: 2,
          title: `Navigate to the project and click on the ticket folder whose ticket you want to archive.`,
        },
        {
          id: 3,
          title:
            "Click on the <b>Task, Story, Bug,</b> or <b>Epic</b> ticket category from where you want to archive the ticket.",
        },
        {
          id: 4,
          title:
            "Now hover over the ticket that you want to archive and click on three dots.",
        },
        {
          id: 5,
          title: "Click on the <b>Archive</b> option.",
        },

        {
          id: 6,
          title: "New prompt will pop, click on <b>Yes</b> option",
          imageSource: `${GifeBaseUrl}archiving-ticket.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Restore archived tickets",
      steps: [
        {
          id: 1,
          title: `Login to your dashboard.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon.`,
        },
        {
          id: 3,
          title:
            "From the top left, click on the downward-facing arrow next to <b>'Project'</b>.",
        },
        {
          id: 4,
          title: "Select <b>Settings</b> option",
        },
        {
          id: 5,
          title: "Click on the <b>Archived Tickets</b>",
          imageSource: `${GifeBaseUrl}restore-archive-tickets-anydone.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "Sharing ticket",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> project icon.`,
        },
        {
          id: 2,
          title: `Navigate to the project and click on the ticket folder whose ticket you want to share.`,
        },
        {
          id: 3,
          title:
            "Click on the <b>Task, Story, Bug,</b> or <b>Epic</b> ticket category from where you want to share the ticket.",
        },
        {
          id: 4,
          title:
            "Now hover over the ticket that you want to share and click on three dots.",
        },
        {
          id: 5,
          title: "Click on the <b>Share</b> option.",
        },

        {
          id: 6,
          title:
            "New prompt will pop, here copy the link and share it with your peers",
          imageSource: `${GifeBaseUrl}sharing-tickets.gif`,
        },
      ],
    },
    // {
    //   id: 5,
    //   title: "Sharing ticket",
    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> project icon.`,
    //     },
    //     {
    //       id: 2,
    //       title: `Navigate to the project and click on the ticket folder whose ticket you want to share.`,
    //     },
    //     {
    //       id: 3,
    //       title:
    //         "Click on the <b>Task, Story, Bug,</b> or <b>Epic</b> ticket category from where you want to share the ticket.",
    //     },
    //     {
    //       id: 4,
    //       title:
    //         "Now hover over the ticket that you want to share and click on three dots.",
    //     },
    //     {
    //       id: 5,
    //       title: "Click on the <b>Share</b> option.",
    //     },

    //     {
    //       id: 6,
    //       title:
    //         "New prompt will pop, here copy the link and share it with your peers",
    //       imageSource: `${GifeBaseUrl}tickets-move.gif`,
    //     },
    //   ],
    // },
    {
      id: 5,
      title: "Make Changes in Ticket",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${plus} alt="plus" height="14px" width="14px"/> project icon.`,
        },
        {
          id: 2,
          title: `Navigate to the project and click on the ticket folder whose ticket you want to edit.`,
        },
        {
          id: 3,
          title:
            "Click on the <b>Task, Story, Bug,</b> or <b>Epic</b> ticket category from where you want to make changes to the ticket.",
        },
        {
          id: 4,
          title: "Now make changes to the tickets.",
          imageSource: `${GifeBaseUrl}updating-tickets.gif`,
        },
      ],
    },
  ],
};
