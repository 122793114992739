import React, { useState } from "react";
import { SearchDiv } from "../../styleComponents";
import searchIcon from "../../new/icons/search_icon.svg";
function SearchBar(props) {
	const { state, searchFunction } = props;
	const [searchText, setSearchText] = useState(state);

	return (
		<SearchDiv>
			<img src={searchIcon} alt="search icon" />
			<input
				placeholder="Find anything"
				className="input-search"
				onChange={(e) => setSearchText(e.target.value)}
				value={searchText}
				onKeyDown={(e) => {
					if (e.key === "Enter" && searchText !== "") {
						searchFunction(e.target.value.toLocaleLowerCase());
					}
				}}
			/>
		</SearchDiv>
	);
}

export default SearchBar;
