const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/messages-and-calls/";

export const SAPcontent = {
  title: "Setting and Preferences",
  link: "setting-preferences",
  content: [
    {
      id: 0,
      title: "General Settings",
      steps: [
        {
          id: 1,
          title: `From the left-hand side of your dashboard, click on the <b>Workspace icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b> option.`,
        },
        {
          id: 3,
          title: `Under Administration, click on <b>Settings & Permissions</b>.`,
        },
        {
          id: 4,
          title: `Now click on the <b>General</b>.`,
          imageSource: `${GifBaseUrl}/general-setting.gif`,
        },
      ],
      text1content: `<i>Under General Setting, you can change the following options</i>.`,
      isgeneralSetting: true,
    },
    // {
    //   id: 1,
    //   title: "Account Settings",

    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard and click on your <b>workspace</b> icon.`,
    //     },
    //     {
    //       id: 2,
    //       title: `Click on the <b>Workspace Settings</b>.`,
    //     },
    //     {
    //       id: 3,
    //       title: `Under <b>Administration</b>, click on <b>Settings & Permissions</b>.`,
    //     },
    //     {
    //       id: 4,
    //       title: `Click on the <b>Account</b> option.`,
    //     },
    //     {
    //       id: 5,
    //       title: `Tick the option to allow <b>guest member</b> invitation.`,
    //     },
    //     {
    //       id: 6,
    //       title: `Then click on the <b>Save</b> button.`,
    //       imageSource: `${GifBaseUrl}/account-setting.gif`,
    //     },
    //   ],
    // },
    {
      id: 1,
      title: "Message Setting",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>workspace icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under Administration, click on <b>Settings & Permissions</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>Messages</b>.`,
        },
        {
          id: 5,
          title: `Under CoSpace messages, set <b>who can create cospaces</b> and <b>who can archive cospaces</b>.`,
        },
        {
          id: 6,
          title: `Now, tick the given to make necessary.`,
        },
        {
          id: 7,
          title: `Then click on the <b>Save</b> button.`,
          imageSource: `${GifBaseUrl}/message-setting.gif`,
        },
      ],
    },
    // {
    //   id: 3,
    //   title: "Meeting Setting",
    //   steps: [
    //     {
    //       id: 1,
    //       title: `Go to your dashboard and click on your <b>workspace</b> icon.`,
    //     },
    //     {
    //       id: 2,
    //       title: `Click on the <b>Workspace Settings</b>.`,
    //     },
    //     {
    //       id: 3,
    //       title: `Under <b>Administration</b>, click on <b>Settings & Permissions</b>.`,
    //     },
    //     {
    //       id: 4,
    //       title: `Click on the <b>Meet</b>.`,
    //     },
    //     {
    //       id: 5,
    //       title: `Make changes according to your preferences.`,
    //     },
    //     {
    //       id: 6,
    //       title: `Then click on the <b>Save</b> button.`,
    //       imageSource: `${GifBaseUrl}meeting-setting.gif`,
    //     },
    //   ],
    // },
    {
      id: 2,
      title: "Workdays and Holidays Setting",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>workspace icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under Administration, click on <b>Settings & Permissions</b>.`,
        },
        {
          id: 4,
          title: `Click on the <b>Workdays & Holidays</b>.`,
        },
        {
          id: 5,
          title: `Make changes according to your preferences.`,
        },
        {
          id: 6,
          title: `Then click on the <b>Save</b> button.`,
          imageSource: `${GifBaseUrl}workdays-and-holidays-setting.gif`,
        },
      ],
    },
  ],
};
