import plus from "./../../../../../new/icons/plus.png";
import pencil from "./../../../../../new/icons/pencil.svg";
import box from "./../../../../../new/icons/box.svg";
import round from "./../../../../../new/icons/round.svg";
import deleteIcon from "./../../../../../new/icons/delete.svg";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const WScontent = {
  title: "Workspace Setting",
  link: "workspace-setting",
  content: [
    {
      id: 0,
      title: "Creating new Workspace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your workspace icon.`,
        },
        {
          id: 2,
          title: ` Click on <b>Create New Workspace</b> option.`,
        },
        {
          id: 3,
          title: `Enter a required details.`,
        },
        {
          id: 4,
          title: `Hit <b>Create</b> option.`,
        },
        {
          id: 5,
          title: `Now, invite members to your workspace and get started.`,
          imageSource: `${GifBaseUrl}create-new-workspace.gif`,
        },
      ],

      add_title: "Alternatively,",
      add_steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on your <b>Workspace icon</b> from top left.`,
        },
        {
          id: 2,
          title: "Select <b>Workspace settings</b> from the options.",
        },
        {
          id: 3,
          title: `Click on <b>Workspaces</b>.`,
        },
        {
          id: 4,
          title: `Here, click on <img src=${plus} alt="plus" height="14px" width="14px"/> button.`,
        },
        {
          id: 5,
          title: "Click on <b>Create Workspace</b>.",
        },
        {
          id: 6,
          title: `Enter all the required details.`,
        },
        {
          id: 7,
          title: `Hit <b>Create</b> option.`,
        },
        {
          id: 8,
          title: "Now, invite members to your workspace and get started.",
          imageSource: `${GifBaseUrl}create-new-workspace-alternative.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Edit Workspace Details",

      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>workspace icon</b> .`,
        },
        {
          id: 2,
          title: `From the drop-down menu, select <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under the Account, click on <b>Workspaces</b>.`,
        },
        {
          id: 4,
          title: `Navigate to the workspace whose details you want to edit.`,
        },
        {
          id: 5,
          title: `Now, click on the <img src=${pencil} alt="pencil-icon" /> icon.`,
        },
        {
          id: 6,
          title: `Then, make the necessary changes and click on the <b>Update</b> button.`,
          imageSource: `${GifBaseUrl}edit-worksapce-detail.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Delete Workspace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>Workspace icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under the Account, click on <b>Workspaces</b>.`,
        },
        {
          id: 4,
          title: `Go to the workspace that you want to delete.`,
        },
        {
          id: 5,
          title: `Click on the <img src=${deleteIcon} alt="delete-icon" height="16px" width:"16px"/> button.`,
        },
        {
          id: 6,
          title: `A new prompt will appear, where you should check both checkboxes.`,
        },
        {
          id: 7,
          title: `Write the name of the workspace that you want to delete.`,
        },
        {
          id: 8,
          title: `Click on the <b>Confirm</b> button.`,
        },
        {
          id: 9,
          title: `Once again, a new prompt will appear where you need to enter your anydone account password.`,
        },
        {
          id: 10,
          title: `Then, click on the Confirm button again.`,
          imageSource: `${GifBaseUrl}delete-workspace.gif`,
        },
      ],
    },
    {
      id: 3,
      title: "Archive Workspace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>Workspace icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under the Account, click on <b>Workspaces</b>.`,
        },
        {
          id: 4,
          title: `Go to the workspace that you want to archive.`,
        },
        {
          id: 5,
          title: `Click on the <img src=${box} alt="box-like"  height="16px" width="18px"/> icon.`,
        },
        {
          id: 6,
          title: `A new prompt will appear, click on the <b>Yes</b> button.`,
          imageSource: `${GifBaseUrl}archiving-workspace.gif`,
        },
      ],
    },
    {
      id: 4,
      title: "Unarchive Workspace",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard and click on your <b>Workspace icon</b>.`,
        },
        {
          id: 2,
          title: `Click on the <b>Workspace Settings</b>.`,
        },
        {
          id: 3,
          title: `Under the Account, click on <b>Workspaces</b>.`,
        },
        {
          id: 4,
          title: `Go to the workspace that you want to unarchive.`,
        },
        {
          id: 5,
          title: `Click on the <img src=${round} alt="round"  height="16px" width="18px"/> icon.`,
        },
        {
          id: 6,
          title: `A new prompt will appear, click on the <b>Yes</b> button.`,
          imageSource: `${GifBaseUrl}unarchiving-workspace.gif`,
        },
      ],
    },
  ],
};
