export function transformResponse(response, token) {
	const transformedResponse = {
		user: {
			accounttype: response.user.accountType === "EMPLOYEE" ? 3 : 0,
			employee: {
				account: {
					accountid: response.user.accountId,
					email: response.user.email,
					accounttype: response.user.accountType === "EMPLOYEE" ? 3 : 0,
					profilepic: response.user.profilePic,
					status: response.user.status === "ACCOUNT_VERIFIED" ? 2 : 0,
					isemailverified: response.user.isEmailVerified,
					fullname: response.user.fullName,
					employeetype: response.user.accountType === "EMPLOYEE" ? 0 : 0,
				},
			},
		},
		token,
		serviceprovider: {
			accountid: response.session[0].serviceProvider.accountId,
			email: "",
			phone: "",
			accounttype:
				response.session[0].serviceProvider.accountType === "EMPLOYEE" ? 3 : 0,
			profilepic: response.session[0].serviceProvider.profilePic,
			fullname: response.session[0].serviceProvider.fullName,
			refid: response.session[0].serviceProvider.refId,
			code: response.session[0].serviceProvider.code,
			createdat: parseInt(response.session[0].serviceProvider.createdAt),
			createdby: {
				accountid: response.session[0].serviceProvider.createdBy.accountId,
				fullname: response.session[0].serviceProvider.createdBy.fullName,
			},
			role:
				response.session[0].serviceProvider.role === "WORKSPACE_MEMBER" ? 0 : 0,
			employeetype:
				response.session[0].serviceProvider.employeeType === "MEMBER" ? 0 : 0,
		},
	};

	if (response.session[0].colors) {
		transformedResponse.serviceprovider.colors = response.session[0].colors;
	}

	if (response.session[0].projects) {
		transformedResponse.serviceprovider.projects =
			response.session[0].projects.map((project) => {
				return {
					projectid: project.projectId,
					name: project.name,
				};
			});
	}

	return transformedResponse;
}
