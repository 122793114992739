import { notification } from "antd";
import "antd/dist/antd.css";

const NotificationType = {
    INFO: "info",
    SUCCESS: "success",
    ERROR: "error",
};

export const showSuccessNotification = (message, description) => {
    notification[NotificationType.SUCCESS]({
        message,
        description,
    });
};

export const showErrorNotification = (message, description) => {
    notification[NotificationType.ERROR]({
        message,
        description: description || "Please try again",
    });
};
