import React, { useEffect, useState } from "react";

import { Col } from "antd";
import {
  ContentDiv,
  NoteDiv,
  H3Text,
  PText,
  StepContent,
} from "../../../../styleComponents";
import ModalPage from "../../../common/ModalPage";

import ReactHtmlParser from "react-html-parser";
import { MEcontent } from "./MessageExperience/content";
function LayoutPage(props) {
  //   console.log(props.content);
  const data = props.content ? props.content : MEcontent.content[0];
  const [showImage, setShowImage] = useState("");
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape") {
        setShowImage("");
      }
    };
    document.addEventListener("keydown", handleKeyPress);
  });

  return (
    <>
      <Col
        xs={{ span: 24 }}
        md={{ span: 10 }}
        lg={{ span: 14 }}
        className="second-column"
      >
        <ContentDiv>
          <H3Text mb="1rem">{data.title}</H3Text>
          {data.onetitle && (
            <PText>
              <b>{data.onetitle}</b>
            </PText>
          )}

          {data.steps && (
            <StepContent>
              {data.steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          <ModalPage
            shown={showImage}
            close={() => {
              setShowImage("");
            }}
            image={showImage}
          />
          {data.Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                <i>
                  {data.Note?.map((each) => {
                    return <li key={each.id}>{each.title}</li>;
                  })}
                </i>
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
          {data.text1content && (
            <PText>{ReactHtmlParser(data.text1content)}</PText>
          )}
        </ContentDiv>
        {data.add_title && (
          <PText>
            {data.add_title === "Alternatively," ? (
              <b>
                <i>{data.add_title}</i>
              </b>
            ) : (
              <b>{data.add_title}</b>
            )}
          </PText>
        )}
        {data.add_title_bold && (
          <PText>
            <b>{data.add_title_bold}</b>
          </PText>
        )}

        <ContentDiv>
          {data.add_steps && (
            <StepContent>
              {data.add_steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.text2content && <PText>{data.contentplus}</PText>}
          {data.Add_Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.Add_Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
        </ContentDiv>
        {data.one_more_title && <PText>{data.one_more_title}</PText>}

        <ContentDiv>
          {data.one_more_steps && (
            <StepContent>
              {data.one_more_steps?.map((each, i) => {
                return (
                  <>
                    <li>
                      <span>{i + 1}</span>
                      <PText>{ReactHtmlParser(each.title)}</PText>
                    </li>
                    {each.imageSource && (
                      <img
                        src={each.imageSource}
                        alt="not-loaded"
                        width="100%"
                        style={{
                          marginBottom: "2rem",
                          boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                        }}
                        onClick={() => {
                          setShowImage(each.imageSource);
                        }}
                      />
                    )}
                  </>
                );
              })}
            </StepContent>
          )}
          {data.text3content && <PText>{data.text3content}</PText>}
          {data.one_More_Note ? (
            <NoteDiv>
              <H3Text>Note:</H3Text>
              <ul>
                {data.one_More_Note?.map((each) => {
                  return <li key={each.id}>{each.title}</li>;
                })}
              </ul>
            </NoteDiv>
          ) : (
            <></>
          )}
        </ContentDiv>
        <ContentDiv>
          {data.isgeneralSetting && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div>
                <PText>
                  <b>
                    Under General Setting, you can change the following options.
                  </b>
                </PText>
              </div>
              <div>
                <img
                  src={
                    "https://static-edge-a.anydone.com/assets/help-page/messages-and-calls/general-settings.PNG"
                  }
                  alt="not-loaded"
                  width="100%"
                  style={{
                    marginTop: "2rem",
                    boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                  }}
                  onClick={() => {
                    setShowImage(
                      "https://static-edge-a.anydone.com/assets/help-page/messages-and-calls/general-settings.PNG"
                    );
                  }}
                />
              </div>
              <div>
                <br />
                <PText>
                  <b>Workspace Language</b>
                  <br />
                  Click on the Workspace Language drop-down menu option and
                  select your preferred language for your Workspace.
                </PText>
              </div>
              <div>
                <PText>
                  <b>Email Display</b>
                  <br />
                  You can set who can view the company member's email addresses.
                  You can change either No one or Members and guests of the new
                  company.
                </PText>
              </div>
              <div>
                <PText>
                  <b>Who can invite members?</b>
                  <br />
                  You can choose who can invite members to your workspace. You
                  can set either Admin or Everyone except guests.
                </PText>
              </div>
              <div>
                <PText>
                  <b>Default Group</b>
                  <br />
                  Ticking this option enables automatically adding members to
                  the General group without any manual work.
                </PText>
              </div>
              <br />
              <div>
                <PText>
                  <b>
                    <i>
                      Remember, whatever changes you make under General
                      Settings, remember to hit the Save button at last.
                    </i>
                  </b>
                </PText>
              </div>
            </div>
          )}
        </ContentDiv>
        <ContentDiv>
          {data.isStartMeetAssist && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <div>
                <PText>
                  <b>Here you will see:</b>
                </PText>
              </div>
              <div>
                <br />
                <PText>
                  <b>Minute</b>
                  <br />
                  In this section you will get the summary of your discussion
                </PText>
              </div>
              <div>
                <PText>
                  <b>Transcribe</b>
                  <br />
                  All the conversation done during the CoConnect will show here
                  with timestamp
                </PText>
              </div>
              <div>
                <PText>
                  <b>Action Items</b>
                  <br />
                  Here you can create a ticket, schedule CoConnect or set
                  reminders for the issues discussed during the CoConnect.
                </PText>
              </div>

              <br />
              <PText>
                <b>
                  Or, click on three vertical dots to download video in your
                  device
                </b>
              </PText>
            </div>
          )}
        </ContentDiv>
        <ContentDiv>
          {data.isthisZipyandBots && (
            <div
              style={{
                padding: "1.5rem",
                boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                borderRadius: "1rem",
              }}
            >
              <H3Text>
                <b>
                  <i>
                    Now, the following features aid you in refining your content
                    elegantly:
                  </i>
                </b>
              </H3Text>
              <div style={{ margin: "2rem 0rem" }}>
                <PText>
                  <b>Grammer</b>: This feature automatically corrects your
                  grammar in your sentence.
                </PText>
                <PText>
                  <b>Casual</b>: It helps your content to sound more casual.
                </PText>
                <PText>
                  <b>Professional</b>: The feature helps to make your sentence
                  sound more professional.
                </PText>
                <PText>
                  <b>Elaborate</b>: This feature helps to describe your content
                  briefly.
                </PText>
                <PText>
                  <b>Shorten</b>: It makes your long content short.
                </PText>
              </div>
              <PText>
                Also, you can translate your text into different languages with
                anydone.
              </PText>
              {/* <img
                src={
                  "https://static-edge-a.anydone.com/assets/help-page/all-gif/translating-with-anydone.gif"
                }
                alt="not-loaded"
                width="100%"
                style={{
                  marginTop: "2rem",
                  boxShadow: "0 0 3px rgba(0, 0, 0, 0.5)",
                }}
                onClick={() => {
                  setShowImage(
                    "https://static-edge-a.anydone.com/assets/help-page/all-gif/translating-with-anydone.gif"
                  );
                }}
              /> */}
            </div>
          )}
        </ContentDiv>
      </Col>
      <Col
        xs={{ span: 0 }}
        md={{ span: 5 }}
        lg={{ span: 4 }}
        className="third-column"
      ></Col>
    </>
  );
}

export default LayoutPage;
