export const ARcontent = {
	title: "Auto Ticket Create",
	link: "auto-ticket-create",
	content: [
		{
			id: 0,
			title: "Fixed Reply",
			steps: [
				{
					id: 1,
					title: "Login to your AnyDone dashboard and click on your workspace.",
				},
				{
					id: 2,
					title: "Click on the Automation option.",
				},
				{
					id: 3,
					title: "Click on Automated Replies.",
				},
				{
					id: 4,
					title: "Click on the + button from the bottom right corner.",
				},
				{
					id: 5,
					title: "Select Fixed Reply.",
				},
				{
					id: 6,
					title: "Give it a title.",
				},
				{
					id: 7,
					title: "Under the Matching Text option, click on +New Matching Text.",
				},
				{
					id: 8,
					title: "Add all the possible queries that the user can make.",
				},
				{
					id: 9,
					title: "Under Reply Text, add the text that you want to show.",
				},
				{
					id: 10,
					title: "Tick the Enable Expiration option and set the time.",
				},
				{
					id: 11,
					title: "Lastly, click on the Create button.",
				},
			],
			text1content: `
            Remember that the ability to create fixed replies is exclusive to the owner and admin. You can create multiple fixed reply bots, and once created, you have the flexibility to enable, disable, edit, or delete them as needed. 
                <br/>
                One more thing, After creating a fixed reply, you are required to create a bot and add it to your desired group.
            `,
		},
		{
			id: 1,
			title: "Resolution Reply",

			steps: [
				{
					id: 1,
					title: "Login to your AnyDone dashboard and click on your workspace.",
				},
				{
					id: 2,
					title: "Click on the Automation option.",
				},
				{
					id: 3,
					title: "Click on Automated Replies.",
				},
				{
					id: 4,
					title: "Click on the + button from the bottom right corner.",
				},
				{
					id: 5,
					title: "Select Resolution Reply.",
				},
				{
					id: 6,
					title: "Give it a title.",
				},
				{
					id: 7,
					title: "Under Problem Definition, click on +Add another.",
				},
				{
					id: 8,
					title: "Add the possible problem that the user could ask.",
				},
				{
					id: 9,
					title: "Click on +Add Problem Solution from the right side.",
				},
				{
					id: 10,
					title: "A new prompt will appear on your screen.",
				},
				{
					id: 11,
					title: "Add the title of the solution.",
				},
				{
					id: 12,
					title: "Under Steps, add all the possible solutions.",
				},
				{
					id: 13,
					title:
						"Under Feedback Reply, add text to ask the user whether the problem has been resolved or not.",
				},
			],
			text1content: `
            Remember that the ability to create a resolution reply is exclusive to the owner and admin. You can create multiple fixed reply bots, and once created, you have the flexibility to enable, disable, edit, or delete them as needed. 
<br/>
One more thing, after creating a resolution reply, you are required to create a bot and add it to your desired group.

            `,
		},
	],
};
