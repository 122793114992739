import plus from "./../../../../../new/icons/plus.png";
import calendar from "./../../../../../new/icons/calendar.svg";
const ImageBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/meetings/";
const GifBaseUrl =
	"https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MCcontent = {
	title: "Managing Calendar",
	link: "managing-calendar",
	content: [
		{
			id: 0,
			title: "One time meeting",
			steps: [
				{
					id: 1,
					title: `From the left side of anydone dashboard, click on the <img src=${calendar} alt="calendar" height="14" width="14"/> icon.`,
				},
				{
					id: 2,
					title: `Click on the <img src=${plus} alt="plus" height="12" width="12"/> button from the right corner .`,
					imageSource: `${ImageBaseUrl}how-to-create-meetings-anydone.png`,
				},
				{
					id: 3,
					title: `Click on the <b>One Time Meeting</b>.`,
				},
				{
					id: 4,
					title: `Now add the required details and information.`,
				},
				{
					id: 5,
					title: `Then click on <b>Create</b> button.`,
					imageSource: `${GifBaseUrl}anydone-one-time-meeting.gif`,
				},
			],
		},
		{
			id: 1,
			title: "Import Calendar",

			steps: [
				{
					id: 1,
					title: `From the left side of anydone dashboard, click on the <img src=${calendar} alt="calendar
					 from the top left.`,
				},
				{
					id: 3,
					title: `Now, click on the <b>three horizontal dots</b>.`,
				},
				{
					id: 4,
					title: `Click on the <b>Export Calendar</b> option then your file will be downloaded automatically.`,
					imageSource: `${GifBaseUrl}export-calendar-anydone.gif`,
				},
			],
		},
		{
			id: 3,
			title: "View holiday and leave on calendar",
			steps: [
				{
					id: 1,
					title: `Go to your anydone dashboard.`,
				},
				{
					id: 2,
					title: `Click on the <img src=${calendar} alt="calendar" height="14" width="14"/> icon.`,
				},
				{
					id: 3,
					title: `Now, on the right side of the dashboard, you can now view the holiday and leave.`,
					imageSource: `${GifBaseUrl}anydone-calendar.gif`,
				},
			],
		},
	],
};
