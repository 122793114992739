import bell from "./../../../../../new/icons/bell.svg";
import down from "./../../../../../new/icons/down.svg";
import iicon from "./../../../../../new/icons/i.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/getting-started/";
const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const MngNcontent = {
  title: "Manage Notification",
  link: "manage-notification",
  content: [
    {
      id: 0,
      title: "How to View Notifications?",
      steps: [
        {
          id: 1,
          title: `Login to your dashboard.`,
        },
        {
          id: 2,
          title: `Navigate to the <img src=${bell} alt="bell" /> icon and click on it.`,
        },
        {
          id: 3,
          title: `Now, view all the notifications you have received.`,
          imageSource: `${GifBaseUrl}notification-view-anydone.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "How to Filter Notifications?",
      steps: [
        {
          id: 1,
          title: `Login to your dashboard.`,
        },
        {
          id: 2,
          title: `Navigate to the <img src=${bell} alt="bell" /> icon and click on it.`,
        },
        {
          id: 3,
          title: `Now, you'll see all the notifications you have received .`,
        },
        {
          id: 4,
          title: `Click on the <b>Filter type</b> icon next to the notification.`,
        },
        {
          id: 5,
          title: `Now, select the specific notification type that you want to see.`,
          imageSource: `${GifBaseUrl}filter-notification-anydone.gif`,
        },
      ],
    },
    {
      id: 2,
      title: "Pause Notification",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard.`,
        },
        {
          id: 2,
          title: `Click on your name icon.`,
        },
        {
          id: 3,
          title: `Click on the <b>Pause Notification</b>.`,
        },
        {
          id: 4,
          title: ` Here, you'll see multiple default option, select one.`,
        },
        {
          id: 5,
          title: `Or you can click on the <b>Custom option</b> and set your preferred date and time.`,
          imageSource: `${GifBaseUrl}push-notification.gif`,
        },
      ],
    },
  ],
};
