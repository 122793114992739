import plus from "./../../../../../new/icons/plus.png";
import ticketIcon from "./../../../../../new/icons/ticket.svg";
import taskicon from "./../../../../../new/icons/task.svg";
const ImageBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/task-management/";
const GifeBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const CAMTcontent = {
  title: "Ticket Workflow",
  link: "ticket-workflow",
  content: [
    {
      id: 0,
      title: "Set Ticket Status Approval",
      onedescription:
        "In anydone ticket workflow, you can give permission to the members, teams or anyone to approve tickets when some change its status. After setting it up, one needs to get approval from members, teams or anydone to move their ticket status/ticket column.",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left side.`,
        },
        {
          id: 2,
          title:
            "Navigate to the project where you want to create a ticket workflow and click on the folder.",
        },
        {
          id: 3,
          title: "Click on the <b>Workflow</b> option from the top right.",
        },
        {
          id: 4,
          title: "Click on the status button from the canvas like to-do.",
        },
        {
          id: 5,
          title: "Under <b>Approval</b>, click on <b>Add approval</b>.",
        },
        {
          id: 6,
          title:
            "Under <b>Get approval</b> from select between <b>anyone</b>, <b>team</b> or <b>member</b>.",
        },
        {
          id: 7,
          title:
            "In <b>Considered approved after option</b>, you can set number of approval for tickets.",
        },
        {
          id: 8,
          title: "Click on <b>Save</b> button.",
        },
        {
          id: 9,
          title: "Click on <b>Update</b> button.",
          imageSource: `${GifeBaseUrl}ticket-status-approval.gif`,
        },
      ],
    },
    {
      id: 1,
      title: "Set Ticket Transition",
      onedescription:
        "In anydone ticket workflow transition, you can specify from which column a ticket can be moved to which destination column. For example, setting a transition from 'To-Do' to 'In Progress' means that no one will be able to move the ticket to any column other than 'In Progress'. For instance, you can set multiple transitions for the ticket. For example if you set your first transition 'To-Do' to 'In Progress' then your second transition could be  'In Progress' to  'Done'",
      steps: [
        {
          id: 1,
          title: `Go to your dashboard, click on the <img src=${taskicon} alt="task"  height="14" width:"14"/> icon from the left side.`,
        },
        {
          id: 2,
          title:
            "Navigate to the project where you want to create a ticket workflow and click on the folder.",
        },
        {
          id: 3,
          title: "Click on the <b>Workflow</b> option from the top right.",
        },
        {
          id: 4,
          title: "Now, ticket workflow canvas will open.",
        },
        {
          id: 5,
          title:
            "Here, drag your pointer from <b>TODO</b> to <b>In progress</b> as your first transition.",
        },
        {
          id: 6,
          title:
            "Then, new prompt will pop up, here you give a name to your transition and click on <b>Create</b> button.",
        },
        {
          id: 7,
          title:
            "Again, to set a second transition, drag your pointer from <b>In Progress</b> to <b>Done</b>.",
        },
        {
          id: 8,
          title:
            "New, prompt will pop up, here give a name to your second transition and click on <b>Create</b> button.",
        },
        {
          id: 9,
          title: "Finally, Click on <b>Update</b> button.",
          imageSource: `${GifeBaseUrl}ticket-transition.gif`,
        },
      ],
    },
  ],
};
