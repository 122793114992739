import headphone from "./../../../../../new/icons/headphones.png";
import screenShare from "./../../../../../new/icons/Screen share.png";
import message from "./../../../../../new/icons/message.svg";
import videoCall from "./../../../../../new/icons/video.png";
import collab from "./../../../../../new/icons/collab.png";

const GifBaseUrl =
  "https://static-edge-a.anydone.com/assets/help-page/all-gif/";

export const Cocontent = {
  title: "Collab",
  link: "collab",
  content: [
    {
      id: 0,
      title: "Starting a Collab",
      steps: [
        {
          id: 1,
          title: `Login to your workspace.`,
        },
        {
          id: 2,
          title: `Click on the <img src=${message} alt="message" height="12" width="12"/> icon.`,
        },
        {
          id: 3,
          title: `Go to the DMs, CoSpace, Subject to start Collab.`,
        },
        {
          id: 4,
          title: `From the right top corner click on the <img src=${collab} alt="message" height="12" width="12"/> icon.`,
        },
        {
          id: 5,
          title: `Now, start the Collab.`,
          imageSource: `${GifBaseUrl}anydone-collab.gif`,
        },
      ],
      add_title: "Additional Information:",
      add_steps: [
        {
          id: 1,
          title: `You can make a video and audio call in Collab.`,
        },
        {
          id: 2,
          title:
            "You can add project folder like ticket and collaborate effectively.",
        },
        {
          id: 3,
          title:
            "You can draw, sketch and make a diagram in the Collab as well.",
        },
      ],
    },
  ],
};
