import styled from "styled-components";
import { Link } from "react-router-dom";

export const MainContainer = styled.div`
  background: #ffffff;
`;
export const FaqLinkDiv = styled(Link)`
  text-align: center;
  margin: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const FlexDiv = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};

  justify-content: ${(props) => !props.justifyItem && "center"};
  align-items: ${(props) => (props.alignItem ? props.alignItem : "center")};
  text-align: ${(props) => props.textalign};
`;

export const HelpHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 32rem;
  padding-top: 7rem;
  background: linear-gradient(90deg, #0a2a41 12.76%, #10456b 85.23%);

  box-shadow: 0px 6px 36px 2px rgba(35, 150, 233, 0.17);
  border-radius: 0px 0px 1px 0px;
  width: 100%;
  @media only screen and (max-width: 767px) {
    padding-top: 7rem;
    height: 36rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 480px) {
    height: 28rem !important;
    padding: 2rem 3rem;
  }
  @media only screen and (max-width: 300px) {
    padding: 1.5rem;
  }
`;

export const NavDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem 14rem;

  .user-if-there {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
  @media only screen and (min-width: 960px) and (max-width: 1024px) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: 767px) {
    padding: 0 2rem;
  }
`;
export const LogoContainer = styled.div`
  font-size: 1.8rem;
  @media only screen and (max-width: 767px) {
    font-size: 1.3rem;
    img {
      ${'' /* width: 100%; */}
    }
  }
  @media only screen and (max-width: 500px) {
    h3 {
      font-size: 16px !important;
      width: 30rem !important;
    }
    img {
      /* width: 2%; */
      padding-right: 0.5rem;
    }
  }
  @media only screen and (max-width: 300px) {
    img {
      width: 66%;
    }
  }
`;
export const NavSearchDiv = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  border: 1px solid #eeeeee;
  border-radius: 0.2rem;
  margin-right: 2rem;

  img {
    position: absolute;
    margin-left: 1rem;
  }
  input {
    height: 3rem;
    display: flex;
    border-radius: 8px;
    background-color: #ffffff;
    outline: none;
    align-items: center;
    border: none;
    padding: 0.7rem 0.7rem 0.7rem 3rem;
    width: 100%;
    font-size: 16px;
    color: black;
  }
`;
export const SearchDiv = styled.div`
  width: 50rem;
  display: ${(props) => !props.notFlex && "flex"};
  align-items: center;
  img {
    position: absolute;
    margin-left: 1rem;
  }
  input {
    display: flex;
    border-radius: 15px;
    background-color: #ffffff;
    outline: none;
    align-items: center;
    border: none;
    padding: 0.7rem 0.7rem 0.7rem 3rem;
    width: 100%;
    font-size: 16px;
    color: black;
  }
  @media only screen and (max-width: 500px) {
    width: 90%;
  }
`;

export const DropDownDiv = styled.div`
  max-height: 25rem !important;
  z-index: 10;
  width: 50rem;
  margin-top: 0.3rem;
  position: ${(props) => (props.isAbsolute ? "absolute" : "relative")};
  background-color: #f7f9ff;
  border-radius: 1rem;
  padding: 2rem;
  color: #000000;
  .inner-dropdown {
    position: relative;
    overflow: scroll;
    max-height: 21rem !important;
  }
  .inner-dropdown h1 {
    padding: 0.5rem;
    /* margin-bottom: 0; */
  }
  .inner-dropdown h1:hover {
    cursor: pointer;
    background-color: #eef4ff;
  }
  .inner-dropdown h1:last-child {
    margin-bottom: 0px;
  }
`;

export const MainHeaderText = styled.h1`
  font-family: "Poppins";
  font-weight: 700;
  /* font-size: 3.6rem; */
  font-size: ${(props) => (props.smsize ? "3.2rem" : "3.6rem")};
  line-height: ${(props) => (props.linehight ? "5.4rem" : "3rem")};
  color: #ffffff;
  @media only screen and (max-width: 767px) {
    font-size: ${(props) => (props.smsize ? "26px" : "32px")}!important;
    line-height: 48px !important;
  }
`;

export const SecStartedContent = styled.div`
  padding: 3rem 10rem 4rem;
  /* display: flex;
	flex-direction: column;
	align-items: center; */
  background-color: #ffffff;
  @media only screen and (min-width: 1025px) and (max-width: 1230px) {
    padding: 2rem 8rem;
  }
  @media only screen and (min-width: 960px) and (max-width: 1024px) {
    padding: 2rem 6rem;
  }
  @media only screen and (min-width: 768px) and (max-width: 959px) {
    padding: 2rem 5rem;
  }
  @media only screen and (max-width: 767px) {
    padding: 1.5rem 2rem;
  }
`;

export const PText = styled.p`
  font-size: 16px;
  margin: 0;
  line-height: 2rem;
  text-align: ${(props) => (props.text_start ? "start" : "justify")};
  color: ${(props) => (props.active ? "#2396E9" : props.color)};
  background-color: ${(props) => props.active && "#F0EDF1"};
  border-radius: ${(props) => props.active && "0.5rem"};
  @media only screen and (min-width: 768px) and (max-width: 959px) {
    text-align: start;
  }
  @media only screen and (max-width: 767px) {
    text-align: start;
  }
 
`;

export const HeadingText = styled.h2`
  font-family: "Poppins";
  margin-top: 2rem;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.9rem;
  color: #162a62;
`;

export const StartHeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 22rem;
  color: white;
  background: linear-gradient(90deg, #0a2a41 12.76%, #10456b 85.23%);
  box-shadow: 0px 6px 36px 2px rgba(35, 150, 233, 0.17);
  @media only screen and (max-width: 767px) {
    padding: 2rem 5rem;
    height: 26rem;
  }
  @media only screen and (min-width: 300px) and (max-width: 480px) {
    padding: 2rem 3rem;
  }
  @media only screen and (max-width: 300px) {
    padding: 1.5rem;
  }
`;

export const CardImgDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  img {
    vertical-align: middle;
    width: 8rem;
    height: 8rem;
  }
`;
export const CardTitle = styled.h2`
  text-align: ${(props) => (props.alignText ? props.alignText : "center")};
  font-size: 1.8rem;
  font-weight: 500;
  color: #333333;
  line-height: 3rem;
  margin: 0;
  padding: 0;
`;

export const ContentDiv = styled.div`
  margin: 2rem 0rem;
  @media only screen and (max-width: 767px) {
    margin: 4rem 0rem;
  }
`;

export const InnerWhatsNew = styled.div`
  margin: auto;
  height: 300px;
  padding: 6rem 4rem;
  @media only screen and (max-width: 767px) {
    height: auto;
    padding: 6rem 3em 0rem;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  align-self: flex-start;
  justify-content: ${(props) =>
    props.no === 2 ? "space-around" : "space-between"};
  position: relative;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Stickydiv = styled.div`
  position: sticky !important;
  top: 3rem;
  @media only screen and (max-width: 767px) {
    position: relative;
    height: ${(props) => (props.clicked ? "30rem" : "20rem")};
    height: ${(props) => props.height && props.height};
    /* height: 40vh */
  }
`;

export const StyledSpan = styled.span`
  font-size: 11px;
  color: #8f928f;
`;

export const H3Text = styled.h3`
  font-size: 24px;
  font-family: "Poppins";
  margin: 0;
  line-height: 20px
  color: ${(props) => props.colored && "#5f88f7"};
  margin-bottom: ${(props) => props.mb && props.mb};
  img{
    margin-right:0.6rem
  }
`;

export const SecTitle = styled.h1`
  margin-top: 6rem;
  margin-bottom: 0rem;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 3rem;
  line-height: 4.5rem;
  color: #162a62;
`;

export const TitleImgDiv = styled.div`
  font-weight: 500;
  font-size: 2rem;
  line-height: 3.6rem;
  display: flex;
  align-items: center;
  color: ${(props) => props.color && props.color};
  img {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.8rem;
  }
`;

export const ListContent = styled.div`
  padding: 0.5rem 1.5rem;
  ul {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 0.5rem 1rem;
    list-style-type: disc;
    margin-bottom: 0 !important;
  }

  li {
    width: 47%;
    margin-bottom: 0.6rem;
    color: #2396e9;
  }
  li:hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: 767px) {
    ul {
      flex-direction: column;
    }
    li {
      width: 100%;
      margin-bottom: 2rem;
    }
  }
`;

export const NoteDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  padding: 1rem 1rem 1.5rem;
  border-radius: 0.3rem;
  border: 1px solid #2396e9;
  border-color: #1d9bd1;
  background-color: #eef9ff;
  h3 {
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }
  ul {
    margin-bottom: 0rem;
  }
  ul li:last-child {
    margin-bottom: 0rem;
  }
`;

export const StepContent = styled.div`
  margin: 2rem 0rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.14);
  margin-left: 0 !important;
  width: 100%;
  li {
    margin-bottom: 1rem;
    font-family: "Poppins";
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
    font-size: 1rem;
  }
  li:last-child {
    margin-bottom: 0rem;
  }
  li span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 25px !important;
    min-width: 27px !important;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #000000;
    color: black;
    margin-right: 0.5rem;
    font-size: 16px;
  }
  p {
    text-align: justify;
    margin-bottom: 1rem;
    padding: 0;
  }
  li p {
    margin: 0;
    padding: 0;
    padding-left: 0.6rem;
  }
  img {
    margin-bottom: 3px;
  }
  ::marker {
    color: rgb(127, 127, 127);
  }
  @media only screen and (max-width: 767px) {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07);

    P {
      text-align: start;
    }
  }
`;

export const EachBoxListingWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  /* justify-content: space-between; */

  @media only screen and (max-width: 767px) {
    flex-direction: "column";
  }
`;
export const EachBoxListing = styled.div`
  width: 30%;
  background-color: white;
  box-shadow: 0px 0px 12px 1px rgba(51, 51, 51, 0.08);
  margin: 1.1rem;
  padding: 1.5rem;
  border-radius: 1rem;
  position: relative;
  a {
    color: #5d6c82 !important;
  }
  a:hover {
    color: #2396e9 !important;
  }
  ul ::marker {
    color: #d9d9d9 !important;
  }
  ul li {
    /* letter-spacing: 1px; */
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.5rem;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 3rem;
  }
`;
export const ExploreButton = styled.button`
  position: absolute;
  bottom: 1rem;
  margin-left: -2rem;
  padding: 0.3rem 1rem;
  outline: none;
  border: none;
  background-color: #ffffff;
  color: #2396e9;
  &:hover {
    cursor: pointer;
  }
  img {
    height: 15px;
    width: 25px;
  }
`;
